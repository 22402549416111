import { connect } from 'react-redux';
import { actionTypes } from '../../store/actions';
import { Dialog, makeStyles, AppBar, Toolbar, IconButton, Icon, Typography, Button, Grid, Slide, Hidden, useTheme, useMediaQuery } from '@material-ui/core';
import cx from 'classnames';

import img_appbar_adornment from '../../assets/adornments/header_view_adornment.png';
import system_translations from '../../texts/system_translations';
import { useState, forwardRef } from 'react';
import TabSwitch from './components/TabSwitch';

const Contact = ({ language, contact_modal, onUpdateContactModal }) => {
	const classes = useStyles();
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
	const content = system_translations[language].modals.contact;
	const [activeTab, setActiveTab] = useState('TAB_GENERAL');

	const handleTabChange = (id_clicked) => {
		setActiveTab(id_clicked);
	};

	return (
		<Dialog fullScreen={fullScreen} maxWidth={'lg'} fullWidth className={classes.dialog} open={contact_modal} onClose={() => onUpdateContactModal(false)}>
			<AppBar className={classes.appbar} position='sticky'>
				<Toolbar>
					<Typography variant='h4'>{content.title}</Typography>
					<IconButton className={classes.close_button} color='inherit' onClick={() => onUpdateContactModal(false)} aria-label='close'>
						<Icon style={{ fontSize: 30 }}>close_sharp</Icon>
					</IconButton>
				</Toolbar>
			</AppBar>
			<Grid container className={classes.main_container}>
				<Grid item xs={12} lg={7} className={classes.left_container}>
					<div className={classes.header_container}>
						<Typography variant='h6' className={classes.header_title}>
							{content.header_title}
						</Typography>
						<div className={classes.tabs_container}>
							{content.tabs.map((item) => (
								<Typography
									color='textSecondary'
									key={item.id}
									className={cx({ [classes.tab]: true, [classes.tab_active]: item.id === activeTab })}
									align='left'
									onClick={() => handleTabChange(item.id)}
								>
									{item.name}
								</Typography>
							))}
						</div>
					</div>
					<div className={classes.divider} />
					<div className={classes.body_container}>
						<TabSwitch active_tab={activeTab} language={language} content={content} />
					</div>
					<Grid item xs={12} className={classes.footer}>
						Volutpat, sit nibh quam vel. Id curabitur blandit id ut sed quam. Pellentesque sagittis vel pellentesque rhoncus convallis pellentesque iaculis.{' '}
					</Grid>
				</Grid>
				<Hidden mdDown>
					<Grid item xs={12} lg={5}>
						<div className={classes.banner_container}>
							<img src={content.right_banner} className={classes.img_banner} />
						</div>
					</Grid>
				</Hidden>
			</Grid>
		</Dialog>
	);
};

const useStyles = makeStyles((theme) => ({
	dialog: {},
	appbar: {
		height: 120,
		width: '100%',
		background: theme.palette.primary.main,
		display: 'flex',
		justifyContent: 'center',
		padding: '0px 140px',
		boxShadow: 'none',
		[theme.breakpoints.down('sm')]: {
			padding: '0px 40px',
		},
	},
	close_button: {
		marginLeft: 'auto',
		zIndex: '2',
		[theme.breakpoints.down('sm')]: {
			padding: '0px 40px',
		},
	},
	main_container: {
		background: '#FEFEFE',
	},
	body_container: {
		padding: '50px 80px 60px 140px',
		[theme.breakpoints.down('sm')]: {
			padding: '80px 30px 60px 30px',
		},
	},
	banner_container: {
		height: 1400,
	},
	img_banner: {
		width: '100%',
		height: '100%',
		objectFit: 'cover',
		objectPosition: 'top',
	},
	header_container: {
		background: '#FEFEFE',
		padding: '100px 100px 0px 140px',
		[theme.breakpoints.down('sm')]: {
			padding: '40px 30px 0px',
		},
	},
	header_title: {
		fontWeight: 600,
		marginBottom: 30,
	},
	tabs_container: {
		display: 'flex',
		justifyContent: 'left',
		alignItems: 'center',
	},
	tab: {
		padding: '35px 0px',
		marginRight: 60,
		textTransform: 'uppercase',
		fontWeight: 600,
		fontSize: 18,
		cursor: 'pointer',
		'&:hover': {
			color: theme.palette.secondary.main,
		},
		[theme.breakpoints.down('xs')]: {
			marginRight: 20,
			fontSize: 14,
		},
	},
	tab_active: {
		color: theme.palette.secondary.main,
		borderBottom: `2px solid ${theme.palette.secondary.main}`,
	},
	divider: {
		width: '100%',
		height: 0,
		border: '0.5px solid #E4E4E4',
	},
	footer: {
		height: 160,
		marginRight: 80,
		background: theme.palette.primary.main,
		padding: '40px 140px',
		fontSize: 14,
		fontWeight: 400,
		color: 'white',
		[theme.breakpoints.down('sm')]: {
			marginRight: 30,
			padding: '30px 50px',
		},
	},
}));

const mapStateToProps = (state) => {
	return {
		contact_modal: state.modals.contact,
		language: state.language,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onUpdateContactModal: (contact_modal) => dispatch({ type: actionTypes.MODAL_UPDATE_CONTACT, contact_modal }),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Contact);
