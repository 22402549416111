import moment from 'moment-timezone'
import { ERROR_CODES } from '../texts/errorCodes';

export const updateObject = (oldObject, updatedProperties) => {
	return {
		...oldObject,
		...updatedProperties,
	};
};

///////////////////// SERVER HANDLERS ///////////////////////////
export const updateAuthorizationHeader = (server) => {
	const logData = JSON.parse(localStorage.getItem('session_data'));
	if (!logData) {
		console.log('Token not valid');
		return;
	}
	server.defaults.headers.common['Authorization'] = logData.token;
};

export const onTruncateText = (input, length) => {
	if (!input) return '';
	return input.length > length ? `${input.substring(0, length - 3)}...` : input;
};

export const isFormValid = (form, keys) => {
	let errors = [];
	if (form) {
		if (keys) {
			keys.forEach((item) => {
				if (!form[item].isValid) errors.push(item);
			});
		} else {
			Object.keys(form).forEach((item) => {
				if (form[item].isRequired && !form[item].isValid) errors.push(item);
			});
		}
	}
	return errors;
};

export const onSetErrorsToForm = (form, errors) => {
	let temp = { ...form };
	if (errors && errors.length)
		errors.forEach((item) => {
			temp[item].isVisited = true;
			temp[item].isValid = false;
		});
	return temp;
};

export const onGetFormData = (form, keys) => {
	let temp = {};
	if (form) {
		if (keys) {
			keys.forEach((item) => {
				if (form[item].isValid) temp = { ...temp, [item]: form[item].value };
			});
		} else {
			Object.keys(form).forEach((item) => {
				if (form[item].isValid) temp = { ...temp, [item]: form[item].value };
			});
		}
	}
	return temp;
};

export const onGetSelectedFormData = (form, origin) => {
	let temp = {};
	if (form) {
		Object.keys(form).forEach((item) => {
			if (form[item].isValid && form[item].value !== origin[item]) temp = { ...temp, [item]: form[item].value };
		});
	}
	return temp;
};

export const onInitForm = (form, origin, translations) => {
	let response = { ...form };
	Object.keys(form).forEach((item) => {
		if (origin) {
			const value = origin[item];
			if (value) response[item] = { ...response[item], value: value, isVisited: true, isValid: true };
		}
		if (translations && translations[item]) response[item].config = { ...response[item].config, ...translations[item] };
	});
	return response;
};

export const onGetDateFormat = (data, format) => {
	if (!data) return null;
	const momentdata = moment(data);
	if (!momentdata.isValid()) return null;
	if (format) return momentdata.format(format);
	return momentdata.format('YYYY-MM-DD');
};

export const onGetCatalogs = (options, keyValue, keyLabel) => {
	if (!options) return [];
	return options.map((item) => {
		return {
			value: item[keyValue],
			label: item[keyLabel] ? item[keyLabel] : '',
		};
	});
};

export const onGetErrorMessage = (error) => {
	let message = 'Hubo un error';
	let details = null;
	if (!error) return { message: '', details: null };
	if (error.response) {
		if (error.response.data) {
			if (error.response.data.error && error.response.data.error.code) {
				const _error = ERROR_CODES[error.response.data.error.code];
				console.log(_error);
				details = { ...error.response.data.error };
				details.message = _error ? _error : null;
				if (_error) message = _error;
			}
		}
	}
	console.log(details);
	console.log(message);
	return { ...details, message: message };
};

export const onSetTokenSession = async (token_data) => {
	if (token_data) await localStorage.setItem('session_data', JSON.stringify(token_data));
};


export const onGetFullname = (first_name, last_name) => {
    if(!first_name) return 'N/D'
    return `${first_name ? first_name : ''} ${last_name ? last_name : ''}`
}

const monthsName = ['Enero','Febrero','Marzo','Abril','Mayo','Junio','Julio','Agosto','Septiembre','Octubre','Noviembre','Diciembre']
const monthsNameEn = ['January','February','March','April','May','June','July','August','September','October','November','December']

export const onGetMonthName = (month, lang) =>{
    const _monthsName = lang === 'en' ? monthsNameEn : monthsName
    if(month < 1 || month > 12) return '-'
    return _monthsName[month-1]
}

export const onGetMonthsCatalog = (year) => {
    let response = []
    for(let i=0;i<12;i++){
        const startDate = moment([year, i])
        const endDate = moment(startDate).endOf('month')
        response.push({
            value:i+1,
            label:monthsName[i],
            start_date: startDate.format('YYYY-MM-DD'),
            end_date: endDate.format('YYYY-MM-DD')
        })
    }
    return response
}



