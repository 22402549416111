import general_es from './esp/general-translations'
import home_es from './esp/home-translations';
import news_es from './esp/news_translations';
import about_es from './esp/about_translations';
import contact_es from './esp/contact_translations';
import offering_es from './esp/offering_translations';

import singin_layout_es from './esp/signin-translations';
import signup_layout_es from './esp/signup-translations';
import frontend_errors_es from './esp/frontend_errors';
import footer_es from './esp/footer-translations';

// ENGLISH
import general_en from './eng/general-translations';
import home_en from './eng/home-translations';
import news_en from './eng/news_translations';
import about_en from './eng/about_translations';
import contact_en from './esp/contact_translations';

import singin_layout_en from './eng/signin-translations';
import frontend_errors_en from './eng/frontend_errors';
import footer_en from './eng/footer-translations';

const system_translations = {
	es: {
		general: general_es,
		layouts: {
			signin: singin_layout_es,
			signup: signup_layout_es,
			footer: footer_es,
		},
		views: {
			home: home_es,
			news: news_es,
			about: about_es,
			offering: offering_es,
		},
		errors: frontend_errors_es,
		modals: {
			contact: contact_es,
		},
	},
	en: {
		general: general_en,
		layouts: {
			signin: singin_layout_en,
			footer: footer_en,
		},
		views: {
			home: home_en,
			news: news_en,
			about: about_en,
		},
		errors: frontend_errors_en,
		modals: {
			contact: contact_en,
		},
	},
};

export default system_translations