import logo from '../../assets/logo_vanguard.png';

const general_translations = {
	sidebar: {
		logo: logo,
		title: 'Monitoring System',
		list: [
			{
				name: 'Dashboard',
				path: '/',
				icon: 'dashboard',
			},
			{
				name: 'Devices',
				path: '/devices',
				icon: 'device_hub',
			},
			{
				name: 'Locations',
				path: '/locations',
				icon: 'room',
			},
		],
	},
	topbar: {
		titles: [
			{
				url: '/offering',
				title: 'Offering',
			},
			{
				url: '/news',
				title: 'News',
			},
			{
				url: '/about',
				title: 'About us',
			},
			{
				url: '/contact',
				title: 'Contact',
			},
			{
				url: '/globalcampus',
				title: 'Global Campus',
			},
		],
		sign_in: 'Sign in',
		sign_up: 'Sign up',
		logo: logo,
	},
};

export default general_translations;
