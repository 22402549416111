import { AppBar, Grid, Hidden, Icon, IconButton, makeStyles, Button, Typography, useMediaQuery, useScrollTrigger, useTheme, Fade } from '@material-ui/core';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import cx from 'classnames';
import { actionTypes } from '../../../store/actions';
import system_translations from '../../../texts/system_translations';
import GeneralButton from '../../../components/Actions/GeneralButton';

const Topbar = ({ history, language, onUpdateDrawerStatus, onUpdateLanguage, onUpdateContactModal }) => {
	const classes = useStyles();
	const theme = useTheme();
	const ismobile = useMediaQuery(theme.breakpoints.down('sm'));
	const trigger = useScrollTrigger({ disableHysteresis: true, threshold: 100 });

	const content = system_translations[language].general.topbar;

	const isURLSelected = (path) => {
		return history.location.pathname.split('/')[1] === path.split('/')[1];
	};

	const onChangeView = (url) => {
		history.push(url);
	};

	const onChangeLanguage = async (lang) => {
		console.log('something new');
		await localStorage.setItem('lang', lang);
		onUpdateLanguage(lang);
		//window.location.reload()
	};

	const current_path = history.location.pathname;

	return (
		<AppBar className={cx({ [classes.appbar]: true, [classes.scrolled_appbar]: trigger })}>
			<div className={classes.root}>
				<Grid container alignItems='center'>
					<Grid item>
						<div className={classes.logo_container} onClick={() => onChangeView('/')}>
							<img alt='' src={content.logo} className={classes.logo} />
						</div>
					</Grid>
					<Hidden mdDown>
						{content.titles.map((item) => {
							const isactive = isURLSelected(item.url);
							const isContact = item.url === '/contact';

							return (
								<Grid item key={item.title}>
									<div
										className={classes.topbar_item}
										onClick={isContact ? () => onUpdateContactModal(true) : () => onChangeView(item.url)}
										style={{ borderRight: item.url === '/globalcampus' ? 0 : '1px solid white' }}
									>
										<Typography align='center' className={cx({ [classes.topbar_item_text]: true, [classes.topbar_item_text_active]: isactive })}>
											{item.title}
										</Typography>
									</div>
								</Grid>
							);
						})}
					</Hidden>
					<Hidden mdDown>
						<Grid item xs />
						<Grid item>
							<Typography variant='button' align='center' noWrap className={classes.signIn_topbar} onClick={() => onChangeView('/signin')}>
								{content.sign_in}
							</Typography>
							<GeneralButton style={{ width: 154, height: 54 }} onClick={() => onChangeView('/signup')}>
								{content.sign_up}
							</GeneralButton>
						</Grid>
					</Hidden>
					<Hidden lgUp>
						<Grid item>
							<IconButton style={{ color: 'white' }} aria-label='open drawer' edge='end' onClick={() => onUpdateDrawerStatus(true)} className={classes.menu}>
								<Icon>menu</Icon>
							</IconButton>
						</Grid>
					</Hidden>
				</Grid>
			</div>
		</AppBar>
	);
};

const useStyles = makeStyles((theme) => ({
	root: {
		//margin:'auto',
		boxSizing: 'border-box',
		position: 'relative',
		width: '100%',
	},
	topbar_button: {
		background: theme.palette.secondary.main,
		color: '#fff',
		height: 54,
		width: 154,
		borderRadius: 0,
		margin: '0px 15px',
		'&:hover': {
			background: theme.palette.secondary.dark,
		},
	},
	appbar: {
		background: 'transparent',
		border: 'none',
		boxShadow: 'none',
		//boxShadow:DASHBOARD_SETTINGS.BOXSHADOW,
		height: 145,
		color: 'grey',
		padding: '20px 140px',
		transition: 'all 300ms linear',
		[theme.breakpoints.down('md')]: {
			padding: '20px 20px',
		},
	},
	logo: {
		width: 110,
		//marginTop:16,
		//paddingTop:8,
		//background:'red'
	},
	logo_container: {
		paddingTop: 5,
		paddingRight: 22,
		cursor: 'pointer',
		//background:'red'
	},
	searchbar: {
		position: 'absolute',
		top: 24,
		left: '50%',
		transform: 'translateX(-50%)',
	},
	topbar_item: {
		//width:100,
		padding: '0px 20px',
		borderRight: '1px solid white',
		color: 'white',
		textTransform: 'uppercase',
		cursor: 'pointer',
	},
	topbar_item_text: {
		fontSize: '.875rem',
		fontWeight: 500,
	},
	topbar_item_text_active: {
		fontWeight: 800,
	},
	signIn_topbar: {
		textAlign: 'center',
		fontSize: '0.850rem',
		color: 'white',
		margin: '0px 20px',
		cursor: 'pointer',
	},
	scrolled_appbar: {
		backgroundColor: 'rgba(8, 37, 79, 0.95)',
		transition: 'all 300ms linear',
		padding: '0px 140px',
		height: 100,
		[theme.breakpoints.down('md')]: {
			padding: '0px 20px',
		},
	},
}));

const mapStateToProps = (state) => {
	return {
		user: state.user,
		is_auth: state.is_auth,
		language: state.language,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onUpdateSignInModal: (signin_modal) => dispatch({ type: actionTypes.MODAL_UPDATE_SIGNIN, signin_modal }),
		onUpdateUserData: (user) => dispatch({ type: actionTypes.AUTH_UPDATE_USER, user }),
		onUpdateAuthStatus: (is_auth) => dispatch({ type: actionTypes.AUTH_UPDATE_STATUS, is_auth }),
		onUpdateDrawerStatus: (drawer_status) => dispatch({ type: actionTypes.SYS_UPDATE_DRAWER_STATUS, drawer_status }),
		onUpdateLanguage: (language) => dispatch({ type: actionTypes.SYS_UPDATE_LANGUAGE, language }),
		onUpdateContactModal: (contact_modal) => dispatch({ type: actionTypes.MODAL_UPDATE_CONTACT, contact_modal }),
	};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Topbar));
