import { makeStyles, Hidden, Grid, Typography, Button, Fade } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import img_adornment from '../../../assets/home_view/section_c_adornment.png';
import InViewMonitor from 'react-inview-monitor';
import GeneralButton from '../../../components/Actions/GeneralButton';

const SectionC = ({ content, history }) => {
	const classes = useStyles();
	const [onView, setOnView] = useState(false);

	const onChangeView = (url) => {
		history.push(url);
	};

	return (
		<>
			<InViewMonitor
				onInView={() => {
					setOnView(true);
				}}
			>
				<Fade in={onView} timeout={1000}>
					<div className={classes.root}>
						<div className={classes.header_container}>
							<Typography variant='h4' align='center'>
								{content.header_title}
							</Typography>
							<Typography variant='h6' align='center' color='textSecondary'>
								{content.header_content}
							</Typography>
						</div>
						<div className={classes.body_container}>
							<div className={classes.left_adornment}>
								<img src={img_adornment} className={classes.img_flip} />
							</div>
							<div className={classes.right_adornment}>
								<img src={img_adornment} />
							</div>
							<Grid container alignItems='center' className={classes.content_container}>
								<Hidden smDown>
									<Grid md={6} xs={12} item className={classes.img_container}>
										<img src={content.img} className={classes.img_body} />
									</Grid>
								</Hidden>
								<Grid md={6} xs={12} item className={classes.rightside}>
									<Typography variant='h3' style={{ color: 'white' }}>
										{content.body_title}
									</Typography>
									<GeneralButton onClick={() => onChangeView('/signup')} style={{ height: 76, width: 186, margin: '46px 0px' }}>
										{content.body_button}
									</GeneralButton>
								</Grid>
							</Grid>
						</div>
					</div>
				</Fade>
			</InViewMonitor>
		</>
	);
};

export default withRouter(SectionC);

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'relative',
		margin: '100px 0px',
		background: theme.palette.layout.background,
		[theme.breakpoints.down('sm')]: {
			margin: '50px 0px',
		},
	},
	header_container: {
		padding: '0px 100px 80px',
		[theme.breakpoints.down('md')]: {
			padding: '0px 15px 30px',
		},
	},
	body_container: {
		padding: '100px 0px',
		position: 'relative',
		background: theme.palette.primary.main,
		[theme.breakpoints.down('md')]: {
			padding: '0px 0px',
		},
	},
	content_container: {
		width: 1200,
		marginLeft: 'auto',
		marginRight: 'auto',
		[theme.breakpoints.down('md')]: {
			width: '100%',
		},
	},
	img_container: {
		width: 630,
	},
	img_body: {
		objectFit: 'cover',
		width: '100%',
		heigth: '100%',
	},
	rightside: {
		padding: 100,
		[theme.breakpoints.down('md')]: {
			padding: 30,
		},
	},
	right_adornment: {
		position: 'absolute',
		top: 30,
		right: 0,
	},
	left_adornment: {
		position: 'absolute',
		top: 30,
		left: 0,
	},
	img_flip: {
		transform: 'scaleX(-1)',
	},
}));
