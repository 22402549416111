import { createTheme  } from "@material-ui/core/styles";
import { grey, w } from '@material-ui/core/colors';

export const theme = createTheme({
	palette: {
		primary: { main: '#08254F' },
		secondary: { main: '#E38E1D' },
		tertiary: { main: '#0D97FE' },
		layout: { background: '#E5E5E5' },
		text: {
			primary: '#151515',
			secondary: '#474747',
			blue: '#184EA2',
			gray: '#B8B8B8',
			forms: '#A0A0A0',
		},
		action: {
			disabled: 'white',
		},
	},
	typography: {
		useNextVariants: true,
		fontFamily: ['Raleway', 'Visby Round CF', 'alda', 'Work Sans'].join(','),
		numberFont: ['Open Sans', 'Visby Round CF', 'alda', 'Work Sans'].join(','),
	},
	overrides: {
		MuiTypography: {
			h1: {
				fontSize: '8rem',
				fontWeight: 700,
			},
			h2: {
				fontSize: '4rem',
				fontWeight: 700,
			},
			h3: {
				fontSize: '3rem',
				fontWeight: 600,
			},
			h4: {
				fontWeight: 600,
				fontSize: '2.250rem',
			},
			h5: {
				fontSize: '1.875rem',
				fontWeight: 600,
			},
			h6: {
				fontSize: '1.5rem',
				fontWeight: 400,
			},
			subtitle1: {
				fontSize: '1.125rem',
				fontWeight: 700,
			},
			subtitle2: {
				fontSize: '0.875rem',
				fontWeight: 500,
			},
			body1: {
				fontSize: '1rem',
				fontWeight: 400,
			},
			body2: {
				fontSize: '0.875rem',
				fontWeight: 300,
			},
			caption: {
				fontSize: '0.875rem',
				fontWeight: 300,
			},
			button: {
				fontSize: '1.125rem',
			},
		},
	},
});