import { AppBar, makeStyles, Toolbar, Grid, Typography } from '@material-ui/core';

const TopbarSignUp = ({ content, onChangeView }) => {
	const classes = useStyles();

	return (
		<AppBar className={classes.root} position='absolute'>
			<Toolbar style={{ padding: 0 }}>
				<Grid container alignItems='center'>
					<Grid item xs={2}>
						<div className={classes.logo_container} onClick={() => onChangeView('/')}>
							<img alt='' src={content.img_logo} className={classes.logo} />
						</div>
					</Grid>
					<Grid item xs={8}>
						<Typography className={classes.nav_title} variant='h6' align='center'>
							{content.nav_title}
						</Typography>
						<Typography className={classes.nav_subtitle} variant='subtitle2' align='center'>
							{content.nav_subtitle}
						</Typography>
					</Grid>
					<Grid item justifyContent='center' container xs={2}>
						<Grid item xs={12} sm={4}>
							<img src={content.icon_fb} className={classes.icon_nav} />
						</Grid>
						<Grid item xs={12} sm={4}>
							<img src={content.icon_ig} className={classes.icon_nav} />
						</Grid>
						<Grid item xs={12} sm={4}>
							<img src={content.icon_tw} className={classes.icon_nav} />
						</Grid>
					</Grid>
				</Grid>
			</Toolbar>
		</AppBar>
	);
};

const useStyles = makeStyles((theme) => ({
	root: {
		background: 'transparent',
		boxShadow: 'none',
		height: 180,
		padding: '40px 70px',
		[theme.breakpoints.down('sm')]: {
			padding: '20px 30px',
		},
	},
	logo_container: {
		width: 118,
		height: 100,
		[theme.breakpoints.down('sm')]: {
			width: 71,
			height: 60,
		},
	},
	logo: {
		width: '100%',
		height: '100%',
		cursor: 'pointer',
	},
	nav_title: {
		color: 'white',
		fontWeight: 700,
		[theme.breakpoints.down('sm')]: {
			fontSize: '1rem',
		},
	},
	nav_subtitle: {
		color: '#C4C4C4',
		[theme.breakpoints.down('sm')]: {
			width: 150,
			margin: '0px auto',
		},
	},
	icon_nav: {
		width: 30,
		height: 30,
		cursor: 'pointer',
		[theme.breakpoints.down('sm')]: {
			width: 25,
			height: 25,
		},
	},
}));

export default TopbarSignUp;
