import { Button, makeStyles } from '@material-ui/core';

const GeneralButton = ({ style, fullWidth, disabled, children, onClick }) => {
	const classes = useStyles();

	return (
		<Button className={classes.root} style={style} fullWidth={fullWidth} disabled={disabled} onClick={onClick}>
			{children}
		</Button>
	);
};

const useStyles = makeStyles((theme) => ({
	root: {
		background: theme.palette.secondary.main,
		color: '#fff',
		borderRadius: 0,
		fontSize: 14,
		fontWeight: 600,
		'&:hover': {
			background: theme.palette.secondary.dark,
		},
	},
}));

export default GeneralButton;
