export const actionTypes = {
	AUTH_UPDATE_USER: 'AUTH_UPDATE_USER',
	AUTH_UPDATE_STATUS: 'AUTH_UPDATE_STATUS',
	SYS_UPDATE_DRAWER_STATUS: 'SYS_UPDATE_DRAWER_STATUS',
	SYS_UPDATE_DRAWER: 'SYS_UPDATE_DRAWER',
	SYS_UPDATE_BLUR: 'SYS_UPDATE_BLUR',
	SYS_UPDATE_TOKEN: 'SYS_UPDATE_TOKEN',
	SYS_UPDATE_SESSION_STATUS: 'SYS_UPDATE_SESSION_STATUS',
	SYS_UPDATE_ERROR: 'SYS_UPDATE_ERROR',
	SYS_UPDATE_MODAL: 'SYS_UPDATE_MODAL',
	SYS_UPDATE_LANGUAGE: 'SYS_UPDATE_LANGUAGE',
	///////////////////////SIGNUP//////////////////////
	SUP_UPDATE_PERSONAL: 'SUP_UPDATE_PERSONAL',
	SUP_UPDATE_CONTACT: 'SUP_UPDATE_CONTACT',
	SUP_UPDATE_PROGRAM: 'SUP_UPDATE_PROGRAM',
	///////////////////////// MODALS /////////////////////
	MODAL_UPDATE_CONTACT: 'MODAL_UPDATE_CONTACT',
};