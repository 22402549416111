import React from 'react';
import { TextField, withStyles, InputBase, InputLabel, Typography, FormHelperText, MenuItem } from '@material-ui/core';
import { ruleValidation } from './customFunctions';
import { makeStyles } from '@material-ui/styles';

const CssTextField = withStyles((theme) => ({
	root: {
		fontFamily: 'Raleway',
		'& input': {
			color: theme.palette.text.primary,
			background: '#EEEFF3',
			fontWeight: 600,
			padding: 15,
			fontSize: 14,
			borderWidth: 0,
		},
		'& textarea': {
			color: theme.palette.text.primary,
			padding: 15,
			fontWeight: 500,
		},
		'&.Mui-error': {},
	},
}))(InputBase);

const useStyles = makeStyles((theme) => ({
	multiline_classes: {
		color: theme.palette.text.secondary,
	},
	inputlabel: {
		marginBottom: 10,
		fontSize: 14,
		fontWeight: 500,
		fontFamily: 'Raleway',
	},
	required_star: {
		color: theme.palette.secondary.main,
		fontSize: 18,
		fontWeight: 700,
	},
}));

const InputFormSignIn = (props) => {
	const { data, onChange, startAdornment, endAdornment, onKeyUp, onKeyDown, overrideStyle } = props;
	const classes = useStyles();

	const onInnerChange = (event) => {
		const response = event.target.value;
		let temp = { ...data };
		temp.value = response;
		temp.isValid = ruleValidation(temp.value, temp.rules, temp.value2);
		temp.isVisited = true;
		onChange(temp);
	};

	const { config, value, isValid, isVisited, isRequired } = data;

	const isError = isVisited && !isValid;

	let interConfig = { ...config };
	let helperText = interConfig.helperText;
	delete interConfig['helperText'];

	if (!isError) helperText = '';

	return (
		<div>
			<InputLabel className={classes.inputlabel} htmlFor={interConfig.id}>
				{interConfig.label}
			</InputLabel>
			<CssTextField value={value} variant='standard' color='secondary' error={isError} {...interConfig} onChange={onInnerChange} style={overrideStyle} />
			<FormHelperText error={isError}>{helperText}</FormHelperText>
		</div>
	);
};

export default InputFormSignIn;
