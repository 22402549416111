import axios from 'axios'

export const nodata = '-'

export const CONFIG_SETTINGS = {
    API_BEARER:`Basic ${btoa(process.env.REACT_APP_API_USERNAME+':'+process.env.REACT_APP_API_PASSWORD)}`,
    S3_SERVER_URL:process.env.REACT_APP_S3_SERVER_URL,
}

export const DASHBOARD_SETTINGS = {
    BOXSHADOW:'0px 12px 18px rgba(0, 0, 0, 0.1)',
    BOXSHADOW_PRIMARY:'0px 6px 14px rgba(202, 203, 251, 0.87)',
    DIVIDER_LINE:'1px solid #B3B3B3',
    COUNTRY_CODE:1,
    CURRENCY_ID:2,
    TOPBAR:{
        TRANSPARENT_MODE:'TRANSPARENT_MODE',
        WHITE_MODE:'WHITE_MODE',
        HEIGHT:72
    },
    PROFILE_DRAWER:{
        WIDTH:350,
        MWIDTH:200,
        SWIDTH:260
    }
}

export const LANG_LOCALE = {
    'es':'es-MX',
    'en':'en'
}

export const private_server = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        'Content-Type': 'application/json',
    },
})

export const public_server = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        'Content-Type': 'application/json',
        'Authorization':CONFIG_SETTINGS.API_BEARER
    },
})

export const LAYOUT_SETTINGS = {
    BOXSHADOW: '0 10px 24px 0 rgba(82, 91, 115, .12)'
}
