import { makeStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import ViewHeader from '../components/ViewHeader';
import { useEffect } from 'react';

import system_translations from '../../texts/system_translations';
import SectionA from './Sections/SectionA';
import SectionB from './Sections/SectionB';
import SectionC from './Sections/SectionC';
import SectionD from './Sections/SectionD';

const AboutUsView = ({ language }) => {
	const classes = useStyles();
	const content = system_translations[language].views.about;

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<div className={classes.root}>
			<ViewHeader title={content.header_title} img={content.header_img} />
			<SectionA content={content.sectionA} />
			<SectionB content={content.sectionB} />
			<SectionC content={content.sectionC} />
			<SectionD content={content.sectionD} />
		</div>
	);
};

const useStyles = makeStyles((theme) => ({
	root: {
		background: theme.palette.layout.background,
		overflow: 'hidden',
		paddingBottom: 140,
	},
}));

const mapStateToProps = (state) => {
	return {
		language: state.language,
	};
};

export default connect(mapStateToProps)(AboutUsView);
