import bannerImg from '../../assets/banners/bannerSignin_blue.png';
import logo from '../../assets/Icons/vanguard logo_white 1.png';

const translations = {
	title: 'Sign in',
	forgotten_password: 'Forgot your password?',
	stay_signed: 'Stay signed',
	dont_have_account: "Don't have an account yet?",
	sign_up: 'Sign up',
	banner: bannerImg,
	logo: logo,
	button: 'Sign in',
	form: {
		username: {
			placeholder: 'Email',
			label: 'Email',
			helperText: 'Invalid Email',
		},
		password: {
			placeholder: 'Password',
			label: 'Password',
			helperText: 'Required field',
		},
	},
};

export default translations;
