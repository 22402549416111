import bg_banner from '../../assets/banners/bannerSignin.jpg';
import logo from '../../assets/logo_vanguard.png';
import icon_fb from '../../assets/Icons/fb_white_logo.png';
import icon_ig from '../../assets/Icons/ig_white_logo.png';
import icon_tw from '../../assets/Icons/tw_white_logo.png';
import country_list from '../countryList.json';
import country_codes from '../countryCodes.json';
import program_list from '../programs.json';
import area_list from '../areas.json';

const translations = {
	nav_title: 'Empieza tu registro',
	nav_subtitle: 'Aprendizaje a la vanguardia',
	bottom_text: '¿Ya tienes una cuenta?',
	bottom_link: 'Iniciar sesión',
	img_banner: bg_banner,
	img_logo: logo,
	icon_fb: icon_fb,
	icon_tw: icon_tw,
	icon_ig: icon_ig,

	btn_finish: 'Finalizar registro',
	personal_data: {
		title: 'Datos personales',
		form: {
			name: {
				placeholder: 'Nombre (s)',
				label: 'Nombre (s)',
				helperText: 'Campo requerido',
			},
			p_surname: {
				placeholder: 'Apellido paterno',
				label: 'Apellido paterno',
				helperText: 'Campo requerido',
			},
			m_surname: {
				placeholder: 'Apellido materno',
				label: 'Apellido materno',
				helperText: 'Campo requerido',
			},
			birth_date: {
				placeholder: '',
				label: 'Fecha de nacimiento',
				helperText: 'Selecciona una fecha válida',
			},
			country_origin: {
				label: 'País/Región de origen',
				helperText: 'Selecciona una opción válida',
			},
			sex: {
				label: 'Sexo',
				helperText: 'Selecciona una opción',
			},
		},
		country_list: country_list,
		btn_list: [
			{ name: 'Masculino', value: 1 },
			{ name: 'Femenino', value: 2 },
		],
	},
	contact_info: {
		title: 'Información de contacto',
		form: {
			email: {
				placeholder: 'Correo electrónico',
				label: 'Correo electrónico',
				helperText: 'Correo electrónico no válido',
			},
			country_code: {
				label: 'Código',
				helperText: 'Selecciona una opción',
			},
			cell_phone: {
				placeholder: 'Celular',
				label: 'Celular',
				helperText: 'Celular no válido',
			},
			phone: {
				placeholder: 'Teléfono fijo',
				label: 'Teléfono fijo',
				helperText: 'Teléfono fijo no válido',
			},
			country_residence: {
				label: 'País/Región de residencia',
				helperText: 'Selecciona una opción válida',
			},
			address: {
				placeholder: 'Dirección',
				label: 'Dirección',
				helperText: 'Campo obligatorio',
			},
			zipcode: {
				placeholder: 'Código postal',
				label: 'Código postal',
				helperText: 'Código postal no válido',
			},
		},
		country_list: country_list,
		country_codes: country_codes,
	},
	program_interest: {
		title: 'Programas de interés',
		form: {
			program: {
				label: 'Programa de interés',
				helperText: 'Selecciona una opción correcta',
			},
			area: {
				label: 'Área',
				helperText: 'Selecciona una opción correcta',
			},
			comment: {
				placeholder: 'Dudas o comentarios',
				label: 'Déjanos tus dudas o comentarios:',
				helperText: 'Longitud de mensaje incorrecta',
			},
		},

		program_list: program_list,
		area_list: area_list,
		terms_conditions1: 'Al hacer click en ',
		btn_finish: 'Finalizar registro',
		terms_conditions2: ', aceptas los ',
		terms_conditions3: ' de Instituto Vanguard',
		terms_conditions_link: 'términos y condiciones',
	},
};

export default translations;
