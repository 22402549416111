import { makeStyles, Grid, Hidden, Typography, Slide } from '@material-ui/core';
import img_footer_adornment from '../../../assets/adornments/about_sb_adornment.png';
import InViewMonitor from 'react-inview-monitor';
import { useState } from 'react';

const SectionB = ({ content }) => {
	const classes = useStyles();
	const items = content.principles_array;
	const [onView, setOnView] = useState(false);

	return (
		<div className={classes.root}>
			<div className={classes.body_container}>
				<Grid container alignItems='center' className={classes.title_container}>
					<Hidden smDown>
						<Grid item md={7} xs={2}>
							<div className={classes.title_divider} />
						</Grid>
					</Hidden>
					<Grid item md={5} xs={10}>
						<Typography align='right' className={classes.title_text} variant='h4'>
							{content.header_title}
						</Typography>
					</Grid>
				</Grid>
				<InViewMonitor
					onInView={() => {
						setOnView(true);
					}}
				>
					<Slide direction='up' in={onView} timeout={1200}>
						<Grid container justifyContent='center' className={classes.principles_container}>
							{items.map((item, i) => (
								<Item key={i} item={item} index={i} />
							))}
						</Grid>
					</Slide>
				</InViewMonitor>
				<div className={classes.title_divider} style={{ width: '60%', margin: '100px 0px 100px auto' }} />
			</div>

			<div className={classes.footer_container}>
				<Hidden mdDown>
					<img src={img_footer_adornment} />
				</Hidden>
				<Typography variant='h5' className={classes.footer_title}>
					{content.footer_title}
				</Typography>
			</div>
		</div>
	);
};

const useStyles = makeStyles((theme) => ({
	root: {},
	body_container: {
		background: `linear-gradient(${theme.palette.layout.background} 23%, ${theme.palette.primary.main} 23%)`,
		padding: '50px 140px',
		[theme.breakpoints.down('sm')]: {
			background: `linear-gradient(${theme.palette.layout.background} 10%, ${theme.palette.primary.main} 10%)`,
			padding: '50px 25px',
		},
	},
	title_container: {
		width: '100%',
	},
	title_text: {
		fontSize: 40,
		[theme.breakpoints.down('sm')]: {
			fontSize: 30,
		},
	},
	title_divider: {
		width: '100%',
		height: 0,
		border: '2px solid #D3BD7B',
	},
	principles_container: {
		width: 1400,
		margin: '0px auto',
		[theme.breakpoints.down('md')]: {
			width: '100%',
			margin: '0px 10%',
		},
		[theme.breakpoints.down('xs')]: {
			width: 300,
			margin: '0px auto',
		},
	},
	footer_container: {
		position: 'relative',
		height: 267,
		background: '#FEFDF9',
		textAlign: 'center',
	},
	footer_title: {
		width: '100%',
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%,-50%)',
		color: '#7E6826',
		textShadow: '0px 4px 9px rgba(0, 0, 0, 0.25)',
		[theme.breakpoints.down('md')]: {
			fontSize: 22,
		},
	},
}));

const Item = ({ item, index }) => {
	const isEven = index % 2 === 0;
	const classes = useItemStyles({ isEven });

	return (
		<Grid md={4} sm={6} xs={12} item className={classes.container}>
			<Typography variant='h1' className={classes.number}>
				{index + 1 < 10 ? `0${index + 1}` : index + 1}
			</Typography>
			<Typography variant='subtitle1' className={classes.title}>
				{item.title}
			</Typography>
			<Typography className={classes.content} variant='body1'>
				{item.content}
			</Typography>
			<div className={classes.vertical_adornment} style={!isEven ? { left: 0 } : { left: 115 }}></div>
			<div className={classes.horizontal_adornment} style={!isEven ? { top: 140 } : { top: -30 }}></div>
		</Grid>
	);
};

const useItemStyles = makeStyles((theme) => ({
	container: {
		margin: '100px 0px 60px',
		position: 'relative',
		[theme.breakpoints.down('md')]: {
			margin: '50px 0px',
		},
	},
	number: {
		fontSize: 206,
		fontFamily: 'Poppins',
		color: 'rgba(232, 202, 112, 0.50)',
		letterSpacing: -5,
	},
	title: {
		textTransform: 'uppercase',
		color: 'white',
		margin: '30px 0px 20px',
	},
	content: {
		color: 'white',
		fontWeight: 300,
		maxWidth: 270,
	},
	vertical_adornment: {
		position: 'absolute',
		width: 165,
		height: 338,
		top: -30,
		background: 'rgba(232, 202, 112, 0.11)',
	},
	horizontal_adornment: {
		position: 'absolute',
		width: 279,
		height: 169,
		background: 'rgba(255, 255, 255, 0.03)',
	},
}));

export default SectionB;
