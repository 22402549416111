import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import WebsiteLayout from '../layouts/WebsiteLayout/WebsiteLayout';
import SignInLayout from '../layouts/SignInLayout/SignInLayout';
import SignUpLayout from '../layouts/SignUpLayout/SignUpLayout';

const MainRouter = ({ history }) => {
	// In this section always goes SignIn, Signup, FAQs, Privacy Notice, etc
	return (
		<Router history={history}>
			<Switch>
				<Route path={'/signin'} component={SignInLayout} />
				<Route path={'/signup'} component={SignUpLayout} />
				<Route path={'/'} component={WebsiteLayout} />
				<Redirect to='/' />
			</Switch>
		</Router>
	);
};

export default MainRouter