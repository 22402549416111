import { makeStyles, Hidden, Typography, useTheme, useMediaQuery, Button, Icon, Paper, Fade } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import Carousel from 'react-material-ui-carousel';
import InViewMonitor from 'react-inview-monitor';

import img_lmb from '../../../assets/home_view/learn_more_button.png';

const SectionE = ({ content, history }) => {
	const classes = useStyles();
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const [onView, setOnView] = useState(false);

	const items = content.carousel;

	let marginNav = isMobile ? '0px' : `calc(50% - ${items.length * 40}px)`;

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<>
			<InViewMonitor
				onInView={() => {
					setOnView(true);
				}}
			>
				<Fade in={onView} timeout={1000}>
					<div className={classes.root}>
						<div className={classes.header_container}>
							<Typography variant='h4' align='center' className={classes.header_title}>
								{content.header_title}
							</Typography>
							<Typography variant='h6' align='center' color='textSecondary' className={classes.header_text}>
								{content.header_text}
							</Typography>
						</div>
						<Carousel
							className={classes.carousel}
							stopAutoPlayOnHover={false}
							interval={6000}
							PrevIcon={<Icon style={{ fontSize: 40 }}>chevron_left</Icon>}
							NextIcon={<Icon style={{ fontSize: 40 }}>chevron_right</Icon>}
							indicatorIconButtonProps={{
								style: {
									padding: '0px 12.5px',
									color: 'rgba(255, 255, 255, 0.44)',
								},
							}}
							activeIndicatorIconButtonProps={{
								style: {
									color: 'white',
								},
							}}
							indicatorContainerProps={{
								style: {},
							}}
							navButtonsProps={{
								style: {
									backgroundColor: 'transparent',
									borderRadius: 0,
								},
							}}
							navButtonsWrapperProps={{
								style: {
									height: 20,
									bottom: 60,
									top: 'unset',
									marginLeft: marginNav,
									marginRight: marginNav,
								},
							}}
							navButtonsAlwaysVisible
						>
							{items.map((item, i) => (
								<Item key={i} item={item} history={history} button_text={content.carousel_button} />
							))}
						</Carousel>
					</div>
				</Fade>
			</InViewMonitor>
		</>
	);
};

export default withRouter(SectionE);

const Item = ({ item, history, button_text }) => {
	const classes = usePaperStyles();
	const onChangeView = (url) => {
		history.push(url);
	};

	return (
		<Paper className={classes.paper}>
			<img src={item.img} className={classes.img_carousel} />
			<div className={classes.carousel_content}>
				<Typography variant='subtitle2' className={classes.carousel_cat}>
					{item.cat}
				</Typography>
				<Typography variant='h4' className={classes.carousel_title}>
					{item.title}
				</Typography>
				<Typography variant='body1' className={classes.carousel_desc}>
					{item.content}
				</Typography>
			</div>
			<Hidden smDown>
				<div className={classes.carousel_link} onClick={() => onChangeView('/')}>
					<Typography variant='button' className={classes.carousel_link_text}>
						{button_text}
					</Typography>
					<img src={img_lmb} className={classes.img_carousel_link} />
				</div>
			</Hidden>
			<Hidden mdUp>
				<Button className={classes.button} onClick={() => onChangeView('/')}>
					{button_text}
				</Button>
			</Hidden>
		</Paper>
	);
};

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'relative',
		margin: '100px 0px',
		background: theme.palette.layout.background,
		[theme.breakpoints.down('sm')]: {
			margin: '50px 0px',
		},
	},
	header_container: {
		padding: '0px 100px 80px',
		[theme.breakpoints.down('md')]: {
			padding: '0px 15px 30px',
		},
	},
	header_title: {
		[theme.breakpoints.down('sm')]: {
			fontSize: 28,
		},
	},
	header_text: {
		[theme.breakpoints.down('sm')]: {
			fontSize: 16,
			marginTop: 10,
		},
	},
	carousel: {
		paddingBottom: 50,
	},
}));

const usePaperStyles = makeStyles((theme) => ({
	root: {
		position: 'relative',
		background: theme.palette.primary.main,
	},
	paper: {
		height: 713,
		// '&::after': {
		// 	content: '""',
		// 	display: 'block',
		// 	position: 'absolute',
		// 	top: 0,
		// 	left: 0,
		// 	width: '100%',
		// 	height: '100%',
		// 	background: 'linear-gradient(180deg, rgba(8, 37, 79, 0.36) 6.59%, rgba(12, 66, 162, 0.306) 57.22%, rgba(8, 37, 79, 0.36) 100%)',
		// },
	},
	img_carousel: {
		width: '100%',
		height: '100%',
		objectFit: 'cover',
		position: 'absolute',
	},
	carousel_title: {
		textShadow: '4px 4px 10px rgba(153, 153, 153, 0.53)',
		marginBottom: 28,
		[theme.breakpoints.down('sm')]: {
			fontSize: '3rem',
		},
	},
	carousel_content: {
		color: 'white',
		position: 'absolute',
		top: 430,
		left: 140,
		maxWidth: 400,
		fontSize: 18,
		[theme.breakpoints.down('sm')]: {
			top: '30%',
			left: 50,
			maxWidth: 350,
		},
	},
	carousel_desc: {
		fontSize: 20,
	},
	carousel_link: {
		cursor: 'pointer',
		width: 202,
		height: 321,
		position: 'absolute',
		marginLeft: 'calc(100% - 202px)',
		top: 473,
	},
	img_carousel_link: {
		width: '100%',
		height: '100%',
	},
	carousel_link_text: {
		position: 'absolute',
		color: 'white',
		fontSize: 20,
		lineHeight: 16,
		left: 20,
	},
	button: {
		background: theme.palette.secondary.main,
		color: '#fff',
		height: 76,
		width: 186,
		borderRadius: 0,
		margin: '0px auto',
		fontSize: 14,
		position: 'absolute',
		top: 580,
		marginLeft: 50,
		'&:hover': {
			background: theme.palette.secondary.dark,
		},
	},
}));
