import { useState, useEffect } from 'react';
import { private_server, public_server } from '../../../config';
import {
	isFormValid,
	onGetErrorMessage,
	onGetFormData,
	onGetSelectedFormData,
	onInitForm,
	onSetErrorsToForm,
	onSetTokenSession,
	updateAuthorizationHeader,
} from '../../../shared/utility';
import system_translations from '../../../texts/system_translations';

const useContactGeneral = ({ language, content, onUpdateAuthStatus, onUpdateUserData }) => {
	const [loading, setLoading] = useState(false);
	const [sending, setSending] = useState(false);
	const [error, setError] = useState(null);
	const [form, setForm] = useState(JSON.parse(JSON.stringify(form_data)));

	const errors_txt = system_translations[language].layouts.errors;

	useEffect(() => {
		actions.onInitModule();
	}, []);

	const formatCountryCodes = () => {
		let cat = content.codes.map((item) => {
			let temp = {};
			temp.label = `${item.dial_code}: ${item.name}`;
			temp.value = item.dial_code;
			return temp;
		});

		return cat;
	};

	const actions = {
		onInitModule: async () => {
			let _form = onInitForm(form, null, content.form);
			_form.country_code.options = formatCountryCodes();
			setForm(_form);
		},
		onChangeForm: (data) => {
			let temp = { ...form };
			const id = data.config.id;
			temp[id] = { ...data };
			if (error) setError(null);
			setForm(temp);
		},
		onKeyPress: (event) => {
			if (event.key === 'Enter') {
				actions.onSubmit();
			}
		},
		onSubmit: async () => {
			const errors = isFormValid(form);

			if (errors && errors.length) {
				console.log(errors);
				const _form = onSetErrorsToForm(form, errors);
				setForm(_form);
				setError(errors_txt.INCOMPLETE_FORM);
				return;
			}

			const data2send = onGetFormData(form);
			data2send.device = 'web';
			console.log(data2send);

			setSending(true);

			try {
				const response = await public_server.post(`/general-contact`, data2send);
				const token_data = response.data.data;

				setError(null);
			} catch (error) {
				setSending(false);
				const _error = onGetErrorMessage(error);
				setError(_error ? _error.message : null);
			}

			setSending(false);

			/* setLoading(true)
            try {
                updateAuthorizationHeader(private_server)
                await private_server.patch('/users/me', data2send)
                const _user = await onGetCurrentUserProfile()
                onUpdateUserData(_user)

            } catch (error) {
                console.log(error)
            }
            setLoading(false) */
		},
	};

	const system = { loading, sending, error };

	return { system, form, actions };
};

export default useContactGeneral;

const form_data = {
	name: {
		value: '',
		error: false,
		isVisited: false,
		isRequired: true,
		isValid: false,
		config: {
			id: 'name',
			type: 'text',
			fullWidth: true,
		},
		rules: {
			type: 'distance',
			min: 1,
			max: 254,
		},
	},
	email: {
		value: '',
		error: false,
		isVisited: false,
		isRequired: true,
		isValid: false,
		config: {
			id: 'email',
			type: 'email',
			fullWidth: true,
		},
		rules: {
			type: 'email',
		},
	},
	country_code: {
		value: '+52',
		error: false,
		isVisited: false,
		isRequired: true,
		isValid: false,
		config: {
			id: 'country_code',
			fullWidth: true,
		},
		rules: {
			type: 'select',
		},
	},
	phone: {
		value: '',
		error: false,
		isVisited: false,
		isRequired: true,
		isValid: false,
		config: {
			id: 'phone',
			type: 'text',
			fullWidth: true,
		},
		rules: {
			type: 'phone_number',
		},
	},
	message: {
		value: '',
		error: false,
		isVisited: false,
		isRequired: true,
		isValid: false,
		config: {
			id: 'message',
			type: 'text',
			fullWidth: true,
			multiline: true,
			rows: 6,
		},
		rules: {
			type: 'distance',
			min: 1,
			max: 500,
		},
	},
};
