import { makeStyles, Typography, Grid, Icon, Hidden, Slide, Fade } from '@material-ui/core';
import { withRouter } from 'react-router';
import { useState } from 'react';
import InViewMonitor from 'react-inview-monitor';

const NewsHighlights = ({ history, content, news }) => {
	const classes = useStyles();
	const [onView, setOnView] = useState(false);

	const onChangeView = (url) => {
		history.push(url);
	};
	const items = news.news_array;

	return (
		<InViewMonitor
			onInView={() => {
				setOnView(true);
			}}
		>
			<Fade in={onView} timeout={800}>
				<div className={classes.root}>
					<Grid container justifyContent='center' alignItems='center' className={classes.title_container}>
						<Hidden smDown>
							<Grid item md={7} xs={2}>
								<div className={classes.title_divider} />
							</Grid>
						</Hidden>
						<Grid item md={5} xs={10}>
							<Typography align='center' className={classes.title_text} variant='h4'>
								{content.news_highlights}
							</Typography>
						</Grid>
					</Grid>
					<div className={classes.news_container}>
						<Typography variant='subtitle1' className={classes.year}>
							{news.year}
						</Typography>
						{items.map((item, i) => (
							<Item key={i} item={item} index={i} onChangeView={onChangeView} />
						))}
					</div>
				</div>
			</Fade>
		</InViewMonitor>
	);
};

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'relative',
		background: theme.palette.layout.background,
		margin: '100px 0px',
		color: theme.palette.text.primary,
	},
	title_container: {
		width: '100%',
		padding: '0px 140px',
		[theme.breakpoints.down('sm')]: {
			padding: '0px 10px',
		},
	},
	title_text: {
		fontSize: 40,
		[theme.breakpoints.down('sm')]: {
			fontSize: 30,
		},
	},
	title_divider: {
		width: '100%',
		height: 0,
		border: '2px solid #D3BD7B',
	},
	news_container: {
		width: '100%',
		margin: '120px 0px',
		[theme.breakpoints.down('sm')]: {
			margin: '50px 0px',
		},
	},
	year: {
		fontSize: 30,
		marginLeft: 140,
		[theme.breakpoints.down('sm')]: {
			marginLeft: 25,
		},
	},
}));

const Item = ({ item, index, onChangeView }) => {
	const classes = useItemStyles();
	const isEven = index % 2 === 0;

	return (
		<div className={classes.main_container} style={!isEven ? { background: '#FAF7EE' } : { background: '#E5E5E5' }}>
			<Grid container className={classes.grid_container}>
				<Grid item md={6} xs={12}>
					<div className={classes.img_container}>
						<img src={item.img} className={classes.img} />
					</div>
				</Grid>
				<Grid item md={6} xs={12} className={classes.content_container}>
					<Typography className={classes.date}>{item.date}</Typography>
					<Typography className={classes.title} variant='h4'>
						{item.title}
					</Typography>
					<Typography variant='body1' className={classes.content}>
						{item.content_preview}
					</Typography>
					<div
						className={classes.link_container}
						onClick={() => {
							onChangeView(item.link_path);
						}}
					>
						<Typography variant='subtitle1' style={{ display: 'inline-block' }}>
							{item.link_text}
						</Typography>
						<Icon style={{ fontSize: 30, display: 'table-cell', verticalAlign: 'middle' }}>chevron_right</Icon>
					</div>
				</Grid>
			</Grid>
		</div>
	);
};

const useItemStyles = makeStyles((theme) => ({
	main_container: {
		height: 500,
		position: 'relative',
		[theme.breakpoints.down('sm')]: {
			height: 'auto',
		},
	},
	grid_container: {
		width: 1100,
		height: '100%',
		padding: '70px 0px 94px 0px',
		borderBottom: '3px solid #CECECE',
		margin: '0px auto',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			padding: '70px 25px 94px 25px',
		},
	},
	img_container: {
		width: '100%',
		height: 340,
		marginLeft: 'auto',
		[theme.breakpoints.down('sm')]: {
			marginBottom: 20,
		},
	},
	img: {
		width: '100%',
		height: '100%',
		objectFit: 'cover',
		objectPosition: 'top center',
		borderRadius: 15,
	},
	content_container: {
		paddingLeft: 55,
		position: 'relative',
		[theme.breakpoints.down('sm')]: {
			padding: 0,
		},
	},
	date: {
		fontStyle: 'italic',
		fontWeight: 600,
		fontSize: 17,
		color: theme.palette.text.secondary,
	},
	title: {
		fontSize: 20,
	},
	content: {
		marginTop: 30,
	},
	link_container: {
		position: 'absolute',
		bottom: 0,
		color: theme.palette.secondary.main,
		textTransform: 'uppercase',
		display: 'table',
		cursor: 'pointer',
		[theme.breakpoints.down('sm')]: {
			position: 'relative',
			marginTop: 20,
		},
	},
}));

export default withRouter(NewsHighlights);
