import { AppBar, Button, Icon, makeStyles, Paper, Typography } from '@material-ui/core';
import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { actionTypes } from '../../store/actions';
import system_translations from '../../texts/system_translations';
import TopbarSignUp from './components/TopbarSignUp';
import ContactInfo from './ContactInfo/ContactInfo';
import PersonalData from './PersonalData/PersonalData';
import ProgramInterest from './ProgramInterest/ProgramInterest';

const SignUpLayout = ({ language, history, forms, updateSignupContact, updateSignupPersonal, updateSignupProgram }) => {
	const classes = useStyles();
	const content = system_translations[language].layouts.signup;

	const [step, setStep] = useState(0);

	const onChangeView = (url) => {
		history.push(url);
	};

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const onNextStep = (form, isValid) => {
		if (step <= 1 && isValid) {
			switch (step) {
				case 0:
					updateSignupPersonal(form);
					break;
				case 1:
					updateSignupContact(form);
					break;
				case 2:
					updateSignupProgram(form);
					break;
				default:
					break;
			}
			setStep(step + 1);
		}
	};

	const onPrevStep = (form) => {
		if (step >= 1) {
			switch (step) {
				case 0:
					updateSignupPersonal(form);
					break;
				case 1:
					updateSignupContact(form);
					break;
				case 2:
					updateSignupProgram(form);
					break;
				default:
					break;
			}
			setStep(step - 1);
		}
	};

	return (
		<div className={classes.root}>
			<img src={content.img_banner} className={classes.img_bg} />
			<div className={classes.bg_overlay} />
			<TopbarSignUp content={content} onChangeView={onChangeView} />
			<Paper className={classes.paper}>
				<div className={classes.main_container}>
					<div className={classes.indicators_container}>
						<div className={step >= 0 ? classes.active_indicator : classes.unactive_indicator}>1</div>
						<div className={step >= 1 ? classes.active_indicator_divider : classes.unactive_indicator_divider} />
						<div className={step >= 1 ? classes.active_indicator : classes.unactive_indicator}>2</div>
						<div className={step === 2 ? classes.active_indicator_divider : classes.unactive_indicator_divider} />
						<div className={step === 2 ? classes.active_indicator : classes.unactive_indicator}>3</div>
					</div>

					<div className={classes.form_container}>
						{
							{
								0: <PersonalData content={content.personal_data} language={language} saved_form={forms.personal} onNextStep={onNextStep} onPrevStep={onPrevStep} />,
								1: <ContactInfo content={content.contact_info} language={language} saved_form={forms.contact} onNextStep={onNextStep} onPrevStep={onPrevStep} />,
								2: <ProgramInterest content={content.program_interest} language={language} saved_form={forms.program} onNextStep={onNextStep} onPrevStep={onPrevStep} />,
							}[step]
						}
					</div>
				</div>
			</Paper>
			<div className={classes.bottom_container}>
				<Typography align='center' className={classes.bottom_text}>
					{content.bottom_text}
				</Typography>
				<Typography align='center' className={classes.bottom_link}>
					{content.bottom_link}
				</Typography>
			</div>
		</div>
	);
};

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'relative',
		height: 1024,
		minHeight: '100vh',
		[theme.breakpoints.down('sm')]: {
			height: 1000,
		},
		[theme.breakpoints.down('xs')]: {
			height: 1200,
		},
	},
	img_bg: {
		height: '100%',
		width: '100%',
		objectFit: 'cover',
		objectPosition: 'center center',
		zIndex: '1',
	},
	bg_overlay: {
		position: 'absolute',
		width: '100%',
		height: '100%',
		top: 0,
		left: 0,
		background: 'linear-gradient(180deg, #08254F 0%, rgba(8, 37, 79, 0.34) 202.25%)',
		zIndex: '2',
	},
	paper: {
		width: 909,
		height: 645,
		background: '#FCFCFC',
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%,-50%)',
		zIndex: '3',
		borderRadius: 10,
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			height: 700,
		},
		[theme.breakpoints.down('xs')]: {
			width: '100%',
			height: 920,
		},
	},
	main_container: {
		position: 'relative',
		height: '100%',
	},
	indicators_container: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		paddingTop: 40,
	},
	unactive_indicator: {
		width: 35,
		height: 35,
		color: '#747474',
		background: 'transparent',
		border: '1px solid #DBDBDB',
		borderRadius: 25,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	active_indicator: {
		width: 35,
		height: 35,
		color: 'white',
		background: theme.palette.secondary.main,
		border: `1px solid ${theme.palette.secondary.main}`,
		borderRadius: 25,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	unactive_indicator_divider: {
		width: 24,
		height: 0,
		borderTop: `1px solid #DBDBDB`,
	},
	active_indicator_divider: {
		width: 24,
		height: 0,
		borderTop: `1px solid ${theme.palette.secondary.main}`,
	},

	form_container: {
		padding: '15px 0px',
		boxSizing: 'border-box',
	},

	bottom_container: {
		position: 'absolute',
		bottom: 100,
		left: '50%',
		transform: 'translateX(-50%)',
		zIndex: '3',
		[theme.breakpoints.down('sm')]: {
			bottom: 60,
		},
	},
	bottom_text: {
		color: 'white',
		fontSize: 16,
		fontWeight: 500,
		display: 'inline-block',
		marginRight: 8,
	},
	bottom_link: {
		color: 'white',
		fontSize: 16,
		fontWeight: 600,
		cursor: 'pointer',
		textDecoration: 'underline',
		display: 'inline-block',
	},
}));

const mapStateToProps = (state) => {
	return {
		language: state.language,
		forms: state.signup,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		updateSignupPersonal: (signup_personal) => dispatch({ type: actionTypes.SUP_UPDATE_PERSONAL, signup_personal }),
		updateSignupContact: (signup_contact) => dispatch({ type: actionTypes.SUP_UPDATE_CONTACT, signup_contact }),
		updateSignupProgram: (signup_program) => dispatch({ type: actionTypes.SUP_UPDATE_PROGRAM, signup_program }),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUpLayout);
