import { makeStyles, Paper, Typography, Grid, Hidden, Slide } from '@material-ui/core';
import { useState } from 'react';
import InViewMonitor from 'react-inview-monitor';

const SectionA = ({ content }) => {
	const classes = useStyles();
	const [onView, setOnView] = useState(false);

	return (
		<InViewMonitor
			onInView={() => {
				setOnView(true);
			}}
		>
			<div className={classes.root}>
				<div className={classes.description_container}>
					<Typography className={classes.description_title}>{content.desc_title}</Typography>
					<Typography variant='h6' className={classes.description_content}>
						{content.desc_content}
					</Typography>
				</div>
				{/* <Hidden mdDown>
				<div className={classes.v_adornment_container}>
					<Typography className={classes.v_adornment}>V</Typography>
				</div>
			</Hidden> */}

				<Grid container spacing={2} className={classes.cards_container}>
					<Grid item md={6} xs={12}>
						<Slide direction='right' in={onView} timeout={800}>
							<Paper elevation={0} className={classes.card}>
								<Typography className={classes.card_title} variant='h4'>
									{content.left_card_title}
								</Typography>
								<div className={classes.card_divider} />
								<Typography className={classes.card_content} variant='caption'>
									{content.left_card_content}
								</Typography>
							</Paper>
						</Slide>
					</Grid>
					<Grid item md={6} xs={12}>
						<Slide direction='left' in={onView} timeout={800}>
							<Paper elevation={0} className={classes.card}>
								<Typography className={classes.card_title} variant='h4'>
									{content.right_card_title}
								</Typography>
								<div className={classes.card_divider} />
								<Typography className={classes.card_content} variant='caption'>
									{content.right_card_content}
								</Typography>
							</Paper>
						</Slide>
					</Grid>
				</Grid>
			</div>
		</InViewMonitor>
	);
};

const useStyles = makeStyles((theme) => ({
	root: {
		background: `linear-gradient(rgba(146, 185, 217, 0.13) 50%, ${theme.palette.layout.background} 50%)`,
		padding: '50px 140px',
		[theme.breakpoints.down('sm')]: {
			padding: '50px 25px',
		},
	},
	description_container: {
		width: 483,
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			margin: '0px auto',
		},
	},
	description_title: {
		fontSize: 40,
		fontWeight: 700,
		color: '#BB9420',
		textShadow: '4px 4px 9px rgba(187, 148, 32, 0.38)',
		[theme.breakpoints.down('sm')]: {
			textAlign: 'center',
		},
	},
	description_content: {
		color: '#7F7F7F',
	},
	v_adornment_container: {
		position: 'absolute',
		height: 950,
		top: -69,
		right: -200,
		overflow: 'hidden',
	},
	v_adornment: {
		fontSize: 1006,
		color: 'white',
		fontWeight: 600,
	},
	cards_container: {
		width: 950,
		margin: '77px auto',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	card: {
		height: 500,
		boxShadow: '0px 5px 101px rgba(0, 0, 0, 0.05)',
		borderRadius: 25,
		textAlign: 'center',
		padding: '75px 55px',
		[theme.breakpoints.down('sm')]: {
			height: 420,
		},
	},
	card_title: {
		fontWeight: 700,
		color: '#374D6E',
	},
	card_divider: {
		width: 74,
		height: 0,
		border: '2px solid #E8CA70',
		margin: '35px auto 26px',
	},
	card_content: {
		fontStyle: 'italic',
		fontSize: 18,
		fontFamily: 'Source Serif Pro',
	},
}));

export default SectionA;
