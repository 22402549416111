import { makeStyles, Dialog, Grid, Icon, Typography, useTheme, useMediaQuery, Hidden } from '@material-ui/core';
import GeneralButton from '../../../components/Actions/GeneralButton';

const ProgramDetails = ({ content, open, handleCloseModal }) => {
	const classes = useStyles();
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

	return (
		<Dialog
			open={open}
			onClose={handleCloseModal}
			maxWidth={'lg'}
			className={classes.root}
			fullScreen={fullScreen}
			PaperProps={{
				style: {
					borderRadius: 10,
					background: '#FCFCFC',
				},
			}}
		>
			<Grid container className={classes.main_container}>
				<Hidden smDown>
					<Grid item xs={5}>
						<img src={content.img} className={classes.img} />
					</Grid>
				</Hidden>

				<Grid item xs={12} md={7}>
					<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
						<Icon className={classes.close_icon} onClick={handleCloseModal}>
							close
						</Icon>
					</div>
					<div className={classes.content_container}>
						<Typography variant='subtitle2' className={classes.type}>
							{content.type}
						</Typography>
						<Typography variant='h4' style={{ textShadow: '4px 4px 8px rgba(0, 0, 0, 0.23)' }}>
							{content.title}
						</Typography>
						<Typography variant='subtitle1' style={{ fontWeight: 300, marginTop: 20 }}>
							{content.desc}
						</Typography>
						<Grid container className={classes.details_container}>
							<Grid item container justifyContent='center' xs={6} className={classes.details_l_side}>
								<Grid item xs={12}>
									<Typography variant='subtitle1' style={{ marginBottom: 15 }}>
										{content.left_title}
									</Typography>
								</Grid>
								<Grid item xs={12} style={{ marginBottom: 15 }}>
									<Typography variant='h5' className={classes.left_content}>
										{content.left_content_1}
									</Typography>
								</Grid>
								<Grid item xs={12}>
									<Typography variant='h5' className={classes.left_content}>
										{content.left_content_2}
									</Typography>
									<Typography variant='subtitle1' className={classes.note}>
										{content.note}
									</Typography>
								</Grid>
							</Grid>
							<Grid item container justifyContent='center' className={classes.details_r_side} xs={6}>
								<Grid item xs={12}>
									<Typography variant='subtitle1' style={{ marginBottom: 15 }}>
										{content.right_title}
									</Typography>
								</Grid>
								<Grid item xs={12} style={{ marginBottom: 15 }}>
									<Typography className={classes.right_content} color='secondary' variant='h5'>
										{content.right_content_1}
									</Typography>
								</Grid>
								<Grid item xs={12}>
									<Typography className={classes.right_content} color='secondary' variant='h5'>
										{content.right_content_2}
									</Typography>
								</Grid>
							</Grid>
							<Grid item container justifyContent='center' xs={12}>
								<GeneralButton style={{ width: 169, height: 76, marginTop: 93 }}>{content.button}</GeneralButton>
							</Grid>
							<Grid item xs={12}>
								<Typography className={classes.note_desc}>{content.note_desc}</Typography>
							</Grid>
						</Grid>
					</div>
				</Grid>
			</Grid>
		</Dialog>
	);
};

const useStyles = makeStyles((theme) => ({
	root: {
		borderRadius: 10,
	},
	main_container: {
		overflow: 'hidden',
		width: 1030,
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	img: {
		width: '100%',
		height: '100%',
		objectFit: 'cover',
		objectPosition: '30% center',
	},
	close_icon: {
		color: '#6A6A6A',
		fontSize: 40,
		margin: '40px 40px 0px 0px',
		cursor: 'pointer',
	},
	content_container: {
		padding: '15px 40px',
	},
	type: {
		fontWeight: 700,
		color: '#9E9E9E',
	},
	details_container: {
		width: 480,
		margin: '60px auto',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	left_content: {
		fontFamily: theme.typography.numberFont,
		fontWeight: 700,
		display: 'inline',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.5rem',
		},
	},
	note: {
		fontWeight: 600,
		color: theme.palette.secondary.main,
		display: 'inline',
		marginLeft: 20,
	},
	right_content: {
		fontFamily: theme.typography.numberFont,
		fontWeight: 700,
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.5rem',
		},
	},
	note_desc: {
		fontSize: 12,
		color: theme.palette.secondary.main,
		fontWeight: 600,
		textAlign: 'center',
		marginTop: 30,
	},
	details_r_side: {
		paddingLeft: 60,
		[theme.breakpoints.down('sm')]: {
			paddingLeft: 50,
		},
	},
	details_l_side: {
		borderRight: '2px solid #D3D3D3',
		[theme.breakpoints.down('sm')]: {
			borderRight: '0px solid #D3D3D3',
		},
	},
}));

export default ProgramDetails;
