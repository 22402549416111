import { createStore } from 'redux'
import { updateObject } from '../shared/utility'
import { actionTypes } from './actions'

const initialState = {
	user: null,
	is_auth: false,
	token: null,
	language: 'es',
	cart: null,
	ip_address: null,
	drawer_status: false,
	blur: false,
	/////////// Modals /////////////
	modals: {
		contact: false,
	},

	////////SIGNUP////////////
	signup: {
		personal: null,
		contact: null,
		program: null,
	},

	// Error Handler
	error: null,
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.AUTH_UPDATE_USER:
			return updateObject(state, { user: action.user });
		case actionTypes.AUTH_UPDATE_STATUS:
			return updateObject(state, { is_auth: action.is_auth });
		case actionTypes.SYS_UPDATE_IP_ADDRESS:
			return updateObject(state, { ip_address: action.ip_address });
		case actionTypes.SYS_UPDATE_DRAWER_STATUS:
			return updateObject(state, { drawer_status: action.drawer_status });
		case actionTypes.SYS_UPDATE_BLUR:
			return updateObject(state, { blur: action.blur });
		case actionTypes.SYS_UPDATE_TOKEN:
			return updateObject(state, { token: action.token });
		case actionTypes.SYS_UPDATE_SESSION_STATUS:
			return updateObject(state, { session_status: action.session_status });
		case actionTypes.SYS_UPDATE_LANGUAGE:
			return updateObject(state, { language: action.language });
		//////////////////////   MODALS   //////////////////////
		case actionTypes.SYS_UPDATE_MODAL:
			return updateObject(state, { modals: { ...state.modals, ...action.modal } });
		case actionTypes.MODAL_UPDATE_CONTACT:
			return updateObject(state, { modals: { ...state.modals, contact: action.contact_modal } });

		///////////////////////SIGNUP///////////////////////
		case actionTypes.SUP_UPDATE_CONTACT:
			return updateObject(state, { signup: { ...state.signup, contact: action.signup_contact } });
		case actionTypes.SUP_UPDATE_PERSONAL:
			return updateObject(state, { signup: { ...state.signup, personal: action.signup_personal } });
		case actionTypes.SUP_UPDATE_PROGRAM:
			return updateObject(state, { signup: { ...state.signup, program: action.signup_program } });

		//////////////////////   ERROR HANDLER   //////////////////////
		case actionTypes.SYS_UPDATE_ERROR:
			return updateObject(state, { error: action.error });
		default:
			return state;
	}
};

export const store = createStore(reducer)

