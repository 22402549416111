import { makeStyles, Grid, Typography, IconButton, Hidden } from '@material-ui/core';
import InputFormGray from '../../../components/Forms/InputFormGray';
import useContactInfo from './useContactInfo';
import InputSelectFormGray from '../../../components/Forms/InputSelectFormGray';
import { isFormValid } from '../../../shared/utility';
import ControlButtons from '../components/ControlButtons';

const ContactInfo = ({ content, language, saved_form, onPrevStep, onNextStep }) => {
	const classes = useStyles();
	let { system, form, actions } = useContactInfo({ language, content, saved_form });

	return (
		<>
			<Typography align='center' className={classes.title}>
				{content.title}
			</Typography>
			<Grid container justifyContent='center'>
				<Grid item xs={12} md={5} className={classes.input_container}>
					<InputFormGray data={form.email} onChange={actions.onChangeForm} onKeyPress={actions.onKeyPress} />
				</Grid>
				<Hidden smDown>
					<Grid item xs={12} md={2} style={{ maxWidth: 60 }} />
				</Hidden>
				<Grid item container xs={12} className={classes.input_container}>
					<Grid item xs={2} className={classes.country_code}>
						<InputSelectFormGray data={form.country_code} onChange={actions.onChangeForm} onKeyPress={actions.onKeyPress} />
					</Grid>
					<Grid item xs={10} className={classes.cell_phone}>
						<InputFormGray data={form.cell_phone} onChange={actions.onChangeForm} onKeyPress={actions.onKeyPress} overrideStyle={{ fontFamily: 'Open Sans' }} />
					</Grid>
				</Grid>

				<Grid item xs={12} md={5} className={classes.input_container}>
					<InputFormGray data={form.phone} onChange={actions.onChangeForm} onKeyPress={actions.onKeyPress} overrideStyle={{ fontFamily: 'Open Sans' }} />
				</Grid>
				<Hidden smDown>
					<Grid item xs={2} style={{ maxWidth: 60 }} />
				</Hidden>
				<Grid item xs={12} md={5} className={classes.input_container}>
					<InputSelectFormGray data={form.country_residence} onChange={actions.onChangeForm} />
				</Grid>

				<Grid item xs={12} md={5} className={classes.input_container}>
					<InputFormGray data={form.address} onChange={actions.onChangeForm} onKeyPress={actions.onKeyPress} />
				</Grid>
				<Hidden smDown>
					<Grid item xs={2} style={{ maxWidth: 60 }} />
				</Hidden>
				<Grid item xs={12} md={5} className={classes.input_container}>
					<InputFormGray data={form.zipcode} onChange={actions.onChangeForm} onKeyPress={actions.onKeyPress} overrideStyle={{ fontFamily: 'Open Sans' }} />
				</Grid>
			</Grid>
			<ControlButtons content={content} step={1} onPrevStep={onPrevStep} onNextStep={onNextStep} isValid={isFormValid(form).length === 0} form={form} />
		</>
	);
};

const useStyles = makeStyles((theme) => ({
	input_container: {
		maxWidth: 311,
		padding: '14px 0px',
		[theme.breakpoints.down('sm')]: {
			padding: '14px 10px',
		},
	},
	country_code: {
		maxWidth: 100,
		paddingRight: 15,
	},
	cell_phone: {
		maxWidth: 210,
		[theme.breakpoints.down('sm')]: {
			maxWidth: 190,
		},
	},
	title: {
		fontSize: 20,
		fontWeight: 600,
		marginBottom: 30,
	},
}));

export default ContactInfo;
