import React from 'react';
import { Drawer, alpha, Grid, Icon, makeStyles, Typography, useMediaQuery, useTheme, Button } from '@material-ui/core';
import { connect } from 'react-redux';
import { actionTypes } from '../../../store/actions';
import cx from 'classnames';
import { grey } from '@material-ui/core/colors';
import { withRouter } from 'react-router';
import system_translations from '../../../texts/system_translations';
import LanguageMenu from './LanguageMenu';
import GeneralButton from '../../../components/Actions/GeneralButton';

const Sidebar = ({ drawer_status, history, ismobile, language, onUpdateDrawerStatus, onUpdateLanguage, onUpdateContactModal }) => {
	const classes = useStyles();
	const content = system_translations[language].general.topbar;

	const isURLSelected = (path) => {
		return history.location.pathname.split('/')[1] === path.split('/')[1];
	};

	const onChangeView = (url) => {
		history.push(url);
		onUpdateDrawerStatus(false);
	};

	const onChangeLanguage = async (lang) => {
		await localStorage.setItem('lang', lang);
		onUpdateLanguage(lang);
		onUpdateDrawerStatus(false);
		//window.location.reload()
	};

	return (
		<div>
			<Drawer open={drawer_status} variant={'temporary'} anchor='left' classes={{ paper: classes.drawer }} onClose={() => onUpdateDrawerStatus(false)}>
				<div className={classes.container}>
					<div className={classes.logo_container} onClick={() => onChangeView('/')}>
						<img src={content.logo} alt='' className={classes.logo} />
					</div>

					<div className={classes.menu}>
						<Grid container>
							{content.titles.map((item, key) => {
								const isactive = isURLSelected(item.url);
								const isContact = item.url === '/contact';

								return (
									<Grid item xs={12} key={key.toString()}>
										<div className={classes.topbar_item} onClick={isContact ? () => onUpdateContactModal(true) : () => onChangeView(item.url)}>
											<Typography variant='caption' className={cx({ [classes.topbar_item_text]: true, [classes.topbar_item_text_active]: isactive })}>
												{item.title}
											</Typography>
										</div>
									</Grid>
								);
							})}
						</Grid>
					</div>
					<div className={classes.signin_container}>
						<Typography variant='button' align='center' noWrap className={classes.signIn_topbar} onClick={() => onChangeView('/signin')}>
							{content.sign_in}
						</Typography>
					</div>
					<div className={classes.button_container}>
						<GeneralButton style={{ width: 154, height: 54, margin: '0px 15px' }} onClick={() => onChangeView('/signup')}>
							{content.sign_up}
						</GeneralButton>
					</div>
				</div>
			</Drawer>
		</div>
	);
};

const useStyles = makeStyles((theme) => ({
	drawer: {
		background: theme.palette.primary.main,
		width: 220,
		//borderTopRightRadius:24,
		//borderBottomRightRadius:24,
		//border:0,
		//boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .12)',
	},
	logo: {
		width: 100,
	},
	logo_container: {
		display: 'flex',
		justifyContent: 'center',
	},
	container: {
		//width:150,
		padding: '32px 0px',
	},
	title: {
		color: 'white',
		marginTop: 8,
	},
	menu: {
		margin: '32px 0px',
	},
	button_container: {
		display: 'flex',
		justifyContent: 'center',
	},
	signin_container: {
		margin: '30px 0px',
		display: 'flex',
		justifyContent: 'center',
	},
	topbar_item: {
		padding: '8px 12px',
		borderLeft: '1px solid white',
		color: 'white',
		textTransform: 'uppercase',
		cursor: 'pointer',
		//marginTop:8,
		marginLeft: 32,
	},
	topbar_item_text: {
		fontSize: '1rem',
		fontWeight: 500,
	},
	topbar_item_text_active: {
		fontWeight: 800,
	},
	signIn_topbar: {
		textAlign: 'center',
		fontSize: '0.850rem',
		color: 'white',
		margin: '0px 20px',
		cursor: 'pointer',
	},
}));

const mapStateToProps = (state) => {
	return {
		drawer_status: state.drawer_status,
		language: state.language,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onUpdateDrawerStatus: (drawer_status) => dispatch({ type: actionTypes.SYS_UPDATE_DRAWER_STATUS, drawer_status }),
		onUpdateContactModal: (contact_modal) => dispatch({ type: actionTypes.MODAL_UPDATE_CONTACT, contact_modal }),
	};
};
 
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Sidebar));


const SidebarItem = ({active, name, path, icon, onChangeView}) => {

    const classes = useStylesItem()

    return(
        <div className={cx(classes.root,
        {[classes.active]:active
        })} onClick={() => onChangeView(path)}>
            <Grid container justifyContent='center'>
                <Grid item>
                    <Icon>{icon}</Icon>
                </Grid>
                <Grid item xs={12}>
                    <Typography align='center' variant='body2'>{name}</Typography>
                </Grid>
            </Grid>
        </div>
    )
}

const useStylesItem = makeStyles(theme => ({
    root:{
        color:grey[300],
        padding:'16px 8px',
        '&:hover':{
            background:alpha('#FFF',0.2),
            cursor:'pointer',
            transition: theme.transitions.create(['background'], {
                easing: theme.transitions.easing.easeIn,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        transition: theme.transitions.create(['background'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.leavingScreen,
        }),
        
    },
    active:{
        background:theme.palette.secondary.main,
        '&:hover':{
            background:theme.palette.secondary.main,
            cursor:'pointer'
        },
        transition: theme.transitions.create(['background'], {
            easing: theme.transitions.easing.easeIn,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }
}))