import icon_fb from '../../assets/Icons/fb_white_logo.png';
import icon_ig from '../../assets/Icons/ig_white_logo.png';
import icon_tw from '../../assets/Icons/tw_white_logo.png';
import icon_lock from '../../assets/Icons/bxs_lock-alt.png';
import logo_white from '../../assets/Icons/vanguard logo_white 1.png';

const translations = {
	logo_white: logo_white,
	contact: {
		title: 'CONTACT US',
		wapp: 'WhatsApp',
		wapp_number: '(55) 5796 - 1248',
		tel: 'Phone',
		tel_number: '(55) 5233 - 1578',
		email: 'Email',
		email_account: 'contacto@vanguard.mx',
	},
	visit: {
		title: 'VISIT US',
		content: 'Sit nisl vel et, eros. Eget rhoncus, id penatibus tortor nascetur aliquam dui integer. Sit nisl vel et, eros. ',
	},
	icons: {
		facebook: icon_fb,
		instagram: icon_ig,
		twitter: icon_tw,
	},
	ssl: 'SECURED SSL',
	lock_icon: icon_lock,
	privacy_notice: 'Privacy notice',
	copyright: '© 2022 Vanguard Institute. All rights reserved.',
};

export default translations;
