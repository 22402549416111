import { makeStyles, Grid, Typography, Zoom, Fade, Grow } from '@material-ui/core';
import { useEffect, useState } from 'react';
import InViewMonitor from 'react-inview-monitor';

const SectionF = ({ content }) => {
	const classes = useStyles();
	const [onView, setOnView] = useState(false);

	const items = content.items;

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<>
			<div className={classes.root}>
				<div className={classes.header_container}>
					<Typography variant='h4' align='center' className={classes.header_title}>
						{content.header_title}
					</Typography>
					<Typography variant='h6' align='center' color='textSecondary' className={classes.header_text}>
						{content.header_text}
					</Typography>
				</div>
				<InViewMonitor
					onInView={() => {
						setOnView(true);
					}}
				>
					<Grow in={onView} timeout={1000}>
						<div className={classes.body_container}>
							<Grid container alignItems='center' className={classes.content_container}>
								{items.map((item, i) => (
									<Item key={i} icon={item.icon} title={item.title} text={item.text} />
								))}
							</Grid>
						</div>
					</Grow>
				</InViewMonitor>
			</div>
		</>
	);
};

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'relative',
		margin: '50px 0px',
		background: theme.palette.layout.background,
	},
	body_container: {
		[theme.breakpoints.down('md')]: {
			padding: '0px 0px',
		},
	},
	header_container: {
		padding: '0px 100px 10px',
		[theme.breakpoints.down('md')]: {
			padding: '0px 15px 30px',
		},
	},
	header_title: {
		[theme.breakpoints.down('sm')]: {
			fontSize: 28,
		},
	},
	header_text: {
		[theme.breakpoints.down('sm')]: {
			fontSize: 16,
			marginTop: 10,
		},
	},
	content_container: {
		width: 1200,
		marginLeft: 'auto',
		marginRight: 'auto',
		[theme.breakpoints.down('md')]: {
			width: '100%',
		},
	},
}));

const Item = ({ icon, title, text }) => {
	const classes = useItemStyles();
	const [clicked, setClicked] = useState(false);

	const handleClick = () => {
		setClicked(!clicked);
	};

	return (
		<>
			<Grid xs={12} sm={6} md={3} item container justifyContent='center' alignContent='center' className={classes.item_container}>
				<div className={!clicked ? classes.item_unclicked : classes.item_clicked} onClick={handleClick}>
					<div className={classes.item_icon_container}>
						<img src={icon} className={classes.item_icon} />
					</div>
					<Typography variant='subtitle2' className={!clicked ? classes.item_unclicked_title : classes.item_clicked_title}>
						{title}
					</Typography>
					{clicked && (
						<>
							<hr className={classes.item_divider} />
							<Typography variant='body1' className={classes.item_text}>
								{text}
							</Typography>
						</>
					)}
				</div>
			</Grid>
		</>
	);
};

const useItemStyles = makeStyles((theme) => ({
	item_container: {
		height: 546,
		[theme.breakpoints.down('sm')]: {
			height: 'auto',
		},
	},
	item_unclicked: {
		height: 378,
		background: '#2B4578',
		width: 279,
		textAlign: 'center',
		padding: '115px 0px',
		boxSizing: 'border-box',
		cursor: 'pointer',
		[theme.breakpoints.down('sm')]: {
			height: 300,
			width: '95%',
			marginBottom: 10,
			padding: '70px 0px',
		},
		transition: 'all 200ms linear',
	},
	item_icon_container: {
		width: 83,
		height: 83,
		background: 'rgba(255, 255, 255, 0.26)',
		border: '0.68595px solid #FFFFFF',
		borderRadius: 50,
		margin: '0px auto',
		boxSizing: 'border-box',
		padding: 17,
	},
	item_icon: {
		width: '100%',
		height: '100%',
	},
	item_unclicked_title: {
		fontSize: 18,
		color: 'white',
		marginTop: 25,
		transition: 'all 200ms linear',
	},
	item_clicked: {
		height: 546,
		background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.11), rgba(255, 255, 255, 0.11)), #5A7AAD',
		width: 301,
		border: '1px solid #FFFFFF',
		textAlign: 'center',
		padding: '63px 0px',
		boxSizing: 'border-box',
		cursor: 'pointer',
		boxShadow: '0px 5px 78px rgba(0, 0, 0, 0.17)',
		[theme.breakpoints.down('sm')]: {
			height: 500,
			width: '95%',
			marginBottom: 10,
		},
		transition: 'all 200ms linear',
	},
	item_clicked_title: {
		fontSize: 24,
		color: 'white',
		marginTop: 25,
		fontWeight: 600,
		transition: 'all 200ms linear',
	},
	item_divider: {
		border: '2px solid #E8CA70',
		height: 0,
		width: 38,
		margin: '25px auto 34px',
	},
	item_text: {
		color: 'white',
		margin: '0px 51px',
		fontWeight: 300,
	},
}));

export default SectionF;
