import { makeStyles, Grid, Typography, Icon, Hidden, Slide } from '@material-ui/core';
import InViewMonitor from 'react-inview-monitor';
import { useState } from 'react';

const Program = ({ content, index, handleOpenModal }) => {
	const classes = useStyles();
	const isPar = index % 2 === 0;
	const [onView, setOnView] = useState(false);

	return (
		<InViewMonitor
			onInView={() => {
				setOnView(true);
			}}
		>
			<div className={classes.root}>
				<Grid container className={classes.body_container}>
					<Slide direction='right' in={onView} timeout={800}>
						<Grid item xs={12} md={5} className={isPar ? classes.image_side : classes.content_sideLeft}>
							{isPar ? (
								<Hidden smDown>
									<img src={content.img} className={classes.img} />
								</Hidden>
							) : (
								<ProgramContent content={content} handleOpenModal={handleOpenModal} />
							)}
						</Grid>
					</Slide>
					<Hidden smDown>
						<Grid item xs={12} md={2} style={!isPar ? { background: 'linear-gradient(90deg, #e8ca701a 50%, transparent 50%)' } : {}}>
							{isPar ? <div className={classes.round_adornment_b} /> : <div className={classes.round_adornment_y} />}
							<div className={classes.line_adornment} />
						</Grid>
					</Hidden>
					<Slide direction='left' in={onView} timeout={800}>
						<Grid item xs={12} md={5} className={!isPar ? classes.image_side : classes.content_sideRight}>
							{!isPar ? (
								<Hidden smDown>
									<img src={content.img} className={classes.img} />
								</Hidden>
							) : (
								<ProgramContent content={content} handleOpenModal={handleOpenModal} />
							)}
						</Grid>
					</Slide>
				</Grid>
			</div>
		</InViewMonitor>
	);
};

const ProgramContent = ({ content, handleOpenModal }) => {
	const classes = usePCStyles();
	return (
		<>
			<Typography className={classes.title}>{content.title}</Typography>
			<Typography color='textSecondary' className={classes.desc}>
				{content.desc}
			</Typography>
			{content.program_details_array.map((item, i) => (
				<Grid container alignItems='center' justifyContent='center' key={i} className={classes.link_modal_container} onClick={() => handleOpenModal(item)}>
					<Grid item xs={11}>
						<Typography style={{ fontWeight: 600, fontSize: 20 }}>{item.title}</Typography>
					</Grid>
					<Grid item xs={1}>
						<Icon className={classes.link_modal_icon}>chevron_right</Icon>
					</Grid>
				</Grid>
			))}
			<div className={classes.link}>
				<Typography variant='subtitle1' style={{ display: 'inline-block', textTransform: 'uppercase' }}>
					{content.link_text}
				</Typography>
				<Icon style={{ fontSize: 30, display: 'table-cell', verticalAlign: 'middle' }}>chevron_right</Icon>
			</div>
		</>
	);
};

const useStyles = makeStyles((theme) => ({
	root: {
		padding: '70px 0px',
		// width: 1440,
		// margin: '0px auto',
		[theme.breakpoints.down('sm')]: {
			padding: 0,
		},
	},
	body_container: {
		minHeight: 718,
		position: 'relative',
		[theme.breakpoints.down('sm')]: {
			minHeight: 'unset',
		},
	},
	image_side: {},
	img: {
		width: '100%',
		maxHeight: 800,
		objectFit: 'cover',
	},
	content_sideLeft: {
		padding: '100px 0px 0px 140px',
		boxSizing: 'border-box',
		position: 'relative',
		background: 'rgba(232, 202, 112, 0.1)',
		[theme.breakpoints.down('sm')]: {
			padding: '50px 20px',
		},
	},
	content_sideRight: {
		padding: '100px 140px 0px 0px',
		boxSizing: 'border-box',
		position: 'relative',
		[theme.breakpoints.down('sm')]: {
			padding: '50px 20px',
		},
	},
	line_adornment: {
		margin: '0px auto 0px',
		height: 'calc(100% + 100px)',
		width: 0,
		border: '1px solid #9F9F9F',
	},
	round_adornment_b: {
		width: 40,
		height: 40,
		borderRadius: 25,
		boxSizing: 'border-box',
		backgroundClip: 'content-box',
		padding: 7,
		border: `2px solid ${theme.palette.primary.main}`,
		background: theme.palette.primary.main,
		margin: '110px auto 0px',
	},
	round_adornment_y: {
		width: 40,
		height: 40,
		borderRadius: 25,
		boxSizing: 'border-box',
		backgroundClip: 'content-box',
		padding: 7,
		border: `2px solid ${theme.palette.secondary.main}`,
		background: theme.palette.secondary.main,
		margin: '110px auto 0px',
	},
}));

const usePCStyles = makeStyles((theme) => ({
	title: {
		fontWeight: 600,
		fontSize: 40,
	},
	desc: {
		fontWeight: 400,
		fontSize: 20,
		margin: '15px 0px 50px',
	},
	link: {
		cursor: 'pointer',
		margin: '80px 0px 30px',
		display: 'table',
		color: theme.palette.secondary.main,
		fontSize: 16,
	},
	link_modal_container: {
		height: 80,
		borderBottom: '2px solid #9F9F9F',
		cursor: 'pointer',
		color: theme.palette.text.secondary,
		'&:hover': {
			color: `${theme.palette.secondary.main} !important`,
		},
	},
	link_modal_icon: {
		fontSize: 38,
		color: theme.palette.secondary.main,
		lineHeight: 1.2,
	},
}));

export default Program;
