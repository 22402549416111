import { makeStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { useEffect } from 'react';

import system_translations from '../../texts/system_translations';
import SectionA from './Sections/SectionA';
import SectionB from './Sections/SectionB';
import SectionC from './Sections/SectionC';
import SectionD from './Sections/SectionD';
import SectionE from './Sections/SectionE';
import SectionF from './Sections/SectionF';
import SectionG from './Sections/SectionG';

const HomeView = ({ language }) => {
	const classes = useStyles();
	const content = system_translations[language].views.home;

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<div className={classes.root}>
			<SectionA content={content.sectionA} />
			<SectionB content={content.sectionB} />

			<SectionC content={content.sectionC} />

			<SectionD content={content.sectionD} />
			<SectionE content={content.sectionE} />
			<SectionF content={content.sectionF} />
			<SectionG content={content.sectionG} />
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		language: state.language,
	};
};

export default connect(mapStateToProps)(HomeView);

const useStyles = makeStyles((theme) => ({
	root: {
		background: theme.palette.layout.background,
		color: theme.palette.text.primary,
	},
}));
