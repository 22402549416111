import { makeStyles, Hidden, Grid, Typography, useTheme, useMediaQuery, Button, Slide } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import InViewMonitor from 'react-inview-monitor';

const SectionG = ({ content, history }) => {
	const classes = useStyles();
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const [onView, setOnView] = useState(false);

	const items = content.steps;

	const onChangeView = (url) => {
		history.push(url);
	};

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<>
			<div className={classes.root}>
				<div className={classes.header_container}>
					<Typography variant='h4' align='center'>
						{content.header_title}
					</Typography>
					<Button className={classes.button} onClick={() => onChangeView('/')}>
						{content.header_button}
					</Button>
				</div>
				<InViewMonitor
					onInView={() => {
						setOnView(true);
					}}
				>
					<Slide direction='up' in={onView} timeout={1000}>
						<div>
							{items.map((item, i) => (
								<Item key={i} index={i} title={item.title} text={item.text} last={4} isMobile={isMobile} />
							))}
							<Hidden xsDown>
								<div className={classes.vertical_divider}></div>
							</Hidden>
						</div>
					</Slide>
				</InViewMonitor>
			</div>
		</>
	);
};

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'relative',
		margin: '50px 0px',
		background: 'rgba(232, 202, 112, 0.05)',
	},
	header_container: {
		padding: '113px 0px 113px',
		[theme.breakpoints.down('md')]: {
			padding: '34px 15px 33px',
		},
	},
	button: {
		background: theme.palette.secondary.main,
		color: '#fff',
		height: 76,
		width: 186,
		borderRadius: 0,
		marginTop: 46,
		fontSize: 14,
		display: 'block',
		margin: '0px auto',
		'&:hover': {
			background: theme.palette.secondary.dark,
		},
	},
	vertical_divider: {
		position: 'absolute',
		width: 834,
		height: 0,
		top: 960,
		left: '50%',
		border: '1px solid #B6B6B6',
		transform: 'translate(-50%, -50%) rotate(90deg)',
		zIndex: '1',
		[theme.breakpoints.down('md')]: {
			top: 800,
		},
	},
}));

const Item = ({ index, title, text, last, isMobile }) => {
	const classes = useItemStyles();
	const isEven = index % 2 == 0;

	return (
		<Grid container className={classes.step_container}>
			{!isEven ? (
				<Grid sm={5} xs={9} item container alignItems='center' justifyContent='flex-end' className={classes.step_left}>
					<div className={classes.step_inside_content} style={{ textAlign: 'right' }}>
						<Typography className={index + 1 !== last ? classes.step_title : classes.step_title_yellow}>{title}</Typography>
						<Typography className={classes.step_content}>{text}</Typography>
					</div>
				</Grid>
			) : (
				<Hidden xsDown>
					<Grid sm={5} xs={9} item container alignItems='center' justifyContent='flex-end' className={classes.step_left}></Grid>
				</Hidden>
			)}
			<Grid sm={2} xs={3} item container alignItems='center' justifyContent='center' className={classes.step_middle}>
				<div className={index + 1 !== last ? classes.step_indicator : classes.step_indicator_yellow}>
					<Typography align='center' className={classes.step_indicator_text}>
						{index + 1}
					</Typography>
				</div>
			</Grid>
			{isEven ? (
				<Grid sm={5} xs={9} item container alignItems='center' className={classes.step_right}>
					{isEven && (
						<div className={classes.step_inside_content}>
							<Typography className={index + 1 !== last ? classes.step_title : classes.step_title_yellow}>{title}</Typography>
							<Typography color='textSecondary' className={classes.step_content}>
								{text}
							</Typography>
						</div>
					)}
				</Grid>
			) : (
				<Grid sm={5} xs={9} item container alignItems='center' className={classes.step_right}></Grid>
			)}
		</Grid>
	);
};

const useItemStyles = makeStyles((theme) => ({
	step_container: {
		background: 'rgba(252, 252, 252, 0.53)',
		height: 252,
		marginBottom: 26,
		[theme.breakpoints.down('sm')]: {
			padding: 20,
		},
	},
	step_left: {},
	step_right: {},
	step_title: {
		fontSize: 30,
		fontWeight: 800,
		color: theme.palette.text.blue,
		[theme.breakpoints.down('sm')]: {
			fontSize: 25,
			fontWeight: 700,
		},
	},
	step_title_yellow: {
		fontSize: 30,
		fontWeight: 800,
		color: theme.palette.secondary.main,
		[theme.breakpoints.down('sm')]: {
			fontSize: 25,
			fontWeight: 700,
		},
	},
	step_content: {
		fontSize: 14,
		fontWeight: 300,
		marginTop: 10,
	},
	step_indicator: {
		width: 76,
		height: 76,
		background: theme.palette.text.blue,
		borderRadius: 50,
		boxShadow: '0px 4px 41px rgba(24, 78, 162, 0.49)',
		zIndex: '2',
		[theme.breakpoints.down('sm')]: {
			width: 55,
			height: 55,
		},
	},
	step_indicator_yellow: {
		width: 76,
		height: 76,
		background: theme.palette.secondary.main,
		borderRadius: 50,
		boxShadow: '0px 4px 41px rgba(227, 142, 29, 0.49)',
		zIndex: '2',
		[theme.breakpoints.down('sm')]: {
			width: 55,
			height: 55,
		},
	},
	step_indicator_text: {
		color: 'white',
		fontWeight: 700,
		fontSize: 36,
		lineHeight: 1.8,
		[theme.breakpoints.down('sm')]: {
			fontSize: 26,
		},
	},
	step_inside_content: {
		maxWidth: 469,
		[theme.breakpoints.down('sm')]: {
			padding: 20,
		},
	},
}));

const mapStateToProps = (state) => {
	return {
		language: state.language,
	};
};

export default withRouter(SectionG);
