import logo from '../../assets/logo_vanguard.png';

const general_translations = {
	sidebar: {
		logo: logo,
		title: 'Monitoring System',
		list: [
			{
				name: 'Dashboard',
				path: '/',
				icon: 'dashboard',
			},
			{
				name: 'Devices',
				path: '/devices',
				icon: 'device_hub',
			},
			{
				name: 'Locations',
				path: '/locations',
				icon: 'room',
			},
		],
	},
	topbar: {
		titles: [
			{
				url: '/offering',
				title: 'Oferta',
			},
			{
				url: '/news',
				title: 'Noticias',
			},
			{
				url: '/about',
				title: 'Nosotros',
			},
			{
				url: '/contact',
				title: 'Contacto',
			},
			{
				url: '/globalcampus',
				title: 'Campus Global',
			},
		],
		sign_in: 'Inicia sesión',
		sign_up: 'Regístrate',
		logo: logo,
	},
};

export default general_translations;
