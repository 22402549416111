import { makeStyles, Hidden, Grid, Typography, Fade, Slide } from '@material-ui/core';
import { useEffect, useState } from 'react';
import InViewMonitor from 'react-inview-monitor';

const SectionB = ({ content }) => {
	const classes = useStyles();
	const [onView, setOnView] = useState(false);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<>
			<InViewMonitor
				onInView={() => {
					setOnView(true);
				}}
			>
				<Fade in={onView} timeout={1000}>
					<div className={classes.root}>
						<Hidden smDown>
							<img src={content.img_gray} className={classes.gray_img} />
							<img src={content.img_yellow} className={classes.yellow_img} />
						</Hidden>
						<Grid container className={classes.main_container}>
							<Hidden smUp>
								<Typography style={{ margin: 30 }} variant='h5'>
									{content.title}
								</Typography>
							</Hidden>
							<Slide direction='right' in={onView} timeout={1000}>
								<Grid item sm={6} xs={12} className={classes.left_container}>
									<img className={classes.section_img} src={content.img} />
								</Grid>
							</Slide>
							<Hidden xsDown>
								<Slide direction='left' in={onView} timeout={1000}>
									<Grid item container sm={6} xs={12}>
										<Grid item xs={12} className={classes.right_container}>
											<Typography className={classes.section_title} variant='h3'>
												{content.title}
											</Typography>
										</Grid>
										<Hidden smDown>
											<Grid item xs={12} className={classes.right_container} />
											<Grid item xs={12} className={classes.right_container}>
												<div className={classes.phrase_container}>
													<Typography className={classes.text_phrase}>{content.phrase}</Typography>
												</div>
											</Grid>
										</Hidden>
									</Grid>
								</Slide>
							</Hidden>
						</Grid>
					</div>
				</Fade>
			</InViewMonitor>
		</>
	);
};

export default SectionB;

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'relative',
		background: theme.palette.layout.background,
	},
	section_img: {
		width: '100%',
		height: '100%',
		objectFit: 'cover',
		objectPosition: 'top center',
		borderRadius: 25,
		[theme.breakpoints.down('xs')]: {
			borderRadius: 0,
		},
	},
	left_container: {
		width: 630,
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			height: 600,
		},
	},
	right_container: {
		height: '33%',
		[theme.breakpoints.down('sm')]: {
			padding: 0,
			height: '100%',
		},
	},
	main_container: {
		width: 1200,
		padding: '140px 0px 0px',
		marginLeft: 'auto',
		marginRight: 'auto',
		[theme.breakpoints.down('md')]: {
			padding: '30px 0px 0px',
			width: '100%',
		},
	},
	section_title: {
		margin: 60,
		[theme.breakpoints.down('sm')]: {
			margin: 20,
			fontSize: '2rem',
		},
	},
	phrase_container: {
		margin: '50px 100px',
	},
	text_phrase: {
		fontWeight: 500,
		color: '#CBCBCB',
		fontSize: 30,
	},
	gray_img: {
		position: 'absolute',
		width: '100%',
		height: 360,
		left: 0,
		top: 376,
	},
	yellow_img: {
		position: 'absolute',
		width: '100%',
		height: 360,
		left: 0,
		top: 736,
	},
}));
