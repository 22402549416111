import img_banner from '../../assets/contact/right_banner.jpg';
import country_codes_json from '../countryCodes.json';

import icon_wa from '../../assets/Icons/ph_whatsapp-logo-thin.png';
import icon_mail from '../../assets/Icons/mdi-light_email.png';

const translations = {
	title: 'Contáctanos',
	right_banner: img_banner,
	header_title: '¿En qué podemos apoyarte?',
	tabs: [
		{
			name: 'General',
			id: 'TAB_GENERAL',
		},
		{
			name: 'Admisiones',
			id: 'TAB_ADMITIONS',
		},
		{
			name: 'Becas',
			id: 'TAB_SHOLARSHIP',
		},
		{
			name: 'Programas',
			id: 'TAB_PROGRAMS',
		},
	],
	general: {
		form: {
			name: {
				placeholder: 'Nombre *',
				label: 'Nombre *',
				helperText: 'Campo requerido',
			},
			email: {
				placeholder: 'Correo electrónico *',
				label: 'Correo electrónico *',
				helperText: 'Correo electrónico no válido',
			},
			country_code: {
				label: '',
				helperText: 'Selecciona una opción correcta',
			},
			phone: {
				placeholder: 'Teléfono *',
				label: 'Teléfono *',
				helperText: 'Teléfono no válido',
			},
			message: {
				placeholder: 'Mensaje *',
				label: 'Mensaje *',
				helperText: 'Campo requerido',
			},
		},
		codes: country_codes_json,
		send_button: 'Enviar',
		whatsapp: {
			icon: icon_wa,
			title: 'Whatsapp',
			text: '(+52) 555796 - 1248',
		},
		email: {
			icon: icon_mail,
			title: 'Correo',
			text: 'contacto@vanguard.mx',
		},
		links: {
			facebook: 'facebook.com',
			twitter: 'twitter.com',
			instagram: 'instagram.com',
		},
	},
};

export default translations;
