import { makeStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { useEffect } from 'react';

import useNewsView from './useNewsView';
import system_translations from '../../texts/system_translations';

import ViewHeader from '../components/ViewHeader';
import LatestNews from './Sections/LatestNews';
import NewsHighlights from './Sections/NewsHighlights';

//PROVISIONAL
import imgC1 from '../../assets/news/news_1.jpg';
import imgC2 from '../../assets/news/news_2.jpg';
import imgC3 from '../../assets/news/news_3.jpg';
import imgC4 from '../../assets/news/news_4.jpg';
import imgC5 from '../../assets/news/news_1.jpg';

const latest_news = [
	{
		id: 1,
		img: imgC1,
		cat: 'Lo más nuevo',
		title: 'Lectus sociis lectus vitae ut sed alique',
		link_text: 'Leer nota',
		link_path: '/',
	},
	{
		id: 2,
		img: imgC2,
		title: 'Part 2 carousel Lectus sociis lectus vitae ut sed alique',
		cat: 'Lo más nuevo',
		link_text: 'Leer nota',
		link_path: '/',
	},
	{
		id: 3,
		img: imgC3,
		title: 'Part 3 carousel Lectus sociis lectus vitae ut sed alique',
		cat: 'Lo más nuevo',
		link_text: 'Leer nota',
		link_path: '/',
	},
	{
		id: 4,
		img: imgC4,
		title: 'Part 4 carousel Lectus sociis lectus vitae ut sed alique',
		cat: 'Lo más nuevo',
		link_text: 'Leer nota',
		link_path: '/',
	},
	{
		id: 5,
		img: imgC5,
		title: 'Part 5 carousel Lectus sociis lectus vitae ut sed alique',
		cat: 'Lo más nuevo',
		link_text: 'Leer nota',
		link_path: '/',
	},
];

const news_highlights = {
	year: '2021',
	news_array: [
		{
			img: imgC1,
			date: '15 / Diciembre',
			title: 'Semper facilisis quam ut fermentum elit eros, sit ante.',
			content_preview:
				'Id massa, consectetur quis dictum. Sapien nisl donec volutpat non rhoncus egestas consectetur erat aliquam. Amet interdum sit odio est volutpat id. Eu velit neque eu cras tempor cursus fermentum maecenas nisi. Neque bibendum volutpat imperdiet a accumsan enim scelerisque amet. Suspendisse sit ornare non sed ut tortor, curabitur. Pellentesque eu lacinia sed faucibus aliquet viverra nam lobortis leo. Pellentesque ac eu id pharetra malesuada.',
			link_text: 'Leer nota',
			link_path: '/',
		},
		{
			img: imgC2,
			date: '08 / Diciembre',
			title: 'Semper facilisis quam ut fermentum elit eros, sit ante.',
			content_preview:
				'Id massa, consectetur quis dictum. Sapien nisl donec volutpat non rhoncus egestas consectetur erat aliquam. Amet interdum sit odio est volutpat id. Eu velit neque eu cras tempor cursus fermentum maecenas nisi. Neque bibendum volutpat imperdiet a accumsan enim scelerisque amet. Suspendisse sit ornare non sed ut tortor, curabitur. Pellentesque eu lacinia sed faucibus aliquet viverra nam lobortis leo. Pellentesque ac eu id pharetra malesuada.',
			link_text: 'Leer nota',
			link_path: '/',
		},
		{
			img: imgC3,
			date: '08 / Diciembre',
			title: 'Semper facilisis quam ut fermentum elit eros, sit ante.',
			content_preview:
				'Id massa, consectetur quis dictum. Sapien nisl donec volutpat non rhoncus egestas consectetur erat aliquam. Amet interdum sit odio est volutpat id. Eu velit neque eu cras tempor cursus fermentum maecenas nisi. Neque bibendum volutpat imperdiet a accumsan enim scelerisque amet. Suspendisse sit ornare non sed ut tortor, curabitur. Pellentesque eu lacinia sed faucibus aliquet viverra nam lobortis leo. Pellentesque ac eu id pharetra malesuada.',
			link_text: 'Leer nota',
			link_path: '/',
		},
	],
};

const NewsView = ({ language }) => {
	const classes = useStyles();
	const content = system_translations[language].views.news;
	//const {news_data} = useNewsView({language})

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<div className={classes.root}>
			<ViewHeader title={content.header_title} img={content.header_img} />
			<LatestNews news_array={latest_news} />
			<NewsHighlights news={news_highlights} content={content} />
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		language: state.language,
	};
};

export default connect(mapStateToProps)(NewsView);

const useStyles = makeStyles((theme) => ({
	root: {
		background: theme.palette.layout.background,
	},
}));
