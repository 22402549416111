import React from 'react';
import { TextField, withStyles, InputBase, InputLabel, Typography, FormHelperText, MenuItem, Select, Icon } from '@material-ui/core';
import { ruleValidation } from './customFunctions';
import { makeStyles } from '@material-ui/styles';
import { grey } from '@material-ui/core/colors';
import { ChevronLeftOutlined, ExpandMore } from '@material-ui/icons';

const CssTextField = withStyles((theme) => ({
	root: {
		'&.Mui-error': {
			border: '1px solid red',
		},
	},
	input: {
		color: theme.palette.text.primary,
		background: '#FCFCFC',
		fontWeight: 600,
		padding: '15px 10px',
		border: '1px solid #939393',
		fontSize: 16,
		fontFamily: 'Open Sans',
	},
}))(InputBase);

const useStyles = makeStyles((theme) => ({
	multiline_classes: {
		color: theme.palette.text.secondary,
	},
	inputlabel: {
		fontFamily: 'Raleway',
		marginBottom: 10,
		fontSize: 14,
		fontWeight: 500,
	},
	required_star: {
		color: theme.palette.secondary.main,
		fontSize: 18,
		fontWeight: 700,
	},
	icon: {
		paddingRight: 15,
	},
	menu_item: {
		fontFamily: 'Open Sans',
	},
}));

const InputSelectFormGray = (props) => {
	const { data, onChange } = props;
	const classes = useStyles();

	const onInnerChange = (event) => {
		const response = event.target.value;
		let temp = { ...data };
		temp.value = response;
		temp.isValid = ruleValidation(temp.value, temp.rules, temp.value2);
		temp.isVisited = true;
		onChange(temp);
	};

	const { config, value, isValid, isVisited, isRequired, options, overrideStyle } = data;

	const isError = isVisited && !isValid;

	let interConfig = { ...config };
	let helperText = interConfig.helperText;
	delete interConfig['helperText'];

	const _options = options ? options : [];

	if (!isError) helperText = '';

	return (
		<div>
			<InputLabel className={classes.inputlabel} htmlFor={interConfig.id}>
				{interConfig.label} {isRequired && <span className={classes.required_star}>*</span>}
			</InputLabel>
			<Select IconComponent={ExpandMore} value={value} onChange={onInnerChange} {...interConfig} input={<CssTextField error={isError} />}>
				{_options.map((item, i) => {
					return (
						<MenuItem key={i} value={item.value} className={classes.menu_item}>
							{item.label}
						</MenuItem>
					);
				})}
			</Select>
			<FormHelperText error={isError}>{helperText}</FormHelperText>
		</div>
	);
};

export default InputSelectFormGray;
