import { makeStyles, Typography, Grid, Hidden } from '@material-ui/core';
import { useState } from 'react';
import { connect } from 'react-redux';
import GeneralButton from '../../components/Actions/GeneralButton';

import system_translations from '../../texts/system_translations';
import ViewHeader from '../components/ViewHeader';
import Program from './components/Program';
import ProgramDetails from './modals/ProgramDetails';

import footer_adornment from '../../assets/adornments/offering_footer_addornment.png';

const OfferingView = ({ language }) => {
	const classes = useStyles();
	const content = system_translations[language].views.offering;

	const [openModal, setOpenModal] = useState(false);
	const [contentModal, setContentModal] = useState({});

	const handleOpenModal = (content) => {
		setOpenModal(true);
		setContentModal(content);
	};

	const handleCloseModal = () => {
		setOpenModal(false);
	};

	return (
		<div className={classes.root}>
			<ViewHeader title={content.header_title} img={content.header_img} />
			<ProgramDetails open={openModal} content={contentModal} handleCloseModal={handleCloseModal} />
			<div className={classes.top_container}>
				<Typography align='center' variant='h4' className={classes.top_text}>
					{content.top_text_1}
					<Typography className={classes.top_number} color='secondary'>
						{content.top_number_1}
					</Typography>
					{content.top_text_2}
					<Typography className={classes.top_number} color='secondary'>
						{content.top_number_2}
					</Typography>
					{content.top_text_3}
				</Typography>
			</div>
			<div className={classes.body_container}>
				{content.programs_array.map((item, i) => (
					<Program content={item} key={i} index={i} handleOpenModal={handleOpenModal} />
				))}
			</div>
			<Grid container justifyContent='center' alignItems='center' className={classes.footer_container}>
				<Grid item xs={12}>
					<Typography align='center' variant='h4'>
						{content.footer_text}
					</Typography>
				</Grid>
				<GeneralButton style={{ width: 186, height: 76, marginTop: 40 }}>{content.footer_button}</GeneralButton>
				<Hidden smDown>
					<img src={footer_adornment} className={classes.footer_adornment_l} />
					<img src={footer_adornment} className={classes.footer_adornment_r} />
				</Hidden>
			</Grid>
		</div>
	);
};

const useStyles = makeStyles((theme) => ({
	root: {},
	top_container: {
		height: 350,
		background: '#F4F5F9',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		padding: '0px 20px',
	},
	top_text: {
		width: 770,
		textShadow: '0px 4px 9px rgba(0, 0, 0, 0.25)',
		fontSize: '2.5rem',
		[theme.breakpoints.down('sm')]: {
			fontSize: '2rem',
		},
	},
	top_number: {
		display: 'inline',
		fontFamily: 'Open Sans',
		fontSize: '3rem',
		fontWeight: 600,
		[theme.breakpoints.down('sm')]: {
			fontSize: '2.2rem',
		},
	},
	footer_container: {
		position: 'relative',
		height: 335,
		background: '#084EB6',
		color: 'white',
		padding: '60px 0px',
		mixBlendMode: 'multitply',
	},
	footer_adornment_l: {
		position: 'absolute',
		top: 0,
		left: 0,
	},
	footer_adornment_r: {
		position: 'absolute',
		top: 0,
		right: 0,
		transform: 'scaleX(-1)',
	},
}));

const mapStateToProps = (state) => {
	return {
		language: state.language,
	};
};

export default connect(mapStateToProps)(OfferingView);
