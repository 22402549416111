import { makeStyles, Grid, Typography, Button, Switch, withStyles, Hidden } from '@material-ui/core';
import system_translations from '../../texts/system_translations';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { useEffect } from 'react';

import { actionTypes } from '../../store/actions';
import useSignInLayout from './useSignInLayout';
import InputFormSignIn from '../../components/Forms/InputFormSingin';

const SignInLayout = ({ user, history, language, onUpdateAuthStatus, onUpdateUserData, onUpdateModalStatus, modals }) => {
	const classes = useStyles();
	const content = system_translations[language].layouts.signin;
	const { system, form, actions } = useSignInLayout({ language, content, onUpdateAuthStatus, onUpdateUserData, onUpdateModalStatus });
	const onChangeView = (url) => {
		history.push(url);
	};
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	let contentRedirect = null;
	if (user) contentRedirect = <Redirect to='/' />;

	return (
		<div className={classes.root}>
			{contentRedirect}
			<div className={classes.container}>
				<Grid container>
					<Grid item xs={12} md={4}>
						<div className={classes.logoContainer}>
							<img
								src={content.logo}
								alt='logo vanguard'
								className={classes.imgLogo}
								onClick={() => {
									onChangeView('/');
								}}
							/>
						</div>
						<div className={classes.leftSide}>
							<Typography variant='h5'>{content.title}</Typography>
							<div>
								<Grid container className={classes.formContainer}>
									<Grid item xs={12} className={classes.inputContainer}>
										<InputFormSignIn data={form.email} onChange={actions.onChangeForm} onKeyPress={actions.onKeyPress} />
									</Grid>
									<Grid item xs={12} className={classes.inputContainer}>
										<InputFormSignIn data={form.password} onChange={actions.onChangeForm} onKeyPress={actions.onKeyPress} />
									</Grid>
								</Grid>
								<Grid container justifyContent='flex-end'>
									<Grid item>
										<div
											// onClick={}
											className={classes.link}
										>
											{content.forgotten_password}
										</div>
									</Grid>
								</Grid>
								<div className={classes.buttonContainer}>
									<Button className={classes.buttonSignIn}>{content.button}</Button>
								</div>
								<Grid container spacing={1}>
									<Grid item>
										<AntSwitch
										//checked={state.checkedC}
										//onChange={handleChange}
										/>
									</Grid>
									<Grid item>
										<Typography style={{ fontWeight: 600, fontSize: 14 }} color='textSecondary'>
											{content.stay_signed}
										</Typography>
									</Grid>
								</Grid>
								<Grid container spacing={1} className={classes.signUpContainer}>
									<Grid item>
										<Typography style={{ fontWeight: 500, fontSize: 14, marginBottom: 20 }} color='textPrimary'>
											{content.dont_have_account}
										</Typography>
									</Grid>
									<Grid item>
										<div className={classes.link} onClick={() => onChangeView('/signup')}>
											{content.sign_up}
										</div>
									</Grid>
								</Grid>
							</div>
						</div>
					</Grid>
					<Grid item xs={12} md={8}>
						<Hidden smDown>
							<div className={classes.rightSide}>
								<img src={content.banner} className={classes.image} />
								<div className={classes.blue_overlay} />
							</div>
						</Hidden>
					</Grid>
				</Grid>
			</div>
		</div>
	);
};

const AntSwitch = withStyles((theme) => ({
	root: {
		width: 38,
		height: 21,
		padding: 0,
		display: 'flex',
	},
	switchBase: {
		padding: 4,
		color: theme.palette.common.white,
		'&$checked': {
			transform: 'translateX(16px)',
			color: theme.palette.common.white,
			'& + $track': {
				opacity: 1,
				backgroundColor: theme.palette.secondary.main,
			},
		},
	},
	thumb: {
		width: 13,
		height: 13,
		boxShadow: 'none',
	},
	track: {
		borderRadius: 18,
		opacity: 1,
		background: theme.palette.grey[400],
	},
	checked: {},
}))(Switch);

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'relative',
	},
	container: {
		margin: 'auto',
		width: '100%',
		boxSizing: 'border-box',
	},

	image: {
		width: '100%',
		minHeight: '100%',
		height: '100vh',
		objectFit: 'cover',
		display: 'block',
		zIndex: '2',
	},
	blue_overlay: {
		position: 'absolute',
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
		background: 'linear-gradient(0deg, rgba(8, 78, 182, 0.33), rgba(8, 78, 182, 0.33))',
		zIndex: '3',
	},

	rightSide: {
		position: 'relative',
		width: '100%',
		height: '100%',
	},
	leftSide: {
		position: 'relative',
		padding: '100px 70px 0px',
		margin: 'auto',
		[theme.breakpoints.down('md')]: {
			padding: '50px 40px 0px',
		},
	},
	logoContainer: {
		height: 190,
		background: theme.palette.primary.main,
	},
	imgLogo: {
		width: 120,
		margin: '40px 70px 50px',
		cursor: 'pointer',
		[theme.breakpoints.down('sm')]: {
			margin: '40px 40px 50px',
		},
	},
	formContainer: {
		padding: '10px 0px',
	},
	labelInput: {
		fontSize: 14,
		color: theme.palette.text.secondary,
	},
	inputForm: {
		background: '#EEEFF3',
		margin: '10px 0px',
		width: '100%',
		height: 48,
		padding: 15,
	},
	inputContainer: {
		margin: '25px 0px 0px',
	},
	link: {
		color: theme.palette.secondary.main,
		fontWeight: 600,
		textDecoration: `underline ${theme.palette.secondary.main}`,
		cursor: 'pointer',
		fontSize: 14,
	},
	buttonSignIn: {
		background: theme.palette.secondary.main,
		color: '#fff',
		height: 62,
		width: '100%',
		borderRadius: 0,
		'&:hover': {
			background: theme.palette.secondary.dark,
		},
	},
	buttonContainer: {
		margin: '28px 0px 50px',
		width: '50%',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	signUpContainer: {
		marginTop: 80,
		[theme.breakpoints.down('md')]: {
			marginTop: 50,
		},
	},
}));

const mapStateToProps = (state) => {
	return {
		user: state.user,
		language: state.language,
		modals: state.modals,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onUpdateUserData: (user) => dispatch({ type: actionTypes.AUTH_UPDATE_USER, user }),
		onUpdateAuthStatus: (is_auth) => dispatch({ type: actionTypes.AUTH_UPDATE_STATUS, is_auth }),
		onUpdateToken: (token) => dispatch({ type: actionTypes.SYS_UPDATE_TOKEN, token }),
		onUpdateSessionStatus: (session_status) => dispatch({ type: actionTypes.SYS_UPDATE_SESSION_STATUS, session_status }),
		onUpdateModalStatus: (modal) => dispatch({ type: actionTypes.SYS_UPDATE_MODAL, modal }),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SignInLayout);
