import { makeStyles, Grid, Typography, Checkbox, Hidden } from '@material-ui/core';
import InputFormGray from '../../../components/Forms/InputFormGray';
import useProgramInterest from './useProgramInterest';
import InputSelectFormGray from '../../../components/Forms/InputSelectFormGray';
import { isFormValid } from '../../../shared/utility';
import ControlButtons from '../components/ControlButtons';
import { useState } from 'react';

const ProgramInterest = ({ content, language, saved_form, onPrevStep, onNextStep }) => {
	const classes = useStyles();
	let { system, form, actions } = useProgramInterest({ language, content, saved_form });

	const [checkedTerms, setCheckedTerms] = useState(false);

	return (
		<>
			<Typography align='center' className={classes.title}>
				{content.title}
			</Typography>
			<Grid container justifyContent='center'>
				<Grid item xs={12} md={5} className={classes.input_container}>
					<InputSelectFormGray data={form.program} onChange={actions.onChangeForm} />
				</Grid>
				<Hidden smDown>
					<Grid item xs={2} style={{ maxWidth: 60 }} />
				</Hidden>

				<Grid item xs={12} md={5} className={classes.input_container}>
					<InputSelectFormGray data={form.area} onChange={actions.onChangeForm} />
				</Grid>
				<Grid item xs={12} className={classes.input_container_textarea}>
					<InputFormGray data={form.comment} onChange={actions.onChangeForm} onKeyPress={actions.onKeyPress} />
				</Grid>
				<Grid item xs={12} style={{ padding: '10px 50px' }}>
					<div style={{ maxWidth: 600, margin: '0px auto' }}>
						<Checkbox
							checked={checkedTerms}
							color='secondary'
							size='small'
							onClick={() => {
								setCheckedTerms(!checkedTerms);
							}}
						/>
						<Typography align='center' className={classes.terms_conditions}>
							{content.terms_conditions1}
						</Typography>
						<Typography align='center' className={classes.terms_conditions_sec}>
							"{content.btn_finish}"
						</Typography>
						<Typography align='center' className={classes.terms_conditions}>
							{content.terms_conditions2}
						</Typography>
						<Typography align='center' className={classes.terms_conditions_link}>
							{content.terms_conditions_link}
						</Typography>
						<Typography align='center' className={classes.terms_conditions}>
							{content.terms_conditions3}
						</Typography>
					</div>
				</Grid>
			</Grid>
			<ControlButtons content={content} step={2} onPrevStep={onPrevStep} onNextStep={onNextStep} isValid={isFormValid(form).length === 0 && checkedTerms} form={form} />
		</>
	);
};

const useStyles = makeStyles((theme) => ({
	input_container: {
		maxWidth: 311,
		padding: '14px 0px',
		[theme.breakpoints.down('sm')]: {
			padding: '14px 10px',
		},
	},
	input_container_textarea: {
		maxWidth: 682,
		padding: '14px 0px',
		width: '100%',
		[theme.breakpoints.down('sm')]: {
			maxWidth: 605,
		},
		[theme.breakpoints.down('xs')]: {
			maxWidth: 290,
		},
	},
	title: {
		fontSize: 20,
		fontWeight: 600,
		marginBottom: 30,
	},
	terms_conditions: {
		color: '#363636',
		fontWeight: 600,
		fontSize: 12,
		display: 'inline',
	},
	terms_conditions_sec: {
		color: theme.palette.secondary.main,
		fontWeight: 700,
		fontSize: 12,
		display: 'inline',
		textTransform: 'uppercase',
	},
	terms_conditions_link: {
		color: '#363636',
		fontWeight: 700,
		fontSize: 12,
		display: 'inline',
		cursor: 'pointer',
		textDecoration: 'underline',
	},
}));

export default ProgramInterest;
