import { makeStyles, Hidden, Paper, Button, Typography, Icon, useMediaQuery, useTheme } from '@material-ui/core';
import { connect } from 'react-redux';
import { useEffect } from 'react';
import { withRouter } from 'react-router';
import Carousel from 'react-material-ui-carousel';

const SectionA = ({ history, content }) => {
	const classes = useStyles();
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	const items = content.carousel;

	let marginNav = isMobile ? '0px' : `calc(50% - ${items.length * 40}px)`;

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<div className={classes.root}>
			<Carousel
				className={classes.carousel}
				stopAutoPlayOnHover={false}
				interval={6000}
				PrevIcon={<Icon style={{ fontSize: 40 }}>chevron_left</Icon>}
				NextIcon={<Icon style={{ fontSize: 40 }}>chevron_right</Icon>}
				indicatorIconButtonProps={{
					style: {
						padding: '0px 12.5px',
						color: 'rgba(255, 255, 255, 0.44)',
					},
				}}
				activeIndicatorIconButtonProps={{
					style: {
						color: 'white',
					},
				}}
				indicatorContainerProps={{
					style: {},
				}}
				navButtonsProps={{
					style: {
						backgroundColor: 'transparent',
						borderRadius: 0,
					},
				}}
				navButtonsWrapperProps={{
					style: {
						height: 20,
						bottom: 60,
						top: 'unset',
						marginLeft: marginNav,
						marginRight: marginNav,
					},
				}}
				navButtonsAlwaysVisible
			>
				{items.map((item, i) => (
					<Item key={i} item={item} history={history} />
				))}
			</Carousel>
		</div>
	);
};

const Item = ({ item, history }) => {
	const classes = usePaperStyles();
	const onChangeView = (url) => {
		history.push(url);
	};

	return (
		<Paper className={classes.paper}>
			<img src={item.img} className={classes.img_carousel} />
			<div className={classes.carousel_content}>
				<Typography variant='h2' className={classes.carousel_title}>
					{item.title}
				</Typography>
				<Typography variant='h2' className={classes.carousel_desc}>
					{item.content}
				</Typography>
				<div className={classes.carousel_link} onClick={() => onChangeView('/')}>
					<Typography variant='subtitle1' style={{ display: 'inline-block', textTransform: 'uppercase' }}>
						{item.link}
					</Typography>
					<Icon style={{ fontSize: 30, display: 'table-cell', verticalAlign: 'middle' }}>chevron_right</Icon>
				</div>
			</div>
		</Paper>
	);
};

export default withRouter(SectionA);

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'relative',
		background: theme.palette.primary.main,
	},
	carousel: {
		paddingBottom: 50,
	},
}));

const usePaperStyles = makeStyles((theme) => ({
	root: {
		position: 'relative',
		background: theme.palette.primary.main,
	},
	paper: {
		height: 'calc(100vh - 50px)',

		// '&::after': {
		// 	content: '""',
		// 	display: 'block',
		// 	position: 'absolute',
		// 	top: 0,
		// 	left: 0,
		// 	width: '100%',
		// 	height: '100%',
		// 	background: 'linear-gradient(180deg, rgba(8, 37, 79, 0.36) 6.59%, rgba(12, 66, 162, 0.306) 57.22%, rgba(8, 37, 79, 0.36) 100%)',
		// },
	},
	img_carousel: {
		width: '100%',
		height: '100%',
		objectFit: 'cover',
		position: 'absolute',
	},
	carousel_title: {
		textShadow: '4px 4px 10px rgba(153, 153, 153, 0.53)',
		marginBottom: 28,

		[theme.breakpoints.down('sm')]: {
			fontSize: '3rem',
		},
	},
	carousel_content: {
		color: 'white',
		position: 'absolute',
		top: '40%',
		left: 140,
		maxWidth: 565,
		[theme.breakpoints.down('sm')]: {
			top: '30%',
			left: 50,
			maxWidth: 350,
		},
	},
	carousel_desc: {
		fontSize: 20,
		fontWeight: 400,
	},
	carousel_link: {
		cursor: 'pointer',
		marginTop: 50,
		display: 'table',
	},
}));
