import { makeStyles, Button, Icon } from '@material-ui/core';

const ControlButtons = ({ content, step, onPrevStep, onNextStep, isValid, form }) => {
	const classes = useStyles();

	return (
		<div className={classes.buttons_container}>
			<Button
				disabled={step === 0}
				className={classes.btn_prev}
				onClick={() => {
					onPrevStep(form);
				}}
			>
				<Icon style={{ fontSize: 40 }}>chevron_left</Icon>
			</Button>
			{step !== 2 ? (
				<Button
					disabled={!isValid}
					className={classes.btn_next}
					onClick={() => {
						onNextStep(form, isValid);
					}}
				>
					<Icon style={{ fontSize: 40 }}>chevron_right</Icon>
				</Button>
			) : (
				<Button disabled={!isValid} className={classes.btn_finish}>
					{content.btn_finish}
				</Button>
			)}
		</div>
	);
};

const useStyles = makeStyles((theme) => ({
	btn_prev: {
		background: '#F3F4F6',
		width: 55,
		height: 55,
		color: theme.palette.secondary.main,
		borderRadius: 0,
		border: '1px solid #CECECE',
		marginRight: 15,
		'&.Mui-disabled': {
			background: '#EBEBEB',
			color: 'white',
			border: '1px solid #EBEBEB',
		},
	},
	btn_next: {
		background: theme.palette.secondary.main,
		width: 55,
		height: 55,
		color: 'white',
		borderRadius: 0,
		marginLeft: 15,
		'&.Mui-disabled': {
			opacity: 0.5,
		},
		'&:hover': {
			background: theme.palette.secondary.light,
		},
	},
	btn_finish: {
		width: 178,
		height: 55,
		background: theme.palette.secondary.main,
		fontSize: 13,
		fontWeight: 600,
		color: 'white',
		borderRadius: 0,
		'&.Mui-disabled': {
			opacity: 0.5,
		},
		'&:hover': {
			background: theme.palette.secondary.light,
		},
		[theme.breakpoints.down('sm')]: {
			width: 150,
		},
		[theme.breakpoints.down('xs')]: {
			width: 100,
		},
	},
	buttons_container: {
		position: 'absolute',
		bottom: 55,
		left: '50%',
		transform: 'translateX(-50%)',
		[theme.breakpoints.down('sm')]: {
			bottom: 30,
		},
	},
}));

export default ControlButtons;
