import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import React, {useEffect} from 'react';
import { connect } from 'react-redux';
import WebsiteRouter from '../../routes/website-router';
import { actionTypes } from '../../store/actions';
import Topbar from './components/Topbar';
import ErrorModal from '../../components/Modals/ErrorModal';
import Sidebar from './components/Sidebar';
import Footer from './components/Footer';
import { withRouter } from 'react-router';
import Contact from '../../modals/Contact/Contact';

// ReactGA.initialize(CONFIG_SETTINGS.GA_TRACKING_CODE) // Uncomment for google analytics

const WebsiteLayout = ({ history, error, language, onUpdateError, onUpdateLanguage }) => {
	const classes = useStyles();
	const theme = useTheme();
	const ismobile = useMediaQuery(theme.breakpoints.down('sm'));

	/*
    // Uncomment to enable google analytics in each page using react router
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search)
    }, [history.location.pathname])
    */

	return (
		<div className={classes.root}>
			<ErrorModal open={Boolean(error)} message={error ? error.message : null} onClose={() => onUpdateError(null)} />
			<main className={classes.main}>
				<Topbar ismobile={ismobile} />
				<Sidebar onUpdateLanguage={onUpdateLanguage} />
				<div className={classes.subroot}>
					<WebsiteRouter />
				</div>
				<Contact />
				<Footer />
			</main>
		</div>
	);
};

const useStyles = makeStyles((theme) => ({
	root: {
		minHeight: '100vh',
		overflowY: 'auto',
		filter: 'none',
	},
	root_blur: {
		filter: 'blur(3px)',
	},
	main: {
		overflowY: 'auto',
		background: theme.palette.layout.background,
	},
	subroot: {
		position: 'relative',
		flexGrow: 1,

		//padding: theme.spacing(3),
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		[theme.breakpoints.down('sm')]: {
			marginLeft: 0,
		},
	},
}));

const mapStateToProps = state => {
    return {
        language: state.language,
        user: state.user,
        error: state.error
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateError: (error) => dispatch({type:actionTypes.SYS_UPDATE_ERROR, error}),
        onUpdateLanguage: (language) => dispatch({type:actionTypes.SYS_UPDATE_LANGUAGE, language})
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WebsiteLayout));

