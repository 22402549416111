import { useState } from 'react';
import { makeStyles, Grid, Typography, Icon, IconButton } from '@material-ui/core';
import { withRouter } from 'react-router';
import InputForm from '../../../components/Forms/InputForm';
import useContactGeneral from './useContactGeneral';
import InputSelectForm from '../../../components/Forms/InputSelectForm';

import GeneralButton from '../../../components/Actions/GeneralButton';
import { Facebook, Instagram, Twitter } from '@material-ui/icons';

const General = ({ content, language, history }) => {
	const classes = useStyles();
	const { system, form, actions } = useContactGeneral({ language, content });

	const onChangeView = (url) => {
		history.push(url);
	};

	return (
		<>
			<Grid container className={classes.form} spacing={2}>
				<Grid item xs={12}>
					<InputForm data={form.name} onChange={actions.onChangeForm} onKeyPress={actions.onKeyPress} />
				</Grid>
				<Grid item xs={12}>
					<InputForm data={form.email} onChange={actions.onChangeForm} onKeyPress={actions.onKeyPress} />
				</Grid>
				<Grid item container spacing={2} xs={12} style={{ marginTop: 60 }}>
					<Grid item xs={3} style={{ width: 84 }}>
						<InputSelectForm data={form.country_code} onChange={actions.onChangeForm} />
					</Grid>
					<Grid item xs={9}>
						<InputForm data={form.phone} onChange={actions.onChangeForm} onKeyPress={actions.onKeyPress} />
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<InputForm data={form.message} onChange={actions.onChangeForm} onKeyPress={actions.onKeyPress} />
				</Grid>
				<Grid item xs={12} md={4}>
					<GeneralButton style={{ height: 81, fontSize: 18, marginTop: 75 }} fullWidth={true}>
						{content.send_button}
					</GeneralButton>
				</Grid>
			</Grid>
			<Grid container className={classes.info}>
				<Grid xs={12} item className={classes.map}>
					mapa
				</Grid>
				<Grid item container xs={10}>
					<Grid item xs={12} container style={{ padding: '20px 0px' }}>
						<Grid item xs={2}>
							<img src={content.whatsapp.icon} className={classes.left_icon} />
						</Grid>
						<Grid item xs={10}>
							<Typography variant='h5' style={{ fontSize: 18 }}>
								{content.whatsapp.title}
							</Typography>
							<Typography color='textSecondary' variant='h6' style={{ fontSize: 16 }}>
								{content.whatsapp.text}
							</Typography>
						</Grid>
					</Grid>
					<Grid item xs={12} container style={{ padding: '20px 0px' }}>
						<Grid item xs={2}>
							<img src={content.email.icon} className={classes.left_icon} />
						</Grid>
						<Grid item xs={10}>
							<Typography variant='h5' style={{ fontSize: 20 }}>
								{content.email.title}
							</Typography>
							<Typography color='textSecondary' variant='h6' style={{ fontSize: 16 }}>
								{content.email.text}
							</Typography>
						</Grid>
					</Grid>
				</Grid>
				<Grid item container alignItems='center' xs={2} style={{ borderLeft: '1px solid #DADADA' }}>
					<Grid item xs={12}>
						<IconButton onClick={() => onChangeView(content.links.facebook)} className={classes.icons_right}>
							<Facebook />
						</IconButton>
					</Grid>
					<Grid item xs={12}>
						<IconButton onClick={() => onChangeView(content.links.twitter)} className={classes.icons_right}>
							<Twitter />
						</IconButton>
					</Grid>
					<Grid item xs={12}>
						<IconButton onClick={() => onChangeView(content.links.instagram)} className={classes.icons_right}>
							<Instagram />
						</IconButton>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
};

const useStyles = makeStyles((theme) => ({
	form: {
		width: 500,
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	info: {
		width: '100%',
	},
	map: {
		height: 522,
		background: 'lightgray',
		margin: '50px 0px',
	},
	left_icon: {
		width: 50,
		height: 50,
	},
	icons_right: {
		width: 40,
		height: 40,
		background: theme.palette.primary.main,
		color: 'white',
		marginLeft: 40,
		'&:hover': {
			background: theme.palette.primary.light,
		},
		[theme.breakpoints.down('sm')]: {
			marginLeft: 20,
		},
	},
}));

export default withRouter(General);
