import React from 'react';
import { Button, ButtonGroup, InputLabel } from '@material-ui/core';
import { ruleValidation } from './customFunctions';
import { makeStyles } from '@material-ui/styles';

const CustomButton = ({ text, value, onClick, selectedValue, index }) => {
	const classes = useStyles();
	const isSelected = value === selectedValue;

	return (
		<Button
			onClick={() => {
				onClick(value);
			}}
			className={isSelected ? classes.button_selected : classes.button}
			style={index < 1 ? { marginRight: 11 } : {}}
		>
			{text}
		</Button>
	);
};

const ButtonGroupForm = (props) => {
	const { data, onChange } = props;
	const classes = useStyles();

	const onInnerChange = (value) => {
		let temp = { ...data };
		temp.value = value;
		temp.isValid = ruleValidation(temp.value, temp.rules, temp.value2);
		temp.isVisited = true;
		onChange(temp);
	};

	const { config, value, isValid, isVisited, isRequired, options } = data;

	const isError = isVisited && !isValid;
	const _options = options ? options : [];

	let interConfig = { ...config };
	let helperText = interConfig.helperText;
	let label = interConfig.label;
	delete interConfig['helperText'];
	delete interConfig['label'];

	if (!isError) helperText = '';

	return (
		<>
			<InputLabel className={classes.inputlabel} htmlFor={interConfig.id}>
				{label} {isRequired && <span className={classes.required_star}>*</span>}
			</InputLabel>
			<ButtonGroup style={{ width: '100%' }} {...interConfig}>
				{_options.map((item, i) => (
					<CustomButton key={i} text={item.text} value={item.value} selectedValue={value} onClick={onInnerChange} index={i} />
				))}
			</ButtonGroup>
		</>
	);
};

const useStyles = makeStyles((theme) => ({
	multiline_classes: {
		color: theme.palette.text.secondary,
	},
	inputlabel: {
		marginBottom: 10,
		fontSize: 14,
		fontWeight: 500,
	},
	required_star: {
		color: theme.palette.secondary.main,
		fontSize: 18,
		fontWeight: 700,
	},
	button: {
		width: '50%',
		height: 48,
		color: '#757575',
		border: '1px solid #939393',
		background: 'rgba(238, 239, 243, 0.65)',
		borderRadius: 0,
		'&:hover': {
			borderColor: '#1D3150',
			color: '#1D3150',
		},
	},
	button_selected: {
		width: '50%',
		height: 48,
		color: 'white',
		border: '1px solid #1D3150',
		background: '#1D3150',
		borderRadius: 0,
		'&:hover': {
			color: 'white',
			border: '1px solid #1D3150',
			background: '#1D3150',
		},
	},
}));

export default ButtonGroupForm;
