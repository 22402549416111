import General from '../views/General';

const TabSwitch = ({ active_tab, content, language }) => {
	switch (active_tab) {
		case 'TAB_GENERAL':
			return <General language={language} content={content.general} />;
			break;
		case 'TAB_ADMITIONS':
			return <h1>ADMISIONES</h1>;
			break;
		case 'TAB_SHOLARSHIP':
			return <h1>BECAS</h1>;
			break;
		case 'TAB_PROGRAMS':
			return <h1>PROGRAMAS</h1>;
			break;
		default:
			break;
	}
};

export default TabSwitch;
