import { makeStyles, Grid, Hidden, Typography, Button, Icon, useTheme, useMediaQuery } from '@material-ui/core';
import { useEffect, useRef, useState } from 'react';

const CardSlider = ({ items }) => {
	const classes = useStyles();
	const theme = useTheme();
	const downSm = useMediaQuery(theme.breakpoints.down('sm'));
	const downMd = useMediaQuery(theme.breakpoints.down('md'));

	const refSlider = useRef();
	const [indexFocused, setIndexFocused] = useState(0);

	useEffect(() => {
		handlePrev();
	}, []);

	const handlePrev = () => {
		if (indexFocused > 0) {
			if (downSm) refSlider.current.scrollLeft -= 300;
			else if (downMd) refSlider.current.scrollLeft -= 320;
			else refSlider.current.scrollLeft -= 480;
			setIndexFocused(indexFocused - 1);
		}
	};
	const handleNext = () => {
		if (indexFocused < items.length - 1) {
			if (downSm) refSlider.current.scrollLeft += 300;
			else if (downMd) refSlider.current.scrollLeft += 320;
			else refSlider.current.scrollLeft += 480;
			setIndexFocused(indexFocused + 1);
		}
	};

	return (
		<div className={classes.root}>
			<div className={classes.slider_container} ref={refSlider}>
				<Hidden smDown>
					<div style={{ display: 'inline-block' }}>
						<div className={classes.empty_item} />
					</div>
				</Hidden>

				{items.map((item, i) => (
					<Item item={item} index={i} key={i} current_index={indexFocused} />
				))}
				<Hidden smDown>
					<div style={{ display: 'inline-block' }}>
						<div className={classes.empty_item} />
					</div>
				</Hidden>
			</div>
			<div className={classes.buttons_container}>
				{indexFocused !== 0 && (
					<Button className={classes.left_button} onClick={handlePrev}>
						<Icon style={{ fontSize: 70 }}>chevron_left</Icon>
					</Button>
				)}
				{indexFocused !== items.length - 1 && (
					<Button className={classes.right_button} onClick={handleNext}>
						<Icon style={{ fontSize: 70 }}>chevron_right</Icon>
					</Button>
				)}
			</div>
		</div>
	);
};

const useStyles = makeStyles((theme) => ({
	root: {
		background: theme.palette.layout.background,
		width: 1440,
		margin: '0px auto',
		[theme.breakpoints.down('md')]: {
			width: 960,
		},
		[theme.breakpoints.down('sm')]: {
			width: 300,
		},
	},
	slider_container: {
		height: 620,
		whiteSpace: 'nowrap',
		display: 'flex',
		alignItems: 'center',
		overflowX: 'scroll',
		overflowY: 'hidden',
		'&::-webkit-scrollbar': {
			display: 'none',
		},
		msOverflowStyle: 'none',
		scrollbarWidth: 'none',
		scrollBehavior: 'smooth',
	},
	buttons_container: {
		margin: '50px 0px 80px',
		position: 'relative',
		height: 120,
	},
	left_button: {
		position: 'absolute',
		top: 0,
		left: 0,
		width: 120,
		height: 120,
		background: theme.palette.primary.main,
		borderRadius: 0,
		color: 'white',
		'&:hover': {
			background: theme.palette.primary.light,
		},
	},
	right_button: {
		position: 'absolute',
		top: 0,
		right: 0,
		width: 120,
		height: 120,
		background: theme.palette.secondary.main,
		borderRadius: 0,
		color: 'white',
		'&:hover': {
			background: theme.palette.secondary.light,
		},
	},
	empty_item: {
		position: 'relative',
		width: 480,
		height: 480,
		background: theme.palette.layout.background,
		[theme.breakpoints.down('md')]: {
			width: 320,
		},
		[theme.breakpoints.down('sm')]: {
			width: 300,
		},
	},
}));

const Item = ({ item, index, current_index }) => {
	const classes = useItemStyles();
	const isFocused = index === current_index;

	return (
		<div className={classes.item_container}>
			<div className={isFocused ? classes.focused_item : classes.unfocused_item}>
				<img src={item.img} className={classes.img_item} style={!isFocused ? { filter: 'grayscale(100%)' } : {}} />
				<Typography variant='h5' className={classes.name}>
					{item.name}
				</Typography>
				<Typography className={classes.position}>{item.position}</Typography>
				{isFocused && <div className={classes.email_container}>{item.email}</div>}
			</div>
		</div>
	);
};

const useItemStyles = makeStyles((theme) => ({
	item_container: {
		display: 'inline-block',
	},
	unfocused_item: {
		position: 'relative',
		width: 480,
		height: 480,
		[theme.breakpoints.down('md')]: {
			width: 320,
		},
		[theme.breakpoints.down('sm')]: {
			width: 300,
		},
	},
	focused_item: {
		position: 'relative',
		width: 480,
		height: 620,
		transition: 'height 0.8s',
		[theme.breakpoints.down('md')]: {
			width: 320,
		},
		[theme.breakpoints.down('sm')]: {
			width: 300,
		},
	},
	img_item: {
		width: '100%',
		height: '100%',
		objectFit: 'cover',
	},
	name: {
		position: 'absolute',
		top: 36,
		left: 25,
		color: 'white',
		[theme.breakpoints.down('md')]: {
			fontSize: '1.5rem',
		},
	},
	position: {
		position: 'absolute',
		top: 77,
		left: 25,
		color: 'white',
		fontSize: 18,
		fontWeight: 400,
		fontFamily: 'Source Serif Pro',
		fontStyle: 'italic',
	},
	email_container: {
		position: 'absolute',
		bottom: 0,
		height: 90,
		width: '100%',
		background: 'rgba(12, 66, 162, 0.53)',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		color: 'white',
		fontFamily: 'Raleway',
		fontSize: 16,
		fontWeight: 700,
	},
}));

export default CardSlider;
