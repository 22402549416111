import React from 'react';
import { TextField, withStyles, InputBase, InputLabel, Typography, FormHelperText, MenuItem } from '@material-ui/core';
import { ruleValidation } from './customFunctions';
import { makeStyles } from '@material-ui/styles';
import { grey } from '@material-ui/core/colors';

const CssTextField = withStyles((theme) => ({
	root: {
		fontFamily: 'Raleway',
		'& input': {
			color: theme.palette.text.primary,
			background: 'rgba(238, 239, 243, 0.65)',
			fontWeight: 600,
			padding: 15,
			border: '1px solid #939393',
			fontSize: 16,
		},
		'& textarea': {
			color: theme.palette.text.primary,
			padding: 15,
			fontWeight: 500,
			border: '1px solid #939393',
		},
		'&.Mui-error': {
			border: '1px solid red',
		},
	},
}))(InputBase);

const useStyles = makeStyles((theme) => ({
	multiline_classes: {
		color: theme.palette.text.secondary,
	},
	inputlabel: {
		marginBottom: 10,
		fontSize: 14,
		fontWeight: 500,
		fontFamily: 'Raleway',
	},
	required_star: {
		color: theme.palette.secondary.main,
		fontSize: 18,
		fontWeight: 700,
	},
}));

const InputFormGray = (props) => {
	const { data, onChange, startAdornment, endAdornment, onKeyUp, onKeyDown, overrideStyle } = props;
	const classes = useStyles();

	const onInnerChange = (event) => {
		const response = event.target.value;
		let temp = { ...data };
		temp.value = response;
		temp.isValid = ruleValidation(temp.value, temp.rules, temp.value2);
		temp.isVisited = true;
		onChange(temp);
	};

	const { config, value, isValid, isVisited, isRequired } = data;

	const isError = isVisited && !isValid;

	let interConfig = { ...config };
	let helperText = interConfig.helperText;
	delete interConfig['helperText'];

	if (!isError) helperText = '';

	return (
		<div>
			<InputLabel className={classes.inputlabel} htmlFor={interConfig.id}>
				{interConfig.label} {isRequired && <span className={classes.required_star}>*</span>}
			</InputLabel>
			<CssTextField value={value} variant='standard' color='secondary' error={isError} {...interConfig} onChange={onInnerChange} style={overrideStyle} />
			<FormHelperText error={isError}>{helperText}</FormHelperText>
		</div>
	);
};

export default InputFormGray;
