import imgC1 from '../../assets/home_view/carousel/LandingPage_01.jpg';
import imgC2 from '../../assets/home_view/carousel/LandingPage_01.jpg';
import imgC3 from '../../assets/home_view/carousel/LandingPage_01.jpg';
import imgC4 from '../../assets/home_view/carousel/LandingPage_01.jpg';
import imgC5 from '../../assets/home_view/carousel/LandingPage_01.jpg';

import img_sb from '../../assets/home_view/LandingPage_02.jpg';
import img_sb_gray from '../../assets/home_view/gray_adornment.png';
import img_sb_yellow from '../../assets/home_view/yellow_adornment.png';

import img_sc from '../../assets/home_view/LandingPage_03.jpg';

import img_sd_1 from '../../assets/home_view/LandingPage_04.jpg';
import img_sd_2 from '../../assets/home_view/LandingPage_05.jpg';
import img_sd_3 from '../../assets/home_view/LandingPage_06.jpg';
import img_sd_4 from '../../assets/home_view/LandingPage_07.jpg';

import img_se_c1 from '../../assets/home_view/LandingPage_08_g.png';
import img_se_c2 from '../../assets/home_view/LandingPage_08_g.png';
import img_se_c3 from '../../assets/home_view/LandingPage_08_g.png';
import img_se_c4 from '../../assets/home_view/LandingPage_08_g.png';
import img_se_c5 from '../../assets/home_view/LandingPage_08_g.png';

import icon_1_sf from '../../assets/Icons/care_white 1.png';
import icon_2_sf from '../../assets/Icons/globe_white 1.png';
import icon_3_sf from '../../assets/Icons/mortarboard_white 1.png';
import icon_4_sf from '../../assets/Icons/time_white 1.png';

export const translations = {
	sectionA: {
		carousel: [
			{
				id: 1,
				img: imgC1,
				title: 'Aprendizaje a la vanguardia',
				content: 'In sed tristique turpis sollicitudin tempus, volutpat sed., cursus magna urna donec amet interdum eget ultricies lacus.',
				link: 'CONOCE MÁS',
			},
			{
				id: 2,
				img: imgC2,
				title: 'Part 2 carousel',
				content: 'In sed tristique turpis sollicitudin tempus, volutpat sed., cursus magna urna donec amet interdum eget ultricies lacus.',
				link: 'CONOCE MÁS',
			},
			{
				id: 3,
				img: imgC3,
				title: 'Part 3 carousel',
				content: 'In sed tristique turpis sollicitudin tempus, volutpat sed., cursus magna urna donec amet interdum eget ultricies lacus.',
				link: 'CONOCE MÁS',
			},
			{
				id: 4,
				img: imgC4,
				title: 'Part 4 carousel',
				content: 'In sed tristique turpis sollicitudin tempus, volutpat sed., cursus magna urna donec amet interdum eget ultricies lacus.',
				link: 'CONOCE MÁS',
			},
			{
				id: 5,
				img: imgC5,
				title: 'Part 5 carousel',
				content: 'In sed tristique turpis sollicitudin tempus, volutpat sed., cursus magna urna donec amet interdum eget ultricies lacus.',
				link: 'CONOCE MÁS',
			},
		],
	},
	sectionB: {
		title: 'Somos una escuela digital de formación vanguardista',
		img: img_sb,
		img_gray: img_sb_gray,
		img_yellow: img_sb_yellow,
		phrase: '“Mi in pretium amet lectus nunc. Lectus nam quis malesuada pulvinar enim ac vitae nunc. ”',
	},
	sectionC: {
		header_title: 'Formamos líderes',
		header_content: 'Magna senectus ac ut vitae fringilla adipiscing nullam tincidunt. Urna eget tellus euismod imperdiet.',
		img: img_sc,
		body_title: 'Busca crecer tu formación académica',
		body_button: 'CONTÁCTANOS',
	},
	sectionD: {
		link: 'Conoce más',
		items: [
			{
				title: 'Educación Continua',
				img: img_sd_1,
				text: 'Sed accumsan arcu vitae commodo aliquet. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Duis vestibulum, ipsum vitae egestas volutpat, ipsum orci blandit enim, in semper mi nulla vitae mauris. Nulla in lectus vestibulum, commodo velit quis, scelerisque felis.',
			},
			{
				title: 'Certificación cero',
				img: img_sd_2,
				text: 'Nunc at ex lobortis, consectetur felis nec, elementum ex. Aliquam vitae risus blandit, sodales nisi id, elementum risus. Maecenas dignissim sed ligula vitae hendrerit.',
			},
			{
				title: 'Licenciaturas online',
				img: img_sd_3,
				text: 'Proin imperdiet tempus nisi nec lacinia. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Mauris vel vestibulum dolor. Fusce nunc turpis, sollicitudin ac accumsan quis, commodo quis dui. Donec nunc nisl, suscipit ut consectetur at, luctus et nisi.',
			},
			{
				title: 'Cursos',
				img: img_sd_4,
				text: 'Nulla vestibulum erat ac nisi ultricies, vitae varius purus aliquet. Aliquam vitae suscipit tortor. Donec imperdiet efficitur finibus. Nulla nec diam mauris. Donec a faucibus ex. Pellentesque at commodo enim, nec eleifend ipsum.',
			},
		],
	},
	sectionE: {
		header_title: 'Conoce nuestra oferta',
		header_text: 'Tenemos los programas académicos que potencian tu formación.',
		carousel_button: 'Conoce más',
		carousel: [
			{
				id: 1,
				img: img_se_c1,
				cat: 'Diplomado',
				title: 'Oratoria',
				content: 'Ornare velit nulla risus vulputate lacus ultrices sed lorem. Dolor, cursus odio arcu tellus, metus aliquet nec. Duis sed blandit tortor, convallis neque ',
				link: 'conoce más',
			},
			{
				id: 2,
				img: img_se_c2,
				cat: 'Diplomado',
				title: 'Part 2 carousel',
				content: 'In sed tristique turpis sollicitudin tempus, volutpat sed., cursus magna urna donec amet interdum eget ultricies lacus.',
				link: 'conoce más',
			},
			{
				id: 3,
				img: img_se_c3,
				cat: 'Diplomado',
				title: 'Part 3 carousel',
				content: 'In sed tristique turpis sollicitudin tempus, volutpat sed., cursus magna urna donec amet interdum eget ultricies lacus.',
				link: 'conoce más',
			},
			{
				id: 4,
				img: img_se_c4,
				cat: 'Diplomado',
				title: 'Part 4 carousel',
				content: 'In sed tristique turpis sollicitudin tempus, volutpat sed., cursus magna urna donec amet interdum eget ultricies lacus.',
				link: 'conoce más',
			},
			{
				id: 5,
				img: img_se_c5,
				cat: 'Diplomado',
				title: 'Part 5 carousel',
				content: 'In sed tristique turpis sollicitudin tempus, volutpat sed., cursus magna urna donec amet interdum eget ultricies lacus.',
				link: 'conoce más',
			},
		],
	},
	sectionF: {
		header_title: 'Prepárate con los mejores',
		header_text: 'Tenemos los programas académicos que potencian tu formación.',
		items: [
			{
				icon: icon_1_sf,
				title: 'Atención',
				text: 'Magna ullamcorper aliquam, orci aliquam sit tristique diam dictumst massa. Et nulla lectus neque fringilla nunc id tortor. Vestibulum a tortor sapien, feugiat. Augue feugiat tortor ut ac tristique lectus praesent.',
			},
			{
				icon: icon_2_sf,
				title: 'Campus Global',
				text: 'Magna ullamcorper aliquam, orci aliquam sit tristique diam dictumst massa. Et nulla lectus neque fringilla nunc id tortor. Vestibulum a tortor sapien, feugiat. Augue feugiat tortor ut ac tristique lectus praesent.',
			},
			{
				icon: icon_3_sf,
				title: 'Excelencia',
				text: 'Magna ullamcorper aliquam, orci aliquam sit tristique diam dictumst massa. Et nulla lectus neque fringilla nunc id tortor. Vestibulum a tortor sapien, feugiat. Augue feugiat tortor ut ac tristique lectus praesent.',
			},
			{
				icon: icon_4_sf,
				title: 'Flexible',
				text: 'Magna ullamcorper aliquam, orci aliquam sit tristique diam dictumst massa. Et nulla lectus neque fringilla nunc id tortor. Vestibulum a tortor sapien, feugiat. Augue feugiat tortor ut ac tristique lectus praesent.',
			},
		],
	},
	sectionG: {
		header_title: 'Empieza hoy a prepararte para el futuro',
		header_button: 'Regístrate',
		steps: [
			{ title: 'Registro', text: 'Laoreet sit pellentesque sed a, sed eget. Sit magna nunc, nunc amet vel fusce.' },
			{ title: 'Actualización de datos y documentos', text: 'Habitant diam sed dui aliquam at urna et. Aliquet mattis arcu aliquam aenean id massa.' },
			{ title: 'Aprobación', text: 'Laoreet sit pellentesque sed a, sed eget. Sit magna nunc, nunc amet vel fusce.' },
			{ title: 'Primer día de clases', text: 'Habitant diam sed dui aliquam at urna et. Aliquet mattis arcu aliquam aenean id massa.' },
		],
	},
};

export default translations;
