import { Grid, makeStyles, Typography } from '@material-ui/core';
import system_translations from '../../../texts/system_translations';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'relative',
		backgroundColor: theme.palette.primary.main,
		height: 337,
		color: 'white',
		padding: '88px 140px',
		[theme.breakpoints.down('sm')]: {
			padding: '40px 40px',
			height: '100%',
		},
	},
	footer_section: {
		padding: '0px 10px',
		[theme.breakpoints.down('sm')]: {
			padding: 0,
			margin: '20px 0px',
		},
	},
	logo_vanguard: {
		width: 110,
		[theme.breakpoints.down('sm')]: {
			display: 'block',
			margin: '0px auto',
		},
		cursor: 'pointer',
	},
	icon_footer: {
		width: 40,
		height: 40,
	},
	icon_footer_container: {
		margin: '0px 10px',
	},
	privacy_link: {
		cursor: 'pointer',
		width: 175,
		display: 'block',
		margin: '0px auto',
		borderRight: '1px solid white',
		borderLeft: '1px solid white',
		marginTop: 100,
		marginBottom: 40,
		'&:hover': {
			textDecoration: 'underline white',
		},
	},
}));

const Footer = ({ history, language }) => {
	const classes = useStyles();
	const content = system_translations[language].layouts.footer;

	const onChangeView = (url) => {
		history.push(url);
	};

	return (
		<div className={classes.root}>
			<Grid container>
				<Grid item md={3} xs={12} className={classes.footer_section}>
					<img src={content.logo_white} alt='logo' className={classes.logo_vanguard} onClick={() => onChangeView('/')} />
				</Grid>
				<Grid item container md={3} xs={12} className={classes.footer_section}>
					<Grid item xs={12} style={{ marginBottom: 20 }}>
						<Typography variant='subtitle1'>{content.contact.title}</Typography>
					</Grid>
					<Grid item container xs={12} style={{ marginBottom: 16 }}>
						<Grid item xs={4}>
							<Typography variant='body2' style={{ fontWeight: 600 }}>
								{content.contact.wapp}
							</Typography>
						</Grid>
						<Grid item xs>
							<Typography variant='body2'>{content.contact.wapp_number}</Typography>
						</Grid>
					</Grid>
					<Grid item container xs={12} style={{ marginBottom: 16 }}>
						<Grid item xs={4}>
							<Typography variant='body2' style={{ fontWeight: 600 }}>
								{content.contact.tel}
							</Typography>
						</Grid>
						<Grid item xs>
							<Typography variant='body2'>{content.contact.tel_number}</Typography>
						</Grid>
					</Grid>
					<Grid item container xs={12}>
						<Grid item xs={4}>
							<Typography variant='body2' style={{ fontWeight: 600 }}>
								{content.contact.email}
							</Typography>
						</Grid>
						<Grid item xs>
							<Typography variant='body2'>{content.contact.email_account}</Typography>
						</Grid>
					</Grid>
				</Grid>
				<Grid item container md={3} xs={12} className={classes.footer_section}>
					<Grid item xs={12}>
						<Typography variant='subtitle1' style={{ marginBottom: 20 }}>
							{content.visit.title}
						</Typography>
						<Typography variant='body2'>{content.visit.content}</Typography>
					</Grid>
				</Grid>
				<Grid item container md={3} xs={12} justifyContent='center' className={classes.footer_section}>
					<Grid item xs={2} className={classes.icon_footer_container}>
						<img src={content.icons.facebook} alt='facebook' className={classes.icon_footer} />
					</Grid>
					<Grid item xs={2} className={classes.icon_footer_container}>
						<img src={content.icons.instagram} alt='instagram' className={classes.icon_footer} />
					</Grid>
					<Grid item xs={2} className={classes.icon_footer_container}>
						<img src={content.icons.twitter} alt='twitter' className={classes.icon_footer} />
					</Grid>
					<Grid item xs={12} style={{ textAlign: 'center' }}>
						<img src={content.lock_icon} alt='' style={{ width: 14, height: 17.5 }} />
						<Typography variant='subtitle1' display='inline' style={{ margin: '0px 13px' }}>
							{content.ssl}
						</Typography>
					</Grid>
				</Grid>
			</Grid>
			<div className={classes.privacy_link}>
				<Typography align='center' style={{ fontSize: '1rem', fontWeight: 700 }}>
					{content.privacy_notice}
				</Typography>
			</div>
			<hr style={{ borderBottom: 0 }} />
			<Typography align='center' variant='body2' style={{ marginTop: 30 }}>
				{content.copyright}
			</Typography>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		user: state.user,
		is_auth: state.is_auth,
		language: state.language,
	};
};

export default withRouter(connect(mapStateToProps)(Footer));
