import { useState, useEffect } from 'react';
import { private_server, public_server } from '../../../config';
import {
	isFormValid,
	onGetErrorMessage,
	onGetFormData,
	onGetSelectedFormData,
	onInitForm,
	onSetErrorsToForm,
	onSetTokenSession,
	updateAuthorizationHeader,
} from '../../../shared/utility';
import system_translations from '../../../texts/system_translations';

const useContactInfo = ({ language, content, saved_form }) => {
	const [loading, setLoading] = useState(false);
	const [sending, setSending] = useState(false);
	const [error, setError] = useState(null);
	const [form, setForm] = useState(saved_form !== null ? saved_form : form_data);

	const errors_txt = system_translations[language].layouts.errors;

	useEffect(() => {
		actions.onInitModule();
	}, []);

	const formatCountry = () => {
		let catalog = content.country_list.map((item) => {
			let temp = {};
			temp.label = item.name;
			temp.value = item.code;
			return temp;
		});

		return catalog;
	};

	const formatCountryCodes = () => {
		let cat = content.country_codes.map((item) => {
			let temp = {};
			temp.label = `${item.dial_code}: ${item.name}`;
			temp.value = item.dial_code;
			return temp;
		});

		return cat;
	};

	const actions = {
		onInitModule: async () => {
			let _form = onInitForm(form, null, content.form);
			_form.country_residence.options = formatCountry();
			_form.country_code.options = formatCountryCodes();
			setForm(_form);
		},
		onChangeForm: (data) => {
			let temp = { ...form };
			const id = data.config.id;
			temp[id] = { ...data };
			if (error) setError(null);
			setForm(temp);
		},
		onKeyPress: (event) => {
			if (event.key === 'Enter') {
				actions.onSubmit();
			}
		},
		onSubmit: async () => {
			const errors = isFormValid(form);

			if (errors && errors.length) {
				console.log(errors);
				const _form = onSetErrorsToForm(form, errors);
				setForm(_form);
				setError(errors_txt.INCOMPLETE_FORM);
				return;
			}

			const data2send = onGetFormData(form);
			data2send.device = 'web';
			console.log(data2send);

			setSending(true);

			try {
				const response = await public_server.post(`/general-contact`, data2send);
				const token_data = response.data.data;

				setError(null);
			} catch (error) {
				setSending(false);
				const _error = onGetErrorMessage(error);
				setError(_error ? _error.message : null);
			}

			setSending(false);

			/* setLoading(true)
            try {
                updateAuthorizationHeader(private_server)
                await private_server.patch('/users/me', data2send)
                const _user = await onGetCurrentUserProfile()
                onUpdateUserData(_user)

            } catch (error) {
                console.log(error)
            }
            setLoading(false) */
		},
	};

	const system = { loading, sending, error };

	return { system, form, actions };
};

export default useContactInfo;

const form_data = {
	email: {
		value: '',
		error: false,
		isVisited: false,
		isRequired: true,
		isValid: false,
		config: {
			id: 'email',
			type: 'text',
			fullWidth: true,
		},
		rules: {
			type: 'email',
		},
	},
	country_code: {
		value: '+52',
		error: false,
		isVisited: false,
		isRequired: true,
		isValid: false,
		config: {
			id: 'country_code',
			fullWidth: true,
		},
		rules: {
			type: 'select',
		},
	},
	cell_phone: {
		value: '',
		error: false,
		isVisited: false,
		isRequired: true,
		isValid: false,
		config: {
			id: 'cell_phone',
			type: 'text',
			fullWidth: true,
		},
		rules: {
			type: 'phone',
		},
	},
	phone: {
		value: '',
		error: false,
		isVisited: false,
		isRequired: true,
		isValid: false,
		config: {
			id: 'phone',
			type: 'text',
			fullWidth: true,
		},
		rules: {
			type: 'phone',
		},
	},
	country_residence: {
		value: 'default',
		error: false,
		isVisited: false,
		isRequired: true,
		isValid: false,
		config: {
			id: 'country_residence',
			fullWidth: true,
		},
		rules: {
			type: 'select',
		},
	},
	address: {
		value: '',
		error: false,
		isVisited: false,
		isRequired: true,
		isValid: false,
		config: {
			id: 'address',
			type: 'text',
			fullWidth: true,
		},
		rules: {
			type: 'distance',
			min: 1,
			max: 500,
		},
	},
	zipcode: {
		value: '',
		error: false,
		isVisited: false,
		isRequired: true,
		isValid: false,
		config: {
			id: 'zipcode',
			type: 'text',
			fullWidth: true,
		},
		rules: {
			type: 'zip',
		},
	},
};
