import bannerImg from '../../assets/banners/bannerSignin.jpg';
import logo from '../../assets/logo_vanguard.png';

const translations = {
	title: 'Iniciar Sesión',
	forgotten_password: '¿Olvidaste tu contraseña?',
	stay_signed: 'Permanecer conectado',
	dont_have_account: '¿Aún no te registras?',
	sign_up: 'Regístrate',
	banner: bannerImg,
	logo: logo,
	button: 'Iniciar Sesión',
	form: {
		email: {
			placeholder: 'Correo electrónico',
			label: 'Correo electrónico',
			helperText: 'Correo electrónico no válido',
		},
		password: {
			placeholder: 'Contraseña',
			label: 'Contraseña',
			helperText: 'Campo requerido',
		},
	},
};

export default translations;
