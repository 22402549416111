import { makeStyles, Grid, Typography, useTheme, useMediaQuery, Icon, Collapse, Grow } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import cx from 'classnames';
import InViewMonitor from 'react-inview-monitor';

const SectionD = ({ content, history }) => {
	const classes = useStyles();
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const [clickedIndex, setClickedIndex] = useState(-1);
	const [onView, setOnView] = useState(false);

	const onChangeView = (url) => {
		history.push(url);
	};

	const handleClickItem = (clickedIndItem) => {
		if (clickedIndItem !== clickedIndex) setClickedIndex(clickedIndItem);
		else setClickedIndex(-1);
	};

	const items = content.items;
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<>
			<InViewMonitor
				onInView={() => {
					setOnView(true);
				}}
			>
				<Grow in={onView} timeout={1000}>
					<div className={classes.root}>
						<Grid container className={classes.main_container}>
							{items.map((item, i) => (
								<Item
									key={i}
									title={item.title}
									text={item.text}
									img={item.img}
									handleClick={handleClickItem}
									index={i}
									clickedIndex={clickedIndex}
									isMobile={isMobile}
									onView={onView}
								/>
							))}
						</Grid>
						{!isMobile && (
							<Collapse in={clickedIndex !== -1}>
								<div className={classes.text_container}>
									<Typography className={classes.text}>{clickedIndex === -1 ? '' : items[clickedIndex].text}</Typography>
								</div>
							</Collapse>
						)}
						<div className={classes.learn_more_container}>
							<div
								onClick={() => {
									onChangeView('/signin');
								}}
								className={classes.learn_more_link}
							>
								<Typography variant='subtitle1' style={{ display: 'inline-block', textTransform: 'uppercase' }}>
									{content.link}
								</Typography>
								<Icon style={{ fontSize: 30, display: 'table-cell', verticalAlign: 'middle' }}>chevron_right</Icon>
							</div>
						</div>
					</div>
				</Grow>
			</InViewMonitor>
		</>
	);
};

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'relative',
		background: theme.palette.layout.background,
		margin: '50px 0px',
		[theme.breakpoints.down('sm')]: {
			margin: '10px 0px',
		},
	},
	main_container: {},
	text_container: {
		padding: '55px 140px',
		background: 'rgba(117, 171, 255, 0.16)',
	},
	text: {
		color: '#474747',
		fontSize: 20,
		fontWeight: 400,
	},
	learn_more_container: {
		height: 74,
		background: '#2B4578',
		display: 'flex',
		alignItems: 'center',
	},
	learn_more_link: {
		display: 'table',
		marginLeft: 'auto',
		marginRight: 140,
		paddingTop: 2,
		color: 'white',
		cursor: 'pointer',
		[theme.breakpoints.down('sm')]: {
			marginRight: 10,
		},
	},
}));

const Item = ({ title, text, img, index, handleClick, clickedIndex, isMobile, onView }) => {
	const classes = useStylesItem();
	const isClicked = clickedIndex === index;

	return (
		<>
			<Grow in={onView} timeout={index * 800}>
				<Grid
					item
					md={3}
					container
					className={cx({ [classes.item_container_grid]: true, [classes.item_container_padding]: index !== 3 })}
					onClick={() => {
						handleClick(index);
					}}
				>
					<div className={classes.item_container}>
						<img src={img} className={classes.img_item} />
						<div className={cx({ [classes.img_bg_black]: isClicked, [classes.img_bg_blue]: !isClicked })} />
						<Typography align='center' className={classes.text_item}>
							{title}
						</Typography>
						{!isClicked ? <Icon className={classes.icon_item}>expand_more</Icon> : <Icon className={classes.icon_item}>expand_less</Icon>}
					</div>
				</Grid>
			</Grow>
			{isMobile && (
				<Collapse in={isClicked}>
					<div className={classes.text_container}>
						<Typography className={classes.text}>{text}</Typography>
					</div>
				</Collapse>
			)}
		</>
	);
};

export default withRouter(SectionD);

const useStylesItem = makeStyles((theme) => ({
	item_container_grid: {
		height: 395,
		cursor: 'pointer',
		[theme.breakpoints.down('sm')]: {
			height: 200,
		},
	},
	item_container: {
		position: 'relative',
		height: '100%',
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	item_container_padding: {
		paddingRight: 12,
		[theme.breakpoints.down('sm')]: {
			padding: 0,
		},
	},
	img_bg_blue: {
		height: '100%',
		width: '100%',
		background: 'linear-gradient(0deg, rgba(12, 66, 162, 0.4), rgba(12, 66, 162, 0.4))',
		position: 'absolute',
		top: 0,
		left: 0,
		zIndex: '2',
	},
	img_bg_black: {
		height: '100%',
		width: '100%',
		background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.13), rgba(0, 0, 0, 0.13))',
		position: 'absolute',
		top: 0,
		left: 0,
		zIndex: '2',
	},
	img_item: {
		objectFit: 'cover',
		width: '100%',
		height: '100%',
		zIndex: '1',
	},
	text_item: {
		color: 'white',
		fontSize: 20,
		fontWeight: 600,
		position: 'absolute',
		maxWidth: 182,
		zIndex: '3',
	},
	icon_item: {
		color: 'white',
		fontSize: 40,
		position: 'absolute',
		zIndex: '3',
		marginTop: 350,
		[theme.breakpoints.down('sm')]: {
			marginTop: 155,
		},
	},
	text_container: {
		padding: '30px 20px',
		background: 'rgba(117, 171, 255, 0.16)',
	},
	text: {
		color: '#474747',
		fontSize: 16,
		fontWeight: 400,
	},
}));
