import { makeStyles, Hidden, Paper, Button, Typography, Icon, useMediaQuery, useTheme, Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import { useEffect } from 'react';
import { withRouter } from 'react-router';
import Carousel from 'react-material-ui-carousel';

const LatestNews = ({ history, news_array }) => {
	const classes = useStyles();
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	const onChangeView = (url) => {
		history.push(url);
	};

	const items = news_array;

	return (
		<div className={classes.root}>
			<Carousel
				className={classes.carousel}
				stopAutoPlayOnHover={false}
				interval={6000}
				indicators={false}
				animation='slide'
				NavButton={({ onClick, className, style, next, prev }) => {
					// Other logic

					return (
						<Button
							onClick={onClick}
							className={className}
							style={next ? { ...style, background: theme.palette.secondary.main } : { ...style, background: theme.palette.primary.main }}
						>
							{next && <Icon style={{ fontSize: 70 }}>chevron_right</Icon>}
							{prev && <Icon style={{ fontSize: 70 }}>chevron_left</Icon>}
						</Button>
					);
				}}
				indicatorIconButtonProps={{
					style: {
						padding: '0px 12.5px',
						color: 'rgba(255, 255, 255, 0.44)',
					},
				}}
				activeIndicatorIconButtonProps={{
					style: {
						color: 'white',
					},
				}}
				indicatorContainerProps={{
					style: {},
				}}
				navButtonsProps={{
					style: {
						backgroundColor: theme.palette.primary.main,
						height: 120,
						width: 120,
						borderRadius: 0,
						margin: 0,
					},
				}}
				navButtonsWrapperProps={{
					style: {
						height: 120,
						bottom: 40,
						top: 'unset',
					},
				}}
				navButtonsAlwaysVisible
			>
				{items.map((item, i) => (
					<Item key={i} item={item} onChangeView={onChangeView} />
				))}
			</Carousel>
		</div>
	);
};

const Item = ({ item, onChangeView }) => {
	const classes = usePaperStyles();

	return (
		<Paper className={classes.paper}>
			<Grid container className={classes.carousel_grid_container}>
				<Grid item md={5} xs={12}>
					<div className={classes.carousel_content}>
						<Typography variant='subtitle1' className={classes.carousel_cat}>
							{item.cat}
						</Typography>
						<Typography variant='h3' className={classes.carousel_title}>
							{item.title}
						</Typography>

						<div className={classes.carousel_link} onClick={() => onChangeView(item.link_path)}>
							<Typography variant='subtitle1' style={{ display: 'inline-block', textTransform: 'uppercase' }}>
								{item.link_text}
							</Typography>
							<Icon style={{ fontSize: 30, display: 'table-cell', verticalAlign: 'middle' }}>chevron_right</Icon>
						</div>
					</div>
				</Grid>
				<Grid item md={7} xs={12}>
					<div className={classes.carousel_img_container}>
						<img src={item.img} className={classes.carousel_img} />
					</div>
				</Grid>
			</Grid>
		</Paper>
	);
};

export default withRouter(LatestNews);

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'relative',
		marginBottom: 100,
		boxSizing: 'border-box',
	},
	carousel: {
		background: `linear-gradient(${theme.palette.primary.main} 63%, ${theme.palette.layout.background} 37%)`,
	},
}));

const usePaperStyles = makeStyles((theme) => ({
	root: {
		position: 'relative',
		background: theme.palette.primary.main,
		boxSizing: 'border-box',
	},
	paper: {
		height: 900,
		padding: '100px 140px',
		background: 'transparent',
		boxSizing: 'border-box',
		[theme.breakpoints.down('md')]: {
			padding: '40px 60px',
			width: '100%',
		},
	},
	carousel_grid_container: {
		position: 'absolute',
		width: '100%',
	},
	carousel_title: {
		marginBottom: 28,
		fontSize: 40,
	},
	carousel_content: {
		color: 'white',
		maxWidth: 400,
		marginTop: 58,
		[theme.breakpoints.down('md')]: {
			marginTop: 10,
			maxWidth: '80%',
		},
	},
	carousel_cat: {
		fontSize: 18,
		fontWeight: 600,
		color: theme.palette.text.gray,
	},
	carousel_link: {
		cursor: 'pointer',
		marginTop: 50,
		display: 'table',
		color: theme.palette.secondary.main,
		fontSize: 16,
	},
	carousel_img_container: {
		width: 686,
		height: 655,
		marginLeft: 'auto',
		marginRight: 280,
		[theme.breakpoints.down('sm')]: {
			margin: '20px 0px',
			width: '80%',
			height: 400,
		},
	},
	carousel_img: {
		width: '100%',
		height: '100%',
		objectFit: 'cover',
		objectPosition: 'center center',
		borderRadius: 20,
	},
}));
