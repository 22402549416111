import { makeStyles, Typography } from '@material-ui/core';
import img_adornment from '../../assets/adornments/header_view_adornment.png';

const ViewHeader = ({ title, img }) => {
	const classes = useStyles();
	return (
		<div className={classes.root}>
			<img className={classes.img_bg} src={img} alt='' />
			<div className={classes.bg_overlay}></div>
			<div className={classes.title_container}>
				<img src={img_adornment} style={{ marginRight: 135 }} alt='' />
				<Typography align='center' className={classes.title}>
					{title}
				</Typography>
				<img src={img_adornment} style={{ marginLeft: 135 }} alt='' />
			</div>
		</div>
	);
};

const useStyles = makeStyles((theme) => ({
	root: {
		height: 327,
		position: 'relative',
	},
	img_bg: {
		width: '100%',
		height: '100%',
		objectFit: 'cover',
		zIndex: '1',
	},
	bg_overlay: {
		position: 'absolute',
		width: '100%',
		height: '100%',
		top: 0,
		left: 0,
		background: 'linear-gradient(0deg, rgba(8, 37, 79, 0.58), rgba(8, 37, 79, 0.58))',
		zIndex: '2',
	},
	title_container: {
		position: 'absolute',
		height: 90,
		width: '100%',
		top: 167,
		left: 0,
		background: 'rgba(232, 202, 112, 0.32)',
		zIndex: '3',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	title: {
		fontSize: 48,
		fontWeight: 600,
		color: 'white',
	},
}));

export default ViewHeader;
