import React from 'react'
import { Redirect, Route, Switch } from 'react-router'
import AboutUsView from '../views/AboutUs/AboutUsView';
import HomeView from '../views/HomeView/HomeView';
import NewsView from '../views/News/NewsView';
import OfferingView from '../views/Offering/OfferingView';

const DashboardRouter = (props) => {
	return (
		<Switch>
			<Route exact path='/offering' component={OfferingView} />
			<Route exact path='/news' component={NewsView} />
			<Route exact path='/about' component={AboutUsView} />
			<Route exact path='/' component={HomeView} />
			<Redirect to='/' />
		</Switch>
	);
};

export default DashboardRouter

