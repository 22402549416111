import React from 'react'
import { TextField, withStyles } from '@material-ui/core'
import { ruleValidation } from './customFunctions'
import { makeStyles } from '@material-ui/styles';
import { grey } from '@material-ui/core/colors';

const CssTextField = withStyles((theme) => ({
	root: {
		//padding:'8px 14px',
		fontFamily: 'Raleway',
		paddingTop: 6,
		'& input': {
			color: theme.palette.text.primary,
			//background: '#EEEFF3',
			padding: '15px 0px',
			fontFamily: theme.typography.fontFamily,
			fontWeight: '500',
		},
		'& textarea': {
			color: theme.palette.text.primary,
			padding: '15px 0px',
			fontFamily: theme.typography.fontFamily,
			fontWeight: '500',
		},
		'& .MuiInput-underline': {
			borderBottomColor: grey[300],
			borderBottomWidth: 2,
			'&:hover:not($disabled):after': {
				//its when its hover and input is focused
			},
			'&:hover:not($disabled):before': {
				borderBottomWidth: 2,
				borderBottomColor: theme.palette.secondary.main, //its when you hover and input is not foucused
			},
			'&:after': {
				borderBottomColor: theme.palette.primary.main, //when input is focused, Its just for example. Its better to set this one using primary color
			},
			'&:before': {
				borderBottomWidth: 2,
				borderBottomColor: grey[300], // when input is not touched
			},
		},
		'& label': {
			color: theme.palette.text.forms,
			fontWeight: 600,
			margin: '15px 0px',
		},
		'& label.Mui-focused': {
			color: theme.palette.text.secondary,
			margin: 0,
		},
	},
}))(TextField);

const useStyles = makeStyles((theme) => ({
	multiline_classes: {
		color: theme.palette.text.secondary,
	},
	inputlabel: {
		//color:'red'
	},
}));

const InputForm = (props) => {
	const { data, onChange, startAdornment, endAdornment, onKeyUp, onKeyDown } = props;
	const classes = useStyles();

	const onInnerChange = (event) => {
		const response = event.target.value;
		let temp = { ...data };
		temp.value = response;
		temp.isValid = ruleValidation(temp.value, temp.rules, temp.value2);
		temp.isVisited = true;
		onChange(temp);
	};

	const { config, value, isValid, isVisited } = data;

	const isError = isVisited && !isValid;
	let interConfig = { ...config };
	if (!isError) interConfig.helperText = '';

	return (
		<div>
			<CssTextField
				value={value}
				variant='standard'
				color='secondary'
				error={isError}
				{...interConfig}
				onChange={onInnerChange}
				InputProps={{
					startAdornment: startAdornment ? startAdornment : undefined,
					endAdornment: endAdornment ? endAdornment : undefined,
					onKeyUp: onKeyUp ? onKeyUp : undefined,
					onKeyDown: onKeyDown ? onKeyDown : undefined,
					classes: { multiline: classes.multiline_classes },
				}}
				InputLabelProps={{
					className: classes.inputlabel,
				}}
			/>
		</div>
	);
};

export default InputForm