import { makeStyles, Grid, Icon, Typography, Hidden, Grow, Fade } from '@material-ui/core';
import img_header_adornment from '../../../assets/adornments/about_sd_adornment.png';
import InViewMonitor from 'react-inview-monitor';
import { useState } from 'react';

const SectionD = ({ content }) => {
	const classes = useStyles();
	const [onView, setOnView] = useState(false);

	return (
		<div className={classes.root}>
			<div className={classes.header_container}>
				<Typography align='center' variant='h5' className={classes.header_title}>
					{content.header_title}
				</Typography>
				<img src={img_header_adornment} className={classes.adornment_img} />
				<div className={classes.adornment_line} style={{ left: 0 }} />
				<div className={classes.adornment_line} style={{ right: 0 }} />
			</div>
			<InViewMonitor
				onInView={() => {
					setOnView(true);
				}}
			>
				<div className={classes.body_container}>
					<Hidden mdDown>
						<div className={classes.img_container}>
							<img src={content.body_img} className={classes.img} />
						</div>
					</Hidden>
					<Typography variant='h4' className={classes.body_title}>
						{content.body_title}
					</Typography>
					<div className={classes.testimony_container}>
						<Grid container>
							{content.testimony_array.map((item, i) => (
								<Grow key={i} in={onView} timeout={1000 + i * 120}>
									<Grid container item xs={12} sm={6} xl={4} className={classes.item_container}>
										<Grid item xs={2}>
											<Icon className={classes.quote_icon}>format_quote</Icon>
										</Grid>
										<Grid item xs={10}>
											<Typography className={classes.testimony}>{item}</Typography>
										</Grid>
									</Grid>
								</Grow>
							))}
						</Grid>
					</div>
				</div>
			</InViewMonitor>
		</div>
	);
};

const useStyles = makeStyles((theme) => ({
	root: {},
	header_container: {
		position: 'relative',
		height: 267,
		background: 'rgba(43, 66, 101, 0.94)',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	header_title: {
		color: 'white',
		fontStyle: 'italic',
	},
	adornment_img: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%,-50%)',
	},
	adornment_line: {
		position: 'absolute',
		height: 0,
		width: '20%',
		border: '0.5px solid rgba(244, 230, 188, 0.32)',
		top: '50%',
		transform: 'translateY(-50%)',
	},
	body_container: {
		height: 800,
		background: 'rgba(233, 205, 122, 0.17)',
		position: 'relative',
		[theme.breakpoints.down('md')]: {
			height: 'auto',
		},
		//padding: '0px 140px 85px 0px',
	},
	img_container: {
		height: '100%',
		width: 650,
	},
	img: {
		width: '100%',
		height: '100%',
		objectFit: 'cover',
		clipPath: 'polygon(100% 0, 100% 10%, 35% 100%, 0 100%, 0 0)',
		objectPosition: 'right center',
	},
	body_title: {
		position: 'absolute',
		top: 85,
		left: 500,
		fontSize: '2.5rem',
		textShadow: '0px 4px 9px rgba(0, 0, 0, 0.25)',
		[theme.breakpoints.down('md')]: {
			position: 'relative',
			width: '100%',
			top: 0,
			left: 0,
			padding: 50,
			fontSize: '2rem',
		},
	},
	testimony_container: {
		position: 'absolute',
		//width: 1200,
		top: 200,
		left: 480,
		[theme.breakpoints.down('md')]: {
			position: 'relative',
			width: '100%',
			top: 0,
			left: 0,
			padding: 20,
		},
	},
	item_container: {
		width: '100%',
		padding: 30,
		[theme.breakpoints.down('sm')]: {
			paddingRight: 50,
		},
	},
	quote_icon: {
		transform: 'rotate(-180deg)',
		color: 'rgba(232, 202, 112, 0.37)',
		fontSize: 80,
	},
	testimony: {
		fontFamily: 'Source Serif Pro',
		fontSize: 16,
		fontWeight: 400,
		fontStyle: 'italic',
		marginLeft: 36,
	},
}));

export default SectionD;
