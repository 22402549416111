import { makeStyles, Grid, Typography, IconButton, Hidden } from '@material-ui/core';
import InputFormGray from '../../../components/Forms/InputFormGray';
import usePersonalData from './usePersonalData';
import InputSelectFormGray from '../../../components/Forms/InputSelectFormGray';
import ButtonGroupForm from '../../../components/Forms/ButtonGroupForm';
import { isFormValid } from '../../../shared/utility';
import { useEffect } from 'react';
import ControlButtons from '../components/ControlButtons';

const PersonalData = ({ content, language, saved_form, onPrevStep, onNextStep }) => {
	const classes = useStyles();
	let { system, form, actions } = usePersonalData({ language, content, saved_form });

	return (
		<>
			<Typography align='center' className={classes.title}>
				{content.title}
			</Typography>
			<Grid container justifyContent='center'>
				<Grid item xs={12} md={5} className={classes.input_container}>
					<InputFormGray data={form.name} onChange={actions.onChangeForm} onKeyPress={actions.onKeyPress} />
				</Grid>
				<Hidden smDown>
					<Grid item xs={2} style={{ maxWidth: 60 }} />
				</Hidden>
				<Grid item xs={12} md={5} className={classes.input_container}>
					<InputFormGray data={form.m_surname} onChange={actions.onChangeForm} onKeyPress={actions.onKeyPress} />
				</Grid>
				<Grid item xs={12} md={5} className={classes.input_container}>
					<InputFormGray data={form.p_surname} onChange={actions.onChangeForm} onKeyPress={actions.onKeyPress} />
				</Grid>
				<Hidden smDown>
					<Grid item xs={2} style={{ maxWidth: 60 }} />
				</Hidden>
				<Grid item xs={12} md={5} className={classes.input_container}>
					<InputFormGray data={form.birth_date} onChange={actions.onChangeForm} onKeyPress={actions.onKeyPress} overrideStyle={{ fontFamily: 'Open Sans' }} />
				</Grid>

				<Grid item xs={12} md={5} className={classes.input_container}>
					<InputSelectFormGray data={form.country_origin} onChange={actions.onChangeForm} />
				</Grid>
				<Hidden smDown>
					<Grid item xs={2} style={{ maxWidth: 60 }} />
				</Hidden>
				<Grid item xs={12} md={5} className={classes.input_container}>
					<ButtonGroupForm data={form.sex} onChange={actions.onChangeForm} />
				</Grid>
			</Grid>
			<ControlButtons content={content} step={0} onPrevStep={onPrevStep} onNextStep={onNextStep} isValid={isFormValid(form).length === 0} form={form} />
		</>
	);
};

const useStyles = makeStyles((theme) => ({
	input_container: {
		maxWidth: 311,
		padding: '14px 0px',
		[theme.breakpoints.down('sm')]: {
			padding: '14px 10px',
		},
	},
	title: {
		fontSize: 20,
		fontWeight: 600,
		marginBottom: 30,
	},
}));

export default PersonalData;
