import img_header from '../../assets/offering/banner.jpg';
import img_o_1 from '../../assets/offering/offering1.jpg';
import img_o_2 from '../../assets/offering/offering2.jpg';
import img_o_3 from '../../assets/offering/offering3.jpg';
import img_o_4 from '../../assets/offering/offering4.jpg';

import img_od_1 from '../../assets/offering/details/coursedetail1.jpg';

const translations = {
	header_title: 'Programas académicos',
	header_img: img_header,
	top_text_1: 'Contamos con más de ',
	top_number_1: 23,
	top_text_2: ' programas en ',
	top_number_2: 5,
	top_text_3: ' distintas áreas de estudio',
	footer_text: 'Estudia con nosotros, da el primer paso y regístrate',
	footer_button: 'Regístrate',
	programs_array: [
		{
			img: img_o_1,
			title: 'Cursos',
			desc: 'In aliquet hac maecenas nascetur augue ullamcorper. Volutpat imperdiet ipsum vitae lectus et.',
			link_text: 'Conoce más',
			program_details_array: [
				{
					title: 'Examen de preparatoria',
					id: 'CURSO_EP',
					img: img_od_1,
					type: 'Curso',
					desc: 'Ornare velit nulla risus vulputate lacus ultrices sed lorem. Dolor, cursus odio arcu tellus, metus aliquet nec. Duis sed blandit tortor, convallis neque',
					left_title: 'Costo',
					left_content_1: '$1,900',
					left_content_2: '$1,800',
					right_title: 'Duración',
					right_content_1: '3 meses',
					right_content_2: '',
					button: 'Conoce más',
					note: '(Beca)*',
					note_desc: '*Blandit etiam volutpat rhoncus laoreet netus ipsum sed id purus. Purus ipsum id egestas etiam facilisis varius dolor ligula ultricies.',
				},
				{
					title: 'Right content test',
					id: 'CURSO_TEST',
					img: img_od_1,
					type: 'Curso',
					desc: 'Ornare velit nulla risus vulputate lacus ultrices sed lorem. Dolor, cursus odio arcu tellus, metus aliquet nec. Duis sed blandit tortor, convallis neque',
					left_title: 'Costo',
					left_content_1: '$0',
					left_content_2: '$0',
					right_title: 'Duración',
					right_content_1: '3 meses',
					right_content_2: 'test',
					button: 'Conoce más',
					note: '(Beca)*',
					note_desc: '*Blandit etiam volutpat rhoncus laoreet netus ipsum sed id purus. Purus ipsum id egestas etiam facilisis varius dolor ligula ultricies.',
				},
				{
					title: 'No note test',
					id: 'CURSO_TEST2',
					img: img_od_1,
					type: 'Curso',
					desc: 'Ornare velit nulla risus vulputate lacus ultrices sed lorem. Dolor, cursus odio arcu tellus, metus aliquet nec. Duis sed blandit tortor, convallis neque',
					left_title: 'Costo',
					left_content_1: '$1,900',
					left_content_2: '$1,800',
					right_title: 'Duración',
					right_content_1: '3 meses',
					right_content_2: '',
					button: 'Conoce más',
					note: '',
					note_desc: '',
				},
			],
		},
		{
			img: img_o_2,
			title: 'Diplomados',
			desc: 'In aliquet hac maecenas nascetur augue ullamcorper. Volutpat imperdiet ipsum vitae lectus et.',
			link_text: 'Conoce más',
			program_details_array: [
				{
					title: 'Examen de preparatoria',
					id: 'CURSO_EP',
					img: img_od_1,
					type: 'Diplomado',
					desc: 'Ornare velit nulla risus vulputate lacus ultrices sed lorem. Dolor, cursus odio arcu tellus, metus aliquet nec. Duis sed blandit tortor, convallis neque',
					left_title: 'Costo',
					left_content_1: '$1,900',
					left_content_2: '$1,800',
					right_title: 'Duración',
					right_content_1: '3 meses',
					right_content_2: '',
					button: 'Conoce más',
					note: '(Beca)*',
					note_desc: '*Blandit etiam volutpat rhoncus laoreet netus ipsum sed id purus. Purus ipsum id egestas etiam facilisis varius dolor ligula ultricies.',
				},
				{
					title: 'Right content test',
					id: 'CURSO_TEST',
					img: img_od_1,
					type: 'Diplomado',
					desc: 'Ornare velit nulla risus vulputate lacus ultrices sed lorem. Dolor, cursus odio arcu tellus, metus aliquet nec. Duis sed blandit tortor, convallis neque',
					left_title: 'Costo',
					left_content_1: '$0',
					left_content_2: '$0',
					right_title: 'Duración',
					right_content_1: '3 meses',
					right_content_2: 'test',
					button: 'Conoce más',
					note: '(Beca)*',
					note_desc: '*Blandit etiam volutpat rhoncus laoreet netus ipsum sed id purus. Purus ipsum id egestas etiam facilisis varius dolor ligula ultricies.',
				},
			],
		},
		{
			img: img_o_3,
			title: 'Certificaciones',
			desc: 'In aliquet hac maecenas nascetur augue ullamcorper. Volutpat imperdiet ipsum vitae lectus et.',
			link_text: 'Conoce más',
			program_details_array: [
				{
					title: 'Certification 1',
					id: 'CURSO_EP',
					img: img_od_1,
					type: 'Certificacion',
					desc: 'Ornare velit nulla risus vulputate lacus ultrices sed lorem. Dolor, cursus odio arcu tellus, metus aliquet nec. Duis sed blandit tortor, convallis neque',
					left_title: 'Costo',
					left_content_1: '$1,900',
					left_content_2: '$1,800',
					right_title: 'Duración',
					right_content_1: '3 meses',
					right_content_2: '',
					button: 'Conoce más',
					note: '(Beca)*',
					note_desc: '*Blandit etiam volutpat rhoncus laoreet netus ipsum sed id purus. Purus ipsum id egestas etiam facilisis varius dolor ligula ultricies.',
				},
				{
					title: 'Certification 2',
					id: 'CURSO_TEST',
					img: img_od_1,
					type: 'Certificacion',
					desc: 'Ornare velit nulla risus vulputate lacus ultrices sed lorem. Dolor, cursus odio arcu tellus, metus aliquet nec. Duis sed blandit tortor, convallis neque',
					left_title: 'Costo',
					left_content_1: '$0',
					left_content_2: '$0',
					right_title: 'Duración',
					right_content_1: '3 meses',
					right_content_2: 'test',
					button: 'Conoce más',
					note: '(Beca)*',
					note_desc: '*Blandit etiam volutpat rhoncus laoreet netus ipsum sed id purus. Purus ipsum id egestas etiam facilisis varius dolor ligula ultricies.',
				},
				{
					title: 'Certification 3',
					id: 'CURSO_TEST2',
					img: img_od_1,
					type: 'Certificacion',
					desc: 'Ornare velit nulla risus vulputate lacus ultrices sed lorem. Dolor, cursus odio arcu tellus, metus aliquet nec. Duis sed blandit tortor, convallis neque',
					left_title: 'Costo',
					left_content_1: '$1,900',
					left_content_2: '$1,800',
					right_title: 'Duración',
					right_content_1: '3 meses',
					right_content_2: '',
					button: 'Conoce más',
					note: '',
					note_desc: '',
				},
			],
		},
		{
			img: img_o_4,
			title: 'Licenciaturas',
			desc: 'In aliquet hac maecenas nascetur augue ullamcorper. Volutpat imperdiet ipsum vitae lectus et.',
			link_text: 'Conoce más',
			program_details_array: [
				{
					title: 'Degree 1',
					id: 'CURSO_EP',
					img: img_od_1,
					type: 'Licenciatura',
					desc: 'Ornare velit nulla risus vulputate lacus ultrices sed lorem. Dolor, cursus odio arcu tellus, metus aliquet nec. Duis sed blandit tortor, convallis neque',
					left_title: 'Costo',
					left_content_1: '$1,900',
					left_content_2: '$1,800',
					right_title: 'Duración',
					right_content_1: '3 meses',
					right_content_2: '',
					button: 'Conoce más',
					note: '(Beca)*',
					note_desc: '*Blandit etiam volutpat rhoncus laoreet netus ipsum sed id purus. Purus ipsum id egestas etiam facilisis varius dolor ligula ultricies.',
				},
				{
					title: 'Degree 2',
					id: 'CURSO_TEST',
					img: img_od_1,
					type: 'Licenciatura',
					desc: 'Ornare velit nulla risus vulputate lacus ultrices sed lorem. Dolor, cursus odio arcu tellus, metus aliquet nec. Duis sed blandit tortor, convallis neque',
					left_title: 'Costo',
					left_content_1: '$0',
					left_content_2: '$0',
					right_title: 'Duración',
					right_content_1: '3 meses',
					right_content_2: 'test',
					button: 'Conoce más',
					note: '(Beca)*',
					note_desc: '*Blandit etiam volutpat rhoncus laoreet netus ipsum sed id purus. Purus ipsum id egestas etiam facilisis varius dolor ligula ultricies.',
				},
				{
					title: 'Degree 3',
					id: 'CURSO_TEST',
					img: img_od_1,
					type: 'Licenciatura',
					desc: 'Ornare velit nulla risus vulputate lacus ultrices sed lorem. Dolor, cursus odio arcu tellus, metus aliquet nec. Duis sed blandit tortor, convallis neque',
					left_title: 'Costo',
					left_content_1: '$0',
					left_content_2: '$0',
					right_title: 'Duración',
					right_content_1: '3 meses',
					right_content_2: 'test',
					button: 'Conoce más',
					note: '(Beca)*',
					note_desc: '*Blandit etiam volutpat rhoncus laoreet netus ipsum sed id purus. Purus ipsum id egestas etiam facilisis varius dolor ligula ultricies.',
				},
				{
					title: 'Degree 4',
					id: 'CURSO_TEST',
					img: img_od_1,
					type: 'Licenciatura',
					desc: 'Ornare velit nulla risus vulputate lacus ultrices sed lorem. Dolor, cursus odio arcu tellus, metus aliquet nec. Duis sed blandit tortor, convallis neque',
					left_title: 'Costo',
					left_content_1: '$0',
					left_content_2: '$0',
					right_title: 'Duración',
					right_content_1: '3 meses',
					right_content_2: 'test',
					button: 'Conoce más',
					note: '(Beca)*',
					note_desc: '*Blandit etiam volutpat rhoncus laoreet netus ipsum sed id purus. Purus ipsum id egestas etiam facilisis varius dolor ligula ultricies.',
				},
			],
		},
	],
};

export default translations;
