import img_header from '../../assets/about_us/banner.jpg';

import img_sc_1 from '../../assets/about_us/team1.jpg';
import img_sc_2 from '../../assets/news/news_1.jpg';
import img_sc_3 from '../../assets/news/news_2.jpg';
import img_sc_4 from '../../assets/news/news_3.jpg';

import img_sd from '../../assets/about_us/last_section.jpg';

const translations = {
	header_title: 'Instituto Vanguard',
	header_img: img_header,
	sectionA: {
		desc_title: '¿Quienes somos?',
		desc_content: 'Mi in pretium amet lectus nunc. Lectus nam quis malesuada pulvinar enim ac vitae nunc.',
		left_card_title: 'Misión',
		left_card_content:
			'Accumsan diam mollis turpis erat risus. Egestas tellus senectus fusce tincidunt in suspendisse. Libero arcu in commodo, tortor porttitor in dignissim. Faucibus ipsum vitae scelerisque tellus, semper sollicitudin tempus.',
		right_card_title: 'Filosofía',
		right_card_content:
			'Accumsan diam mollis turpis erat risus. Egestas tellus senectus fusce tincidunt in suspendisse. Libero arcu in commodo, tortor porttitor in dignissim. Faucibus ipsum vitae scelerisque tellus, semper sollicitudin tempus. Nec id tellus in enim turpis habitant nulla rhoncus. Vitae malesuada scelerisque neque et et.',
	},
	sectionB: {
		header_title: 'Nuestros principios',
		principles_array: [
			{
				title: 'Responsabilidad',
				content: 'Velit arcu aenean venenatis neque. Tempor imperdiet massa ante magna adipiscing. Commodo, mattis risus laoreet scelerisque semper neque.',
			},
			{
				title: 'Liderazgo',
				content: 'Velit arcu aenean venenatis neque. Tempor imperdiet massa ante magna adipiscing. Commodo, mattis risus laoreet scelerisque semper neque.',
			},
			{
				title: 'Honestidad',
				content: 'Velit arcu aenean venenatis neque. Tempor imperdiet massa ante magna adipiscing. Commodo, mattis risus laoreet scelerisque semper neque.',
			},
			{
				title: 'Confianza',
				content: 'Velit arcu aenean venenatis neque. Tempor imperdiet massa ante magna adipiscing. Commodo, mattis risus laoreet scelerisque semper neque.',
			},
			{
				title: 'Compromiso',
				content: 'Velit arcu aenean venenatis neque. Tempor imperdiet massa ante magna adipiscing. Commodo, mattis risus laoreet scelerisque semper neque.',
			},
		],
		footer_title: 'Calidez humana y gran servicio nos caracterizan',
	},
	sectionC: {
		header_title: 'Nuestro equipo',
		header_content: 'Rhoncus sagittis ut at maecenas sollicitudin mauris id.',
		team_array: [
			{
				name: 'José Rafael Saiz',
				position: 'Presidente ejecutivo',
				email: 'example@vanguard.mx',
				img: img_sc_3,
			},
			{
				name: 'Mireya Fernández',
				position: 'Subdirectora académica',
				email: 'miye_fernandez@vanguard.mx',
				img: img_sc_1,
			},
			{
				name: 'Zaira Barrientos',
				position: 'Coordinadora académica',
				email: 'example@vanguard.mx',
				img: img_sc_2,
			},
			{
				name: 'Example Card One',
				position: 'Lorem ipsum dolor',
				email: 'example@vanguard.mx',
				img: img_sc_3,
			},
			{
				name: 'Example Card Two',
				position: 'Lorem ipsum dolor',
				email: 'miye_fernandez@vanguard.mx',
				img: img_sc_3,
			},
			{
				name: 'Example Card Three',
				position: 'Lorem ipsum dolor',
				email: 'example@vanguard.mx',
				img: img_sc_4,
			},
		],
	},
	sectionD: {
		header_title: 'Estamos comprometidos con formar líderes',
		body_title: 'Impactando vidas',
		body_img: img_sd,
		testimony_array: [
			'Sed diam tempor, faucibus habitant ut erat. Arcu non feugiat quisque tristique morbi suspendisse iaculis gravida.',
			'Sagittis feugiat volutpat a, placerat elementum felis faucibus a metus. Cum lacus tellus pulvinar nibh. Odio congue sit sit ut massa porttitor risus id vel.',
			'Magna tincidunt ut viverra eget. Ac accumsan adipiscing tincidunt tempor elit morbi ultricies porttitor nunc.',
			'Pharetra laoreet aliquam fermentum suscipit mi dictum tincidunt pellentesque interdum. Orci id nunc eget dolor vel duis semper arcu nisl. Aliquet pulvinar eget cursus nisi.',
			'Egestas posuere egestas in leo auctor semper nibh sit. Platea placerat tellus amet, vulputate neque consectetur egestas odio. Ornare arcu, sapien, velit, netus erat blandit cras dui. Ac sed sed nisl blandit sagittis.',
			'Sed diam tempor, faucibus habitant ut erat. Arcu non feugiat quisque tristique morbi suspendisse iaculis gravida.',
		],
	},
};

export default translations;
