import React from 'react'
import { MenuItem, TextField, withStyles, makeStyles } from '@material-ui/core';
import { ruleValidation } from './customFunctions';
import { grey } from '@material-ui/core/colors';
import classNames from 'classnames';

const CssTextField = withStyles((theme) => ({
	root: {
		marginTop: 31,
		'& label.Mui-focused': {
			//color: 'green',
		},
		'& .MuiInput-underline:after': {
			borderBottomWidth: 0,
		},
		'& .MuiSelect-root': {
			fontFamily: 'Open Sans',
			fontWeight: 600,
			color: grey[500],
		},
		'& .MuiOutlinedInput-root': {
			height: 40,
			paddingBottom: 5,
			'& fieldset': {
				borderWidth: 0,
				borderRadius: 0,
				borderBottomColor: grey[300],
				borderBottomWidth: 2,

				//borderColor: 'red',
			},
			'&:hover fieldset': {
				borderBottomColor: theme.palette.secondary.main,
			},
			'&.Mui-focused fieldset': {
				borderWidth: 0,
				borderRadius: 0,
				borderBottomColor: theme.palette.primary.main,
				borderBottomWidth: 2,
			},
		},
	},
}))(TextField);

const InputSelectForm = (props) => {
	const { data, onChange, startAdornment, endAdornment } = props;

	const onInnerChange = (event) => {
		const response = event.target.value;
		let temp = { ...data };
		temp.value = response;
		temp.isValid = ruleValidation(temp.value, temp.rules, temp.value2);
		temp.isVisited = true;
		onChange(temp);
	};

	const { config, value, isValid, isVisited, options } = data;

	const isError = isVisited && !isValid;
	let interConfig = { ...config };
	if (!isError) interConfig.helperText = '';

	const _options = options ? options : [];

	const classes = useStyles();

	return (
		<div>
			<CssTextField
				value={value}
				variant='outlined'
				error={isError}
				{...interConfig}
				select
				onChange={onInnerChange}
				InputProps={{
					startAdornment: startAdornment ? startAdornment : undefined,
					endAdornment: endAdornment ? endAdornment : undefined,
				}}
			>
				{_options.map((item, i) => {
					return (
						<MenuItem key={i} value={item.value} className={classes.menu_item}>
							{item.label}
						</MenuItem>
					);
				})}
			</CssTextField>
		</div>
	);
};

const useStyles = makeStyles((theme) => ({
	menu_item: {
		fontFamily: 'Open Sans',
	},
}));

export default InputSelectForm