import img_header from '../../assets/about_us/banner.jpg';

import img_sc_1 from '../../assets/about_us/team1.jpg';

const translations = {
	header_title: 'Vanguard Institute',
	header_img: img_header,
	sectionA: {
		desc_title: '¿Who are we?',
		desc_content: 'Mi in pretium amet lectus nunc. Lectus nam quis malesuada pulvinar enim ac vitae nunc.',
		left_card_title: 'Mission',
		left_card_content:
			'Accumsan diam mollis turpis erat risus. Egestas tellus senectus fusce tincidunt in suspendisse. Libero arcu in commodo, tortor porttitor in dignissim. Faucibus ipsum vitae scelerisque tellus, semper sollicitudin tempus.',
		right_card_title: 'Philosophy',
		right_card_content:
			'Accumsan diam mollis turpis erat risus. Egestas tellus senectus fusce tincidunt in suspendisse. Libero arcu in commodo, tortor porttitor in dignissim. Faucibus ipsum vitae scelerisque tellus, semper sollicitudin tempus. Nec id tellus in enim turpis habitant nulla rhoncus. Vitae malesuada scelerisque neque et et.',
	},
	sectionB: {
		header_title: 'Our principles',
		content_array: [
			{
				title: 'Responsibility',
				content: 'Velit arcu aenean venenatis neque. Tempor imperdiet massa ante magna adipiscing. Commodo, mattis risus laoreet scelerisque semper neque.',
			},
			{
				title: 'Leadership',
				content: 'Velit arcu aenean venenatis neque. Tempor imperdiet massa ante magna adipiscing. Commodo, mattis risus laoreet scelerisque semper neque.',
			},
			{
				title: 'Honesty',
				content: 'Velit arcu aenean venenatis neque. Tempor imperdiet massa ante magna adipiscing. Commodo, mattis risus laoreet scelerisque semper neque.',
			},
			{
				title: 'Reliance',
				content: 'Velit arcu aenean venenatis neque. Tempor imperdiet massa ante magna adipiscing. Commodo, mattis risus laoreet scelerisque semper neque.',
			},
			{
				title: 'Commitment',
				content: 'Velit arcu aenean venenatis neque. Tempor imperdiet massa ante magna adipiscing. Commodo, mattis risus laoreet scelerisque semper neque.',
			},
		],
		footer_title: 'Human warmth and great service characterize us',
	},
	sectionC: {
		header_title: 'Our team',
		header_content: 'Rhoncus sagittis ut at maecenas sollicitudin mauris id.',
		team_array: [
			{
				name: 'José Rafael Saiz',
				position: 'Executive President',
				email: 'example@vanguard.mx',
				img: img_sc_1,
			},
			{
				name: 'Mireya Fernández',
				position: 'Academic Assistant Director',
				email: 'miye_fernandez@vanguard.mx',
				img: img_sc_1,
			},
			{
				name: 'Zaira Barrientos',
				position: 'Academic Coordinator',
				email: 'example@vanguard.mx',
				img: img_sc_1,
			},
		],
	},
	sectionD: {
		header_title: 'We are commited to developing leaders',
		content_title: 'Impacting lives',
		testimony_array: [
			'Sed diam tempor, faucibus habitant ut erat. Arcu non feugiat quisque tristique morbi suspendisse iaculis gravida.',
			'Sagittis feugiat volutpat a, placerat elementum felis faucibus a metus. Cum lacus tellus pulvinar nibh. Odio congue sit sit ut massa porttitor risus id vel.',
			'Magna tincidunt ut viverra eget. Ac accumsan adipiscing tincidunt tempor elit morbi ultricies porttitor nunc.',
			'Pharetra laoreet aliquam fermentum suscipit mi dictum tincidunt pellentesque interdum. Orci id nunc eget dolor vel duis semper arcu nisl. Aliquet pulvinar eget cursus nisi.',
			'Egestas posuere egestas in leo auctor semper nibh sit. Platea placerat tellus amet, vulputate neque consectetur egestas odio. Ornare arcu, sapien, velit, netus erat blandit cras dui. Ac sed sed nisl blandit sagittis.',
			'Sed diam tempor, faucibus habitant ut erat. Arcu non feugiat quisque tristique morbi suspendisse iaculis gravida.',
		],
	},
};

export default translations;
