import { makeStyles, Grid, Hidden, Typography, Fade } from '@material-ui/core';
import CardSlider from '../components/CardSlider';
import InViewMonitor from 'react-inview-monitor';
import { useState } from 'react';

const SectionC = ({ content }) => {
	const classes = useStyles();
	const [onView, setOnView] = useState(false);

	return (
		<div className={classes.root}>
			<div className={classes.header_container}>
				<Typography variant='h4' className={classes.header_title}>
					{content.header_title}
				</Typography>
				<Typography variant='h6' className={classes.header_content}>
					{content.header_content}
				</Typography>
			</div>
			<InViewMonitor
				onInView={() => {
					setOnView(true);
				}}
			>
				<Fade in={onView} timeout={1000}>
					<div>
						<CardSlider items={content.team_array} />
					</div>
				</Fade>
			</InViewMonitor>
		</div>
	);
};

const useStyles = makeStyles((theme) => ({
	root: {},
	header_container: {
		padding: '140px 140px 50px',
		[theme.breakpoints.down('md')]: {
			padding: '25px',
		},
	},
	header_title: {
		fontSize: '2.5rem',
	},
	header_content: {
		color: '#7F7F7F',
	},
}));

export default SectionC;
